<template>
    <v-card 
      location="top"
      offset-y
      style="
        border-radius: 8px;
        margin-top:-20px;
        margin-right: 68px;
        box-shadow: 0 0 32px rgba(0, 0, 0, 0.2);
        padding-bottom: 8px;
        padding-right: 8px;
        padding-left: 8px;
      "
    >
      <!---------------------------------------------------------------------------------------->
      <!-------------------------------------- Add calendar ------------------------------------>
      <!---------------------------------------------------------------------------------------->
      <div style="
        padding-top: 8px;
        align-content: start
        ">     
          <AnnucalLinkTwo style="float:left"
          v-bind="props"
          variant="text"
          icon="PhCalendarPlus"
          linktext= "addcalendar" 
          @click="createNewCalendar"
        />           

        <!---------------------------------------------------------------------------------------->
        <!----------------------------------- Subscribe calendar --------------------------------->
        <!---------------------------------------------------------------------------------------->
        
          <v-dialog max-width="600">

                <template v-slot:activator="{ props: activatorProps }">
                  <div   v-bind="activatorProps"  >
                          <AnnucalLinkTwo style="float:left"
                            v-bind="props"
                            variant="text"
                            icon="PhCalendarDots"
                            linktext= "subscribecalendar" 
                            @click="openSettingsDialog"
                        />  
                      </div>

                </template>

                <template v-slot:default="{ isActive }">
                  <v-card :title=dearusersText>
                    <template v-slot:text>
                      <div class="text-style">{{commingsoonText}}</div>
                      <br>
                      <div class="text-style" style="font-weight: 500">{{ yourteamText }}</div>
                    </template>

                    <v-card-actions>
                      <v-spacer></v-spacer>

                      <v-btn
                        text="Schließen"
                        variant="text"
                        @click="isActive.value = false"
                      ></v-btn>

                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
           

            <!---------------------------------------------------------------------------------------->
            <!----------------------------------- Public calendar ------------------------------------>
            <!---------------------------------------------------------------------------------------->
            <v-dialog max-width="600">

          <template v-slot:activator="{ props: activatorProps }">
            <div     v-bind="activatorProps"  >
              <AnnucalLinkTwo style="float:left"
                    v-bind="props"
                    variant="text"
                    icon="PhCalendarStar"
                    linktext= "publiccalendar" 
                    @click="openSettingsDialog"
              />  
            </div>
          </template>

<template v-slot:default="{ isActive }">
  <v-card :title=dearusersText>
    <template v-slot:text>
      <div class="text-style">{{commingsoonText}}</div>
      <br>
      <div class="text-style" style="font-weight: 500">{{ yourteamText }}</div>
    </template>

    <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn
        text="Schließen"
        variant="text"
        @click="isActive.value = false"
      ></v-btn>

    </v-card-actions>
  </v-card>
</template>
</v-dialog>
    
      
         
        </div>
    </v-card>
</template>

<script setup>
import { ref, defineProps, computed, onMounted, defineEmits } from 'vue';
import AnnucalLinkTwo from "./AnnucalLinkTwo.vue"
//import AddNewCalendar from "./AddNewCalendar.vue";
import {useStore} from "vuex";
import { useI18n } from 'vue-i18n'
import { v4 as uuidv4 } from 'uuid';
import calenderCore from "../composition/calenderCore";

const calenderCore1 = calenderCore();

const isAuthenticated = computed(() => store.getters["user/isAuthenticated"]);
const currentUserID = computed(() => store.getters["user/currentUserID"]);

const { t } = useI18n()


const commingsoonText = t('commingsoon')
const dearusersText = t('dearusers')
const yourteamText = t('yourteam')



const store = useStore();
    const { state } = store;

// Define props
const props = defineProps({
  id: {
    type: String,
    required: true
  },
  calendarColor: {
    type: String,
    default: '#DAFDD8' // Default value if not provided
  }
});

onMounted(() => {
  store.state.settings.Log_Status = ""
  store.state.settings.AnnucalSaveText = t("savecalendar")
  store.state.settings.AnnucalVcardHeadline = t("createcalendar")
  store.state.settings.normlaErrorMessage = ""
});

const mycalendars = computed({
    get() {
      return state.mycalendars.items;
    },
})

const emits = defineEmits(['closeCalendarMenu'])

async function createNewCalendar (){
  
  store.state.settings.savingModus = true

  let newCalendar = {
      color: getCalendarColor(), //calendarColor.value, 
      icon: 'ph-CalendarCheck', //icon.value,
      name: 'Kalender',
      externalCalendarLink: '',//externalCalendarLink.value,
      description: '',
      visible: true,
      private: true,
      creatorUID: currentUserID.value == false ? 'notsignedin' :  currentUserID.value ,
      creatorName: "Annucal",
      calendarImage:  "",
      type: '1',
      specialday:'',
      country: '',
      subdivision: '',
      subdivisionCode: '',
      loadedPublicHolidays: false,
      events:[]
  }

    if(isAuthenticated.value == true){
        const newCalendarData = await store.dispatch("createCalendar", newCalendar);
        newCalendar.icon = newCalendarData.imageURL
        newCalendar.calendarId = newCalendarData.calendarId
        newCalendar.docId = newCalendarData.MyCalendarsdocId
        store.state.mycalendars.items.push(newCalendar)
    }else{
        const cId = uuidv4()
        newCalendar.calendarId = cId
        newCalendar.docId = cId
        store.state.mycalendars.items.push(newCalendar)
    }

    calenderCore1.updateCalender();

    emits('closeCalendarMenu')
}

const availableColors = ref(['#6AD069','#4BA0C4','#5C70D7','#9D41D5','#D272DA'])

function getCalendarColor(){
  for(let c = 0; c < availableColors.value.length; ++c){
    var hasColor = false
    for (let i = 0; i < mycalendars.value.length; ++i){
      if(mycalendars.value[i].color === availableColors.value[c]){
        hasColor = true
      }
    }
    if(hasColor === false){
      return availableColors.value[c]
    }
  }
  return '#0000ff'
}


</script>

  
  <style scoped>

.text-style{
    font-size: 14px;
    font-weight: 300;
    color:var(--standard-text-color);
    letter-spacing: -0.6px;
}

  .grid-container {
    display: grid;
    grid-template-columns: 130px auto;
    background-color: #ffffff;
    padding: 16px; /* Gleichmäßiges Padding */
  }

  .grid-item-links-active {
    font-size: 14px;
    text-align: left;
    font-weight: 300;
    letter-spacing: -0.6px;
    user-select: none;
  }
  
  .grid-item-links-inactive {
    font-size: 14px;
    text-align: left;
    font-weight: 300;
    letter-spacing: -0.6px;
    color: #85827C;
    user-select: none;
  }
  
  .grid-item-rechts {}
  
  .plus-button-active {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18px; /* Gleiche Größe wie Checkbox */
    height: 18px; /* Gleiche Größe wie Checkbox */
    background-color: #e7e5df;
    border: 1px solid #e7e5df;
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
    margin-top: 2px;
    cursor: pointer;
  }

  .plus-button-inactive {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18px; /* Gleiche Größe wie Checkbox */
    height: 18px; /* Gleiche Größe wie Checkbox */
    background-color: #f1f1f1;
    border: 1px solid #f1f1f1;
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
    margin-top: 2px;
    
  }
  
  .plus-icon {
    color: #191816;
    font-size: 14px;
    font-weight: bold;
    transition: color 0.3s ease;
  }
  
  .plus-button-active:hover {
    background-color: #191816;
  }
  
  .plus-button-active:hover .plus-icon {
    color: white;
  }
  </style>
  