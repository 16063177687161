<template>
  <div class="input-container">
    <label :class="{ 'floating-label': isFocused || internalValue }" class="label">
      {{ label }}
    </label>


    <!-------------------------------------------------------------------------------------------->
    <!------------------------------------  normal Text ------------------------------------------>
    <!-------------------------------------------------------------------------------------------->
    <div v-if="props.labelType=='normal'">
      <v-text-field 
        v-model="internalValue"
        variant="flat"
        hide-details
        @focus="isFocused = true"
        @blur="checkBlur"
        class="flat-text-field small-text-field"
        :style="inputStyle"
        :maxlength=props.textLength
        density="compact"
        autofocus
      >
        <template v-slot:append v-if="showCounter == true">
          <span :class="{ 'floating-counter': isFocused || internalValue.length > 0 }" class="counter">
            {{ internalValue ? internalValue.length : 0 }} / {{props.textLength}}
          </span>
        </template>
      </v-text-field>

        <!------------------------------------  Error Message -------------------------------------->
      
        <div v-if="this.$store.state.settings.normlaErrorMessage != ''"
          class="error-style"
        >
          {{ this.$store.state.settings.normlaErrorMessage }}
        </div>
        <div v-if="this.$store.state.settings.normlaErrorMessage == ''"
          class="error-style"
        >
        &nbsp;
        </div>
  </div>

    <!-------------------------------------------------------------------------------------------->
    <!------------------------------------  Email textfield -------------------------------------->
    <!-------------------------------------------------------------------------------------------->
    <div v-if="props.labelType=='email'">
      
        <v-text-field 
          v-model="internalValue"
          variant="flat"
          hide-details
          @focus="isFocused = true"
          @blur="checkBlur"
          class="flat-text-field small-text-field"
          :style="inputStyle"
          :maxlength=props.textLength
          density="compact"
        >
          <template v-slot:append>
            <span :class="{ 'floating-counter': isFocused || internalValue.length > 0 }" class="counter">
              {{ internalValue ? internalValue.length : 0 }} / {{props.textLength}}
            </span>
          </template>
  
        </v-text-field>

        <!------------------------------------  Error Message -------------------------------------->
        
        <div v-if="this.$store.state.settings.emailErrorMessage != ''"
          class="error-style"
        >
          {{ this.$store.state.settings.emailErrorMessage }}
        </div>
        <div v-if="this.$store.state.settings.emailErrorMessage == ''"
          class="error-style"
        >
        &nbsp;
        </div>
    </div>

    <!-------------------------------------------------------------------------------------------->
    <!------------------------------------  Password textfield ----------------------------------->
    <!-------------------------------------------------------------------------------------------->
    <div v-if="props.labelType=='password'">
      
      <v-text-field 
        v-model="internalValue"
        variant="flat"
        hide-details
        @focus="isFocused = true"
        @blur="checkBlur"
        class="flat-text-field small-text-field"
        :style="inputStyle"
        :maxlength=props.textLength
        density="compact"
        type="password"
      >
        <template v-slot:append>
          <span :class="{ 'floating-counter': isFocused || internalValue.length > 0 }" class="counter">
            {{ internalValue ? internalValue.length : 0 }} / {{props.textLength}}
          </span>
        </template>



      </v-text-field>

      <!------------------------------------  Error Message -------------------------------------->
      
      <div v-if="this.$store.state.settings.passwordErrorMessage != ''"
        class="error-style"
      >
        {{ this.$store.state.settings.passwordErrorMessage }}
      </div>
      <div v-if="this.$store.state.settings.passwordErrorMessage == ''"
        class="error-style"
      >
      &nbsp;
      </div>
  </div>

  </div>
</template>

<script setup>
import { ref, computed, watch, defineProps, defineEmits } from 'vue';

// Props
const props = defineProps({
  modelValue: String,
  label: {
    type: String,
    default: "Eingabe",
  },
  labelType: {
    type: String,
    default: "normal",
  },
  showCounter:{
    type:Boolean,
    default: true
  },
  textLength:{
    type: Number,
    default: 20
  }
});

// Emits
const emit = defineEmits(['update:modelValue']);



// Reactive Data
const isFocused = ref(false);
const internalValue = ref(props.modelValue || ""); // Initialisiere mit leerem String

// Watchers
watch(() => props.modelValue, (newValue) => {
  internalValue.value = newValue || ""; // Fallback zu leerem String
});

watch(internalValue, (newValue) => {
  emit('update:modelValue', newValue);
});

// Computed Properties
const inputStyle = computed(() => ({
  backgroundColor: isFocused.value ? '#FFFFFF' : '#F6F2EF',
  border: '1px solid',
  borderColor: isFocused.value ? '#191816' : 'transparent',
  boxSizing: 'border-box',
  borderRadius: '5px',
}));


const checkBlur = () => {
  isFocused.value = false;
};



/*
const validateEmail = () => {
  emailErrors.value = [];
  if (!email.value) {
    emailErrors.value.push('Email ist erforderlich');
  } else if (!/.+@.+\..+/.test(email.value)) {
    emailErrors.value.push('Muss eine gültige E-Mail sein');
  }
};
*/


</script>

<style scoped>
.input-container {
  position: relative;
}

.label {
  position: absolute;
  top: 10px;
  left: 12px;
  font-size: 15px;
  font-weight: 400;
  color: #85827C;
  pointer-events: none;
  transition: all 0.2s ease-in-out;
  letter-spacing: -0.6px;
}

.floating-label {
  top: -18px;
  left: 2px;
  font-size: 12px;
  font-weight: 300;
  color: #191816;
  letter-spacing: -0.5px;
}

.flat-text-field {
  background-color: #F6F2EF;
  box-sizing: border-box;
}

.flat-text-field .v-input__control {
  min-height: 32px; /* Festgelegte Höhe */
  padding-top: 4px;
  padding-bottom: 4px;
}

.flat-text-field .v-input__slot {
  height: 100%; /* Stellt sicher, dass das Textfeld innerhalb des Containers bleibt */
}

.flat-text-field input {
  padding: 0 0 0 10px; /* Vergrößert den Abstand des Textes von der linken Seite */
  font-size: 14px; /* Optional: Verkleinert die Schriftgröße */
}

.counter {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
  margin-right: 10px; /* Abstand zum rechten Rand */
  position: absolute;
  right: 4px;
  bottom: 34px;
  transition: all 0.2s ease-in-out;
  pointer-events: none;
  letter-spacing: -1.5px;
}

.floating-counter {
  bottom: 65px;
  margin-right: 1px;
  font-size: 11px;
  font-weight: 300;
  color: #191816;
  letter-spacing: -1.5px;
}

.error-style{

  padding-left:3px;
  padding-top:1px;
  font-size: 12px;
  font-weight: 300;
  color: #E30303;
  letter-spacing: -0.5px;
}
</style>
