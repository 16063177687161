<!-- AnnucalRadioButton.vue -->
<template>
    <label class="custom-radio-container">
      <input
        type="radio"
        class="custom-radio"
        :value="value"
        v-model="currentValue"
        @change="emitChange"
      />
      <span class="custom-radio-mark"></span>
      <slot></slot>
    </label>
  </template>
  
  <script>
  export default {
    name: "AnnucalRadioButton",
    props: {
      modelValue: {
        required: true
      },
      value: {
        required: true
      }
    },
    computed: {
      currentValue: {
        get() {
          return this.modelValue;
        },
        set(value) {
          this.$emit("update:modelValue", value);
        }
      }
    },
    methods: {
      emitChange() {
        this.$emit("update:modelValue", this.value);
      }
    }
  };
  </script>
  
  <style scoped>
  .custom-radio-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 10px;
    padding-bottom: 12px;
  }
  
  .custom-radio {
    display: none;
  }
  
  .custom-radio-mark {
    position: relative;
    width: 16px;
    height: 16px;
    background-color: #F6F2EF;
    border: 1px solid #A39F96;
    border-radius: 50%;
    display: inline-block;
  }
  
  .custom-radio:checked + .custom-radio-mark {
    background-color: #6278E9;
    border: 2px solid #6278E9;
  }
  
  .custom-radio:checked + .custom-radio-mark::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8px;
    height: 8px;
    background-color: white;
    border-radius: 50%;
  }
  </style>
  