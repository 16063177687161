<template>


  <div class="main-div">
  <!-------------------------------------------------------------------------------------------->
  <!------------------------------------ Annucal VCard ----------------------------------------->
  <!-------------------------------------------------------------------------------------------->
  <AnnucalVCard
    :padding_botton='4'
    :first_column_width='14'
    :calendarColor = props.calendarColor
    :showCalendarName = true
    :showvCardActions = false
    :VcardWitdh = '380'
    :calendarName="calendarName"
    @close-menu="closeMenu"
    @saveNew="saveNew"
    style="
      margin-left:8px;
      margin-right:8px;
    "
  >

  <!-------------------------------------------------------------------------------------------->
  <!------------------------------  Middle Slot - Annucal VCard -------------------------------->
  <!-------------------------------------------------------------------------------------------->
  <template v-slot:middle>
      <div class="grid-container">
    
      <!----------------------------------  Input Textbox ------------------------------------------>
      <AnnucalTextfieldVue
        labeltext="textinput"
        v-model="textInput" 
        :label = eventname
        labelType="normal"
      />
      
      <!------------------------------------  Icon trash ------------------------------------------->
      <div style="padding-left: 18px;" v-if="showSavingCircleDeleteEvent">
        <v-progress-circular class="circular-progress-trash" 
          indeterminate :size="20" 
          :width="2"
          color="#85827C"
          ></v-progress-circular>
      </div>

      <div v-if="!showSavingCircleDeleteEvent">
        <div  v-if="props.prop_mode == 'edit'" >
        <deleteButton
          :active = true
          @childClicked="deleteEvent($event)" 
         />
      </div>
      <div v-if="props.prop_mode == 'new'">
        <deleteButton
          :active = false
         />
      </div>
      </div>
    
      <!----------------------------------  Icon Black Next ---------------------------------------->
      <div v-if="showSavingCircleNewEvent">
        <v-progress-circular class="circular-progress-save" 
          indeterminate :size="20" 
          :width="2"
          color="#85827C"
          ></v-progress-circular>
      </div>
      <div v-if="!showSavingCircleNewEvent" >
        <div class="plus-button" v-on:click="saveNew" v-if="prop_mode == 'new'">
          <PhArrowRight class="plus-icon" />
        </div>
        <div class="plus-button" v-on:click="saveEdit" v-if="prop_mode == 'edit'">
          <PhArrowRight class="plus-icon" />
        </div>
      </div>
    </div>
    </template>
  </AnnucalVCard>
</div>




</template>

<script setup>
import { ref, defineProps, defineEmits, onMounted, onBeforeUnmount, computed, nextTick } from 'vue';
import {useStore} from "vuex";
import { useToast } from "vue-toastification"
//import format from 'date-fns/format' 
import calenderCore from "../composition/calenderCore";
import deleteButton from "../components/DeleteButton.vue"
const store = useStore();
const { state } = store;
const toast = useToast(); 
const calenderCore1 = calenderCore(); 
import AnnucalVCard from "./AnnucalVcard.vue"
import AnnucalTextfieldVue from "./AnnucalTextfield.vue"
import { v4 as uuidv4 } from 'uuid';

const showSavingCircleNewEvent = ref(false)
const showSavingCircleDeleteEvent = ref(false)

const deletingEvent = ref(false)
const textInput = ref('')

import { useI18n } from 'vue-i18n'


const { t } = useI18n()
const eventname = t('eventname')

const events = computed({
        get() {
            return state.events.items;
        },
})

const handleKeyPress = (event) => {
  if (event.key === 'Enter') {
    if(props.prop_mode === 'new'){
      saveNew()
    }else{
      saveEdit()
    }
  }
};

// Define props
const props = defineProps({
eventidDB:{
  type: String,
  required: true
},
calendarId:{
  type: String,
  required: true
},
calendarName: {
  type: String,
  required: true
},
calendarColor:{
  type: String,
  required: true
},
prop_mode:{
  type: String,
  required: true
},
datefrom:{
  type: String,
  required: true
},
datefrom_json:{
  type: String,
  required: true
},
dateto:{
  type: String,
  required: true
},
dateto_json:{
  type: String,
  required: true
},
eventName:{
  type: String,
  required: true
}
});

const isAuthenticated = computed(() => store.getters["user/isAuthenticated"]);
const userId = computed(() => store.getters["user/userID"]);

onMounted(() => {
  if(props.eventName != "Event"){
    textInput.value = props.eventName
  }
  calenderCore1.setEditMode(props.date, props.calendarId)
  store.state.settings.AnnucalVcardHeadline = props.calendarName
  store.state.settings.normlaErrorMessage = ""
  focusTextField();
  window.addEventListener('keydown', handleKeyPress);
});

onBeforeUnmount(() => {
  window.removeEventListener('keydown', handleKeyPress);
});

const autofocusField = ref(null); // Referenz für das Textfeld

function focusTextField() {
  // Verwende nextTick, um sicherzustellen, dass das Element gerendert ist
  nextTick(() => {
    if (autofocusField.value) {
      // Greife auf das native Input-Element zu und setze den Fokus
      const input = autofocusField.value.$el.querySelector('input');
      if (input) {
        input.focus();
      }
    }
  });
}



async function saveNew(){

  showSavingCircleNewEvent.value = true
  if(deletingEvent.value == true){
    return
  }

  if(textInput.value == ""){
    store.state.settings.normlaErrorMessage = "Event Name erforderlich"
    return
  }

  

    let newEvent = {
      
      calendarId: props.calendarId,
      eventidDB: uuidv4(),
      color: props.calendarColor,//store.state.settings.currentCalendarColor, 
      title: textInput.value,
      datefrom: props.datefrom , //format(store.state.dayevent.dateTo, 'yyyy-MM-dd')
      dateto: props.dateto, //format(store.state.dayevent.dateTo, 'yyyy-MM-dd')
      datefromJson: props.datefrom_json,//store.state.dayevent.dateFrom, 
      datetoJson: props.dateto_json,//store.state.dayevent.dateTo,
      icon: 'ph-CalendarCheck', 
      fromHour: store.state.dayevent.valueFromHour,
      fromMinute: store.state.dayevent.valueFromMinute,
      toHour: store.state.dayevent.valueToHour,
      toMinute: store.state.dayevent.valueToMinute,
      isFullDay: store.state.dayevent.isFullDay,
      creatorUID: userId.value != undefined ?  userId.value : "notSignedIn",
      calendarName: props.calendarName,
      additionalInfos: '',// this.additionalInfos,
      rRules: (store.state.dayevent.rRules == undefined)? "ONCE" :  store.state.dayevent.rRules ,
      location: "",
      removedDays: [],
      type: "1"
    }
  
    if(isAuthenticated.value == true){
      await store.dispatch("createEvent", newEvent); 
    }else{
      store.state.events.items.push(newEvent)
    }

    // Refresh Events Indexlist
    for(let index = 0; index < store.state.events.items.length; ++index){
        store.state.events.items[index].index = index;
    }

    // Write each event index in the events of each mycalendars
    for (let calenderIndex = 0; calenderIndex < store.state.mycalendars.items.length; calenderIndex++) {
      store.state.mycalendars.items[calenderIndex].events = []
        for (let eventIndex = 0; eventIndex < store.state.events.items.length; eventIndex++){
            if(store.state.mycalendars.items[calenderIndex].calendarId == store.state.events.items[eventIndex].calendarId){
              store.state.mycalendars.items[calenderIndex].events.push(eventIndex)
            }
        } 
    } 

    calenderCore1.updateCalender();
    closeMenu()

}

async function saveEdit(){
    
  if(deletingEvent.value == true){
    return
  }
  
  store.state.dayevent.removedDays = []
   /* switch (selectedOption.value) {
    case '1':
      console.log('Diesen Termin');
      setRemovedDays(1);
      break;
    case '2':
      console.log('Diesen Termin und alle folgenden');
      setRemovedDays(2);
      break;
    case '3':
      console.log('Alle Termine');
      store.state.dayevent.removedDays = [];
      // Do nothing
      break;
    default:
      console.log("Error - AddNewItemBox - saveEdit - switch(selectedOption.value)");
}*/

    if(textInput.value == ""){
      toast.error("Bitte einen Titel für das Ereignis eintragen")
      return
    }

    showSavingCircleNewEvent.value = true
   
    let newEvent = {
      calendarId: props.calendarId,
      eventidDB: props.eventidDB,
      title: textInput.value,
      datefrom: props.datefrom , //format(store.state.dayevent.dateTo, 'yyyy-MM-dd')
      dateto: props.dateto, //format(store.state.dayevent.dateTo, 'yyyy-MM-dd')
      datefromJson: props.datefrom_json,//store.state.dayevent.dateFrom, 
      datetoJson: props.dateto_json,//store.state.dayevent.dateTo,
      fromHour: store.state.dayevent.valueFromHour,
      fromMinute: store.state.dayevent.valueFromMinute,
      toHour: store.state.dayevent.valueToHour,
      toMinute: store.state.dayevent.valueToMinute,
      isFullDay: store.state.dayevent.isFullDay,
      additionalInfos: "", //this.additionalInfos,
      rRules: store.state.dayevent.rRules,
      location: "",
      removedDays: store.state.dayevent.removedDays,
      type: "1"
    }
    
    if(isAuthenticated.value == true){
      await store.dispatch("updateStoredEvent", newEvent);
    }else{
      for(let index = 0; index < store.state.events.items.length; index++){
            if(store.state.events.items[index].eventidDB == newEvent.eventidDB){
              store.state.events.items[index].title = newEvent.title,
              store.state.events.items[index].datefrom = newEvent.datefrom,
              store.state.events.items[index].dateto = newEvent.dateto,
              store.state.events.items[index].datefromJson = newEvent.datefromJson,
              store.state.events.items[index].datetoJson = newEvent.datetoJson,  
              store.state.events.items[index].fromHour = newEvent.fromHour,
              store.state.events.items[index].fromMinute = newEvent.fromMinute,
              store.state.events.items[index].toHour = newEvent.toHour,
              store.state.events.items[index].toMinute = newEvent.toMinute,
              store.state.events.items[index].isFullDay = newEvent.isFullDay,
              store.state.events.items[index].additionalInfos =  newEvent.additionalInfos,
              store.state.events.items[index].rRules =  newEvent.rRules,
              store.state.events.items[index].location= (newEvent.location == undefined)? "" : newEvent.location,
              store.state.events.items[index].removedDays = [],//data.removedDays
              store.state.events.items[index].type = newEvent.type
            }
        }
        //createIndexList
        for (let index = 0; index < store.state.events.items.length; index++){
          store.state.events.items[index].index = index;
        }
    }
    
    calenderCore1.updateCalender();

  }


async function deleteEvent(){

  showSavingCircleDeleteEvent.value = true

  deletingEvent.value = true

  calenderCore1.unSetEditMode();

  let Event = {
      calendarId: props.calendarId,
      eventidDB: props.eventidDB,
  }
  if(isAuthenticated.value == true){
    await store.dispatch("deleteEventFromCalendar", Event);
  }
  

  // delete event from eventlist
  for(let index = 0; index < events.value.length; ++index){
        if(events.value[index].calendarId == props.calendarId && events.value[index].eventidDB == props.eventidDB){
            events.value.splice(index,1);
    }
  }

  // Refresh Events Indexlist
  for(let index = 0; index < store.state.events.items.length; ++index){
      store.state.events.items[index].index = index;
  }

  // Write each event index in the events of each mycalendars
  for (let calenderIndex = 0; calenderIndex < store.state.mycalendars.items.length; calenderIndex++) {
    store.state.mycalendars.items[calenderIndex].events = []
      for (let eventIndex = 0; eventIndex < store.state.events.items.length; eventIndex++){
          if(store.state.mycalendars.items[calenderIndex].calendarId == store.state.events.items[eventIndex].calendarId){
            store.state.mycalendars.items[calenderIndex].events.push(eventIndex)
          }
      } 
  } 


  
      
  calenderCore1.updateCalender();
  



//emits('closeNewEventMenue')

} 


const emit = defineEmits(['close-menu'])

function closeMenu(){
    emit('close-menu')
}



</script>

<style scoped>


.main-div{
      margin-left:8px;
      margin-right:8px;
      margin-top: -68px;
}

.grid-container {
  padding-top: 16px;
  display: grid;
  grid-template-columns: auto 55px 61px;
}

.small-textbox {
  width: 208px; /* Adjust the width as needed */
  height: 22px;
  margin-top: 8px; /* Optional: for better appearance */
  margin-left: 8px;
  font-size: 14px; /* Optional: adjust font size */
  border-radius: 4px; /* Rounded corners */
  background-color: #F6F2EF; /* Grey background */
  border: 0px  /* Optional: border styling */
}

.small-textbox::placeholder {
  padding-left: 4px; /* Padding for the placeholder text */
}

.next-icon {
  color: white;
  font-size: 18px;
  font-weight: bold;
}

.circular-progress-trash{
  margin-top: 11px;
  margin-left: 5px;
  width: 30px;
  height: 30px;
  border-radius: 8px;
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
}

.circular-progress-save{
  margin-top:11px;
  margin-left:24px;
  width: 30px;
  height: 30px;
  border-radius: 8px;
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
}

.arrow-back{
  margin-top: 1px;
  margin-right: 10px;
  width: 44px;
  height: 44px;
  background-color: black;
  border-radius: 4px;
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
}

.icon-wrapper {
display: inline-block;
}

icon-trash-activ:hover{
transform: scale(1.5);
}

.icon-trash-activ {

  margin-top: 15px;
  margin-left: 15px;
  transition: transform 0.2s ease-in-out;
  color: rgb(255, 0, 0); 
}

.icon-trash-inactiv {
  padding-top: 5px;
  font-size: 18px;
  opacity: 1; /* Icon initial unsichtbar */
  transition: opacity 0.3s ease; /* Weicher Übergang für die Sichtbarkeit */
  color: rgb(198, 198, 198); /* Icon weiß */
}

.headline{
  padding-top: 2px;
  font-size: 15px;
  font-weight: 300;
  letter-spacing: -0.6px;
}

.headline-grid-container {
  padding-left:8px;
  display: grid;
  grid-template-columns: 24px 255px 70px ;
  height: 40px;
}

.headline-grid-item {
  display: flex;
  align-items: center;
  justify-content: start; /* links ausgerichtet */   
}

.headline-grid-item-close{
padding-top: 8px;

}

.headline-grid-item-close:hover{
font-weight: 500;
cursor: pointer; 

}

.dot {
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background-color: var(--dot--color);
  margin-top: 4px;
  margin-left: 0px;
}

.plus-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px; 
    height: 45px; 
    background-color: #E7E5DF;
    border: 1px solid #E7E5DF;
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
    margin-top: 0px;
    margin-left:15px;
  }
  
  .plus-icon {
    color: #191816;
    font-size: 24px;
    font-weight: bold;
    transition: color 0.3s ease;
  }
  
  .plus-button:hover {
    background-color: #191816;
    cursor: pointer; 
  }
  
  .plus-button:hover .plus-icon {
    color: #F6F2EF;
  }

  .button-text-cancel{
    font-size: 15px;
    font-weight: 400;
    color: #191816;
    letter-spacing: -0.6px;
    
    padding-left: 30px;
}

.button-text-save{
    font-size: 15px;
    font-weight: 400;
    color: white;
    letter-spacing: -0.6px;
    padding-right: 5px;
    padding-left: 5px;
}



</style>


