<template>
  <div :class="this.$store.state.settings.currentTheme + '-theme'">
    <v-btn 
        color="#191816"  
        class="text-none text-style" 
        density="default"
        :variant="variant"  
        style="
          font-weight: 300;
        "
        > 
        
        {{$t(props.linktext)}}
        
        <template v-slot:prepend>
          <PhIsland v-if="props.icon=='PhIsland'" style="color: #34332F; width: 15px; height: 15px;" />
          <PhConfetti v-if="props.icon=='PhConfetti'" style="color: #34332F; width: 15px; height: 15px;" />
          <PhCalendarDots v-if="props.icon=='PhCalendarDots'" style="color: #34332F; width: 15px; height: 15px;" />
          <PhCalendarPlus v-if="props.icon=='PhCalendarPlus'" style="color: #34332F; width: 15px; height: 15px;" />
          <PhCalendarStar v-if="props.icon=='PhCalendarStar'" style="color: #34332F; width: 15px; height: 15px;" />
        </template>

        <template v-slot:append>
            <div class="plus-button">
              <PhPlus class="plus-icon" />
            </div>
        </template>
      </v-btn> 
    </div>
</template>
    

    
<script setup>
    
import { defineProps, onBeforeMount, ref } from 'vue';

const currentIcon = ref('')

const props = defineProps({
  icon: {
    type: String,
    required: true,
  },
  linktext: {
    type: String,
    required: true,
  },
  variant: {
    type: String,
    required: true,
  },
});

onBeforeMount(() => initialize());
const variant = ref('')

const initialize = () => {
    currentIcon.value = props.icon
    variant.value = props.variant
}

</script>


<style scoped>

.plus-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 17px; /* Same as checkbox size */
  height: 17px; /* Same as checkbox size */
  background-color: #e7e5df;
  border: 1px solid #e7e5df;
  border-radius: 4px;
  transition: background-color 0.3s ease, color 0.3s ease;
}


  .text-style{
      font-size: 13px;
      font-weight: 400;
      color:var(--standard-text-color);
      letter-spacing: -0.6px;
  }
  
  .icon-style{
      color:var(--standard-text-color);
  }
  
  .btn {
      text-transform: unset !important;
      color:var(--standard-text-color);
  }
    
</style>