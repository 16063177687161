<template>
  <div :class="$store.state.settings.currentTheme + '-theme'">

    <v-card 
      style="
          padding: 8px;
          border-radius: 8px;
          background-color: white;
          margin-left: 4px;
          margin-top: -16px;
      "
    >

        <v-dialog v-model="menu" >

          <template v-slot:activator="{ props }">

          <div 
            v-bind="props" 
            @click="openMenu"
            class="row1"
          >
            <!-------------------------------- Left Icon ---------------------------------------->
            
            <div class="left-icon-back">
                  <PhCalendarDots class="left-icon"/>
            </div>

            <!---------------------------------- Text -------------------------------------------->
            
            <div class="center-column">
                {{linkText}}
            </div>

            <!-------------------------------- Right Icon ---------------------------------------->

            <div class="right-icon-back">
                  <div class="right-icon-container">
                  <PhGearSix class="right-icon" />
                  </div>
            </div>

          </div>
        
          </template>
        
              <AddNewCalendar 
                @closeCalendarMenu="closemenu"  
                :header=editcalendar
                prop_mode="edit"
                :prop_kalendername=props.name
                :prop_kalenderbeschreibung=props.description
                prop_externalCalendarLink=""
                :prop_radios= true
                :calendarColor= props.calendarColor
                prop_icon='ph-CalendarCheck'
                :prop_calendarId=props.calendarId
                :prop_docId=props.docId
                :prop_creatorUID=this.$store.state.user.userID
                showDeleteButton= true
                prop_type=1
                @close-menu="closeCalendarMenu"
                />
            
        </v-dialog>

  </v-card>

</div>
</template>

<script setup>
import { ref, defineProps, onMounted } from 'vue';
import AddNewCalendar from "./AddNewCalendar.vue";
import {useStore} from "vuex";

const store = useStore();

import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const editcalendar = ref(t('editcalendar') )

function openMenu(){
    menu.value = true
}

function closemenu(){
    menu.value = false
}


const props = defineProps({
    docId: {
    type: String,
    required: true
  },
  calendarId:{
    type: String,
    required: true
  },
  name: {
    type: String,
    required: true
  },
  calendarColor: {
    type: String,
    default: '#DAFDD8' // Default value if not provided
  },
  calendarInfo:{
    type: Object,
    required: true
  },
  description: {
    type: String,
    default: 'true'
  },
});

const linkText = ref("")

onMounted(() => {
  store.state.settings.Log_Status = ""
  store.state.settings.AnnucalSaveText = t("save") 
  store.state.settings.AnnucalDeleteText = t("delete")
  store.state.settings.AnnucalVcardHeadline = t("editcalendar")
  store.state.settings.normlaErrorMessage = ""
  linkText.value = props.calendarInfo.name
});


const menu = ref(false);

function closeCalendarMenu() {
  menu.value = false;
}


</script>

<style scoped>

.plus-button:hover {
    cursor: pointer;
}
  
.settings-div{
    margin-top: 2px;
    width:24px;
    height: 24px;
    background-color: #E7E5DF;
    border-radius: 8px;
    display: flex;
    justify-content: center; /* Centers horizontally */
    align-items: center; /* Centers vertically */
  }

.settings-div:hover{
  background-color: #34332F;
}

.setting-icon{
  color: #34332F;
  width: 18px;
  height: 18px;
  margin-top: 5px;
}

.setting-icon:hover{
  color: white;
}

.container {
  display: grid;
  grid-template-columns: 1fr 25px; /* 2 gleiche Spalten */
  gap: 10px;
}


.annicual-button-grid {
  display: grid;
  grid-template-rows: auto; /* Automatische Höhe für jede Row */
  grid-auto-columns: auto; /* Automatische Breite für Spalten */
  
}

.row1 {
  display: grid; /* Jede Row bleibt ein Grid */
  grid-template-columns: 30px auto 30px; /* Spaltenaufteilung */
  align-items: center; /* Zentrierung vertikal */
  margin-bottom: 2px;
  padding:8px;
  background-color: white; /* Standardhintergrundfarbe */
  border-radius: 4px; /* Optional: Abgerundete Ecken */
}

.row2 {
  display: grid; /* Jede Row bleibt ein Grid */
  grid-template-columns: 30px auto 30px; /* Spaltenaufteilung */
  align-items: center; /* Zentrierung vertikal */
  background-color: white; /* Standardhintergrundfarbe */
  padding:8px;
  border-radius: 4px; /* Optional: Abgerundete Ecken */
}

.row1:hover,
.row2:hover {
  cursor: pointer; /* Zeigt den Zeiger an */
  background-color: #e6e5e55a; /* Hover-Hintergrundfarbe */
}
  
  .left-icon {
    width: 16px;
    height: 16px;
  }
  
  .left-icon-back {
    
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .right-icon-back {
    display: flex;
    justify-self: end; /* Horizontal nach rechts ausgerichtet */
    align-items: center;
  }
  
  .right-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #E7E5DF;
    border-radius: 4px; /* Runde Form */
    width: 16px; /* Größe des grauen Quadrats */
    height: 16px;
    margin-right: 4px;
  }
  
  .right-icon {
    width: 12.8px;
    height: 12.8px;
  }
  
  .center-column {
    text-align: left;
    font-size: 12px;
    font-weight: 400;
    color: var(--standard-text-color);
    letter-spacing: -0.6px;
    line-height: 16px;
    padding-right: 4px;
    
  }

  .card-style{
    border-radius: 8px;
    margin-top: -15px;
    box-shadow: 0 0 32px rgba(0, 0, 0, 0.2);
    padding-left:4px;
    padding-right: 4px;
    padding-top: 8px; 
    padding-bottom: 8px;
    margin-left: 3px;
  }

</style>