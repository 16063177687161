<template>
    <div :class="$store.state.settings.currentTheme + '-theme'">
        
            <v-card style="
                padding: 8px;
                border-radius: 8px;
                background-color: white;
                margin-left: 34px;
                margin-top: -26px;
            ">

            <div class="annicual-button-grid">

                <!---------------------------------------------------------------------------------------->
                <!------------------------------- Show Calendarweek setting ------------------------------>
                <!---------------------------------------------------------------------------------------->
    
                  <div 
                    @click="checkBoxClicked('togglekw')" 
                    class="row1"
                    style="padding-right: 20px;"
                  >

                    <!--------------------------------------- Text ----------------------------------------->
                
                    <div class="center-column">
                        {{showcalendarweek}}
                    </div>
                   
                    <!------------------------------------ Toggle Button ----------------------------------->

                    <div style="
                        margin-left: 16px;
                        padding-bottom: 2px;
                        " class="toggle">
                        <input 
                            v-model="state.settings.showKalenderwochen"
                            type="checkbox" 
                            id="togglekw"
                            @change="checkBoxClicked('togglekw')" 
                        />

                        <div class="toggle-handle"></div>

                      <label for="togglekw"  >
                        <div 
                          @click="checkBoxClicked('togglekw')"
                          style="
                          padding-left: 40px;
                          margin-right: 20px;
                          padding-bottom:26px;
                          font-size: small;
                          text-align: center;
                          height: 14px;
                          line-height: 15px;
                          font-weight:600;
                          width: 80px;
                          ">
                        </div>
                    </label>
                </div>




                </div>
              
                 
                <!---------------------------------------------------------------------------------------->
                <!--------------------------------- Show Today Settings ---------------------------------->
                <!---------------------------------------------------------------------------------------->
                
                  
                  <div 
                    @click="checkBoxClicked('toggledayView')" 
                    class="row2"
                    style="padding-right: 20px;"
                  >
                    <!--------------------------------------- Text ----------------------------------------->

                    <div class="center-column">
                        {{showtoday}}
                    </div>
                    
                    <!------------------------------------ Toggle Button ----------------------------------->

                    <div style="
                        padding-left: 16px;
                        padding-bottom: 2px;
                        " class="toggle">
                    <input 
                    v-model="state.settings.showCurrentDay" 
                      type="checkbox" 
                      id="toggledayView"
                      @change="checkBoxClicked('toggledayView')" />

                    <div class="toggle-handle"></div>
                      <label for="toggledayView"  >
                        <div 
                          @click="checkBoxClicked('toggledayView')"
                          style="
                          padding-left: 40px;
                          padding-bottom:26px;
                          font-size: small;
                          text-align: center;
                          height: 14px;
                          line-height: 15px;
                          font-weight:600;
                          ">
                    </div>
                </label>
            </div>


                </div>
              

            </div>
            
        </v-card>
    </div>
    
  </template>
  
<script setup>
  
import { ref } from 'vue';
import { useI18n } from 'vue-i18n'

import { useStore } from 'vuex';

const store = useStore();
const { state } = store;



const { t } = useI18n()

const showcalendarweek = ref(t('showcalendarweek'))
const showtoday = ref(t('showtoday'))


function checkBoxClicked(checkBoxName){
      
      var checkBox = document.getElementById(checkBoxName);
      if (checkBox.checked == true){
        checkBox.checked = false;
        switch(checkBoxName) {
        case 'togglekw':
          state.settings.showKalenderwochen = false;
          break;
        case 'toggledayView':
          state.settings.showCurrentDay = false;
          break;
        default:
          // code block
      }
      } else {
        checkBox.checked = true;
        switch(checkBoxName) {
        case 'togglekw':
          state.settings.showKalenderwochen = true;
          break;
        case 'toggledayView':
          state.settings.showCurrentDay = true;
          break;
        default:
          // code block
      }
      }
    }
  
  
</script>
  
  <style scoped>


.annicual-button-grid {
  display: grid;
  grid-template-rows: auto; /* Automatische Höhe für jede Row */
  grid-auto-columns: auto; /* Automatische Breite für Spalten */
  
}

.row1 {
  display: grid; /* Jede Row bleibt ein Grid */
  grid-template-columns: auto 30px; /* Spaltenaufteilung */
  align-items: center; /* Zentrierung vertikal */
  margin-bottom: 2px;

  padding:8px;
  background-color: white; /* Standardhintergrundfarbe */
  border-radius: 4px; /* Optional: Abgerundete Ecken */
}

.row2 {
  display: grid; /* Jede Row bleibt ein Grid */
  grid-template-columns: auto 30px; /* Spaltenaufteilung */
  align-items: center; /* Zentrierung vertikal */
  background-color: white; /* Standardhintergrundfarbe */
  padding:8px;
  border-radius: 4px; /* Optional: Abgerundete Ecken */
}

.row1:hover,
.row2:hover {
  cursor: pointer; /* Zeigt den Zeiger an */
  background-color: #e6e5e55a; /* Hover-Hintergrundfarbe */
}
  
  .left-icon {
    width: 16px;
    height: 16px;
  }
  
  .left-icon-back {
    
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .right-icon-back {
    display: flex;
    justify-self: end; /* Horizontal nach rechts ausgerichtet */
    align-items: center;
  }
  
  .right-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #E7E5DF;
    border-radius: 4px; /* Runde Form */
    width: 16px; /* Größe des grauen Quadrats */
    height: 16px;
    margin-right: 4px;
  }
  
  .right-icon {
    width: 12.8px;
    height: 12.8px;
  }
  
  .center-column {
    text-align: left;
    font-size: 12px;
    font-weight: 400;
    color: var(--standard-text-color);
    letter-spacing: -0.6px;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }

.toggle label:hover{
    cursor: pointer; /* Zeigt den Zeiger an */
}

.toggle:hover{
    cursor: pointer; /* Zeigt den Zeiger an */
}

.toggle {
  margin-top: 4px;
  position: relative;
}
.toggle input[type="checkbox"] {
  opacity: 0;
  position:absolute;
}
.toggle label {
  background-color: #A39F96;
  border: 0px solid #A39F96;
  border-radius: 20px 20px 20px 20px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1) inset;
  height: 16px;
  position: relative;
  width: 29px;
  display: block;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
.toggle input:checked ~ label {
  background-color: #6278E9;
  background-image: linear-gradient(to bottom, #6278E9 0px, #6278E9 100%);
  border: 0px solid #6278E9;
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.25) inset;
}
.toggle .toggle-handle {
    background-color: #FFFFFF;
    background-image: linear-gradient(to bottom, #FFFFFF 0px, #F2F2F2 100%);
    border: 2px solid #A39F96;
    border-radius: 100px 100px 100px 100px;
    height: 16px;
    position: absolute;
    -webkit-transition: -webkit-transform 0.1s ease-in-out 0s, border 0.1s ease-in-out 0s;
    transition: transform 0.1s ease-in-out 0s, border 0.1s ease-in-out 0s;
    width: 16px;
    z-index: 2;
    pointer-events: none;
}
.toggle input:checked + .toggle-handle {
    border-color: #6278E9;
    -webkit-transform: translate3d(15px, 0px, 0px);
    transform: translate3d(15px, 0px, 0px);
}
.toggle label:after {
    color: #e81616;
    
    font-weight: bold;
    line-height: 28px;
    position: absolute;
    right: 13px;
    text-shadow: 0 1px #FFFFFF;
    text-transform: uppercase;
}
.toggle input:checked ~ label:after {
    color: #FFFFFF;
    
    left: 15px;
    right: auto;
    text-shadow: 0 -1px #A39F96;
}

  </style>
  