<template>
  
  <div style="position: relative;
      overflow: visible;
  ">
  <!-------------------------------------------------------------------------------------------->
  <!----------------------------------  v-card MAIN -------------------------------------------->
  <!-------------------------------------------------------------------------------------------->
    
      <v-card
          style="
            padding-bottom: 12px;
            "
          variant="flat"
          width="40px"
          class="vcard-style"
      >

      <!-------------------------------------------------------------------------------------------->
      <!----------------------------------  Calendar Icon ------------------------------------------>
      <!-------------------------------------------------------------------------------------------->

      <div style="padding-left: 10px; padding-top: 4px;">
        <div>
          <PhCalendarDots
            style="
              color: #34332F;
              width: 22px;
              height: 22px;
              position: absolute;
              top: 10px;
              left: 50%;
              transform: translate(-50%);
            "
          />
      </div>

      <!-------------------------------------------------------------------------------------------->
      <!----------------------------------  Progress circular -------------------------------------->
      <!-------------------------------------------------------------------------------------------->

        <div
          v-if="filteredCalendarsNoHolidays.length == 0"
          style="
            text-align: center;   
            padding-top: 32px;
            padding-right:6px;
            "
        >
          <v-progress-circular
            size="16"
            width="2"
            indeterminate
            color="#A39F96"
          ></v-progress-circular>
        </div>

      <!-------------------------------------------------------------------------------------------->
      <!----------------------------- Calendar Checkbox Group -------------------------------------->
      <!-------------------------------------------------------------------------------------------->

        <div class="checkbox-group" v-if="filteredCalendarsNoHolidays.length > 0">
          <div
            v-for="checkbox in items"
            :key="checkbox.docId"
            @mouseover="showMenu($event)"
            @mouseleave="hideMenu"
            ref="checkboxRefs"
          >
          
             <!-------------------------------------------------------------------------------------------->
          <!---------------------------------- Calendar Checkbox --------------------------------------->
          <!-------------------------------------------------------------------------------------------->

          <v-menu 
                :open-on-click = false
                activator="parent"
                open-on-hover
                close-on-content-click
                location="end"
                offset-x nudge-right="15" offset-y nudge-top="-25" 
            > 
            <!-------------------------------------------------------------------------------------------->
            <!---------------------------------- Kalender Hover Box -------------------------------------->
            <!-------------------------------------------------------------------------------------------->
            <calendar-list-details 
              :name="checkbox.name"
              :calendarColor="checkbox.color"
              :docId="checkbox.docId"
              :calendarId="checkbox.calendarId"
              :calendarInfo="checkbox"
              :description="checkbox.description"
              />
            </v-menu>
            
      <!-------------------------------------------------------------------------------------------->
      <!----------------------------------- CustomCheckbox ----------------------------------------->
      <!-------------------------------------------------------------------------------------------->

            <CustomCheckbox v-if="checkbox.type == '1'"
                :id="checkbox.docId"
                :checkbox-background-color="checkbox.color"
                v-model:value="checkbox.visible"
                @state-change="handleStateChange"
            />
            </div>
          </div>
          
         

      <!-------------------------------------------------------------------------------------------->
      <!-------------------------------- New Calendar Button --------------------------------------->
      <!-------------------------------------------------------------------------------------------->
        
        <div class="button-group" v-if="filteredCalendarsNoHolidays.length < 5">
          <div class="plus-button">
              
          <PhPlus class="plus-icon" />
     
          <v-menu 
            v-model="menu" 
              :openOnHover="true" 
              :openOnClick="true"
              activator="parent"
          >  
              <div style="
                  margin-left: 20px;
                  margin-right: 30px;
                  margin-top: -49px;
              "/> 
                  <addNewCalendarSelector
                    @closeCalendarMenu="closeCalendarMenu"  
                  />  
              </v-menu>
          </div>
        </div>

      <!-------------------------------------------------------------------------------------------->
      <!----------------------------------  Holiday Icon ------------------------------------------->
      <!-------------------------------------------------------------------------------------------->

        <div style="padding-top: 28px; padding-bottom: 2px;">
          <!--<PhSun style="color: #34332F; width: 20px; height: 20px;" />-->
          <PhIsland style="color: #34332F; width: 20px; height: 20px;" />
        </div>




<!-------------------------------------------------------------------------------------------->
      <!----------------------------- Calendar Checkbox Group -------------------------------------->
      <!-------------------------------------------------------------------------------------------->

      <div class="checkbox-group-holidays" v-if="filteredCalendarsHolidays.length < 5">
          <div
            v-for="checkbox in items"
            :key="checkbox.docId"
            @mouseover="showMenu($event)"
            @mouseleave="hideMenu"
            ref="checkboxRefs"
          >
          
             <!-------------------------------------------------------------------------------------------->
          <!---------------------------------- Calendar Checkbox --------------------------------------->
          <!-------------------------------------------------------------------------------------------->

          <v-menu 
                :open-on-click = false
                activator="parent"
               open-on-hover
                close-on-content-click
                location="end"
                offset-x nudge-right="15" offset-y nudge-top="-25" 
            > 
            <!-------------------------------------------------------------------------------------------->
            <!---------------------------------- Kalender Hover Box -------------------------------------->
            <!-------------------------------------------------------------------------------------------->
            <annucalHolidayListDetails
              :name="checkbox.name"
              :calendarColor="checkbox.color"
              :docId="checkbox.docId"
              :calendarId="checkbox.calendarId"
              :calendarInfo="checkbox"
              />
            </v-menu>
            
      <!-------------------------------------------------------------------------------------------->
      <!----------------------------------- CustomCheckbox ----------------------------------------->
      <!-------------------------------------------------------------------------------------------->

            <CustomCheckbox v-if="checkbox.type == '2' || checkbox.type == '3'"
                :id="checkbox.docId"
                :checkbox-background-color="checkbox.color"
                v-model:value="checkbox.visible"
                @state-change="handleStateChange"
            />
            </div>
          </div>






      <!-------------------------------------------------------------------------------------------->
      <!----------------------------------  New Holiday Button ------------------------------------->
      <!-------------------------------------------------------------------------------------------->
        
        <div class="button-group" >
          <div class="plus-button">
            <PhPlus class="plus-icon" />
            <v-menu
              v-model="menuHolidaysVisible"
              activator="parent"
              :openOnClick="true"
              :openOnHover="true" 
          >  
          <div style="
                  margin-left: 0px;
                  margin-right: 30px;
                  margin-top: -29px;
              ">
          <addNewHolidaySelector
                    @close-menu="closeMenu"
                    prop_mode="new"
                    :allowDelete = false
                 />
          </div>
                 
              </v-menu>
          </div>
        </div>













      <!-------------------------------------------------------------------------------------------->
      <!-----------------------------------  Different Views  -------------------------------------->
      <!-------------------------------------------------------------------------------------------->

       <!-- <v-divider style="
        margin-top:16px;
        margin-right: 8px;
        "></v-divider>



        <div class="plus-button" style="margin-top:16px">
            <PhList class="plus-icon" style="transform: rotate(90deg); "/>
        </div>
        <div class="plus-button" >
            <PhList class="plus-icon" />
        </div>
        <div class="plus-button" >
            <PhTextAlignRight class="plus-icon" />
        </div>

-->




      </div>

   

    </v-card>

  </div>

</template>

<script setup>
import { computed, ref } from 'vue';
import CustomCheckbox from './CustomCheckbox.vue';
import { useStore } from 'vuex';
import useAuth from '@/composition/useAuth';
import addNewCalendarSelector from './AddNewCalendarSelector.vue'
import addNewHolidaySelector from './AddNewHolidaySelector.vue'
import calendarListDetails from './CalendarListDetails.vue'
import annucalHolidayListDetails from './AnnucalHolidayListDetails.vue'
import calenderCore from "../composition/calenderCore";
import { PhIsland } from "@phosphor-icons/vue";

useAuth();

const store = useStore();
const { state } = store;

const menuHolidaysVisible = ref(false)

const filteredCalendarsNoHolidays = computed(() => {
  return state.mycalendars.items.filter(myCal => myCal.type === '1');
});

const filteredCalendarsHolidays = computed(() => {
  return state.mycalendars.items.filter(myCal => myCal.type != '1');
});

function closeMenu(){
  menuHolidaysVisible.value = false
}

const menu = ref(false);

const items = computed(() => state.mycalendars.items);

// Refs to track menu visibility and position
const menuVisible = ref(false);
const menuPosition = ref({ x: 0, y: 0 });
const checkboxRefs = ref([]);


    const calenderCore1 = calenderCore();

function closeCalendarMenu() {
  menu.value = false;
}

// Methods
function handleStateChange() {
  calenderCore1.updateCalender();
}

function showMenu(event) {
  menuVisible.value = true;
  const rect = event.currentTarget.getBoundingClientRect();
  menuPosition.value = { x: rect.right + 10, y: rect.top };
}

function hideMenu() {
  menuVisible.value = false;
}
</script>

<style scoped>
.checkbox-group {
  padding-top: 40px;
  display: flex;
  flex-direction: column;
}
.checkbox-group-holidays {
  padding-top: 4px;
  display: flex;
  flex-direction: column;
}

.custom-checkbox {
  margin-bottom: 2px;
}

.button-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.plus-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px; /* Same as checkbox size */
  height: 20px; /* Same as checkbox size */
  background-color: #e7e5df;
  border: 1px solid #e7e5df;
  border-radius: 4px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.plus-icon {
  color: #191816;
  font-size: 14px;
  font-weight: bold;
  transition: color 0.3s ease;
}

.plus-button:hover {
  background-color: #191816;
  cursor: pointer;
}

.plus-button:hover .plus-icon {
  color: white;
}

#tooltip {
  background-color: #333;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 13px;
}

.settings-div{
    margin-top: 2px;
    width:22px;
    height: 22px;
    background-color: #E7E5DF;
    border-radius: 8px;
    display: flex;
    justify-content: center; /* Centers horizontally */
    align-items: center; /* Centers vertically */

}

.settings-div:hover{
  background-color: #34332F;
}

.setting-icon{
  color: #34332F;
  width: 16px;
  height: 16px;
}

.setting-icon:hover{
  color: white;

}

.vcard-style{
  background-color: var(--back-flat); 
  border-radius:8px;
}
</style>

