<template>
    <v-card 
      location="top"
      offset-y

      style="
        border-radius: 8px;
        margin-top:-20px;
        margin-right: 68px;
        box-shadow: 0 0 32px rgba(0, 0, 0, 0.2);
        padding-bottom: 8px;
      "
    >
      
      <div style="
        padding-top: 8px;
        padding-left: 8px;
        padding-right: 8px;
        align-content: start
        ">     
        

            <!---------------------------------------------------------------------------------------->
            <!----------------------------------- School Holidays ------------------------------------>
            <!---------------------------------------------------------------------------------------->
            
            <v-dialog v-model="menuschool" :close-on-content-click="false" :openOnHover="false"  location="end">
                
                <template v-slot:activator="{ props }">
                    <div class="menue-grid" v-bind="props" @click="openMenuSchool">
                        <AnnucalLinkTwo style="float:left"
                            variant="text"
                            icon="PhIsland"
                            linktext= "schoolholidays" 
                            :showCalendarName = true
                            @click="dialog = true"
                        />  
                    </div>
                </template>

                    <AnnucalHolidays
                        @close-menu="closemenu"  
                        headline = schoolholidays
                    />

            </v-dialog>

            <!---------------------------------------------------------------------------------------->
            <!----------------------------------- Public Holidays ------------------------------------>
            <!---------------------------------------------------------------------------------------->
            <v-dialog v-model="menuPulic" :close-on-content-click="false" :openOnHover="false"  location="end">
                
                <template v-slot:activator="{ props }">
                    <div class="menue-grid" v-bind="props" @click="openMenuPublic">
                        <AnnucalLinkTwo style="float:left"
                            variant="text"
                            icon="PhConfetti"
                            linktext= "publicholidays" 
                            :showCalendarName = true
                            @click="dialog = true"
                        />  
                    </div>
                </template>

                    <AnnucalHolidays
                        @close-menu="closemenu"  
                        headline = "publicholidays"
                    />

            </v-dialog>
    
      
         
        </div>
    </v-card>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import AnnucalLinkTwo from "./AnnucalLinkTwo.vue"
//import AddNewCalendar from "./AddNewCalendar.vue";
import AnnucalHolidays from "./AnnucalHolidays.vue"
import {useStore} from "vuex";

function openMenuSchool(){
    menuschool.value = true
}

function openMenuPublic(){
    menuPulic.value = true
}

const store = useStore();

const menuPulic = ref(false);
const menuschool = ref(false);
    



onMounted(() => {
  store.state.settings.Log_Status = ""
  //store.state.settings.AnnucalSaveText = t("savecalendar")
  //store.state.settings.AnnucalVcardHeadline = t("createcalendar")
  store.state.settings.normlaErrorMessage = ""
});

function closemenu(){
    menuPulic.value = false
    menuschool.value = false
}



</script>

  
  <style scoped>

.text-style{
    font-size: 14px;
    font-weight: 300;
    color:var(--standard-text-color);
    letter-spacing: -0.6px;
}

  .grid-container {
    display: grid;
    grid-template-columns: 130px auto;
    background-color: #ffffff;
    padding: 16px; /* Gleichmäßiges Padding */
  }

  .grid-item-links-active {
    font-size: 14px;
    text-align: left;
    font-weight: 300;
    letter-spacing: -0.6px;
    user-select: none;
  }
  
  .grid-item-links-inactive {
    font-size: 14px;
    text-align: left;
    font-weight: 300;
    letter-spacing: -0.6px;
    color: #85827C;
    user-select: none;
  }
  
  .grid-item-rechts {}
  
  .plus-button-active {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18px; /* Gleiche Größe wie Checkbox */
    height: 18px; /* Gleiche Größe wie Checkbox */
    background-color: #e7e5df;
    border: 1px solid #e7e5df;
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
    margin-top: 2px;
    cursor: pointer;
  }

  .plus-button-inactive {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18px; /* Gleiche Größe wie Checkbox */
    height: 18px; /* Gleiche Größe wie Checkbox */
    background-color: #f1f1f1;
    border: 1px solid #f1f1f1;
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
    margin-top: 2px;
    
  }
  
  .plus-icon {
    color: #191816;
    font-size: 14px;
    font-weight: bold;
    transition: color 0.3s ease;
  }
  
  .plus-button-active:hover {
    background-color: #191816;
  }
  
  .plus-button-active:hover .plus-icon {
    color: white;
  }
  </style>
  