

import { useStore } from "vuex";
import { computed, ref } from "vue";
import format from 'date-fns/format' 
//import { datetime,rrulestr } from 'rrule'

export default function calenderCore() {
    const store = useStore();
    const { state } = store;
    
    const myCalendars = ref(computed(() => state.mycalendars.items));
    const items = ref(computed(() => state.calender.items));
    const dayDictionary = ref(computed(() => state.calender.dayDictionary));
    const events = ref(computed(() => state.events.items));
    
    //const daysWithEvents = ref(computed(() => state.daysWithEvents.items));

   /* function hasAllreadyEvent(dayId, calendarId, eventidDB){
        var hasEvent = false
        for (let month = 0; month < items.value.length; ++month){
            for (let day = 0; day < 32; ++day){
                if(items.value[month][day] != undefined){
                    if(items.value[month][day].id === dayId){
                        for(let x = 0; x < items.value[month][day].myCalendars.length; ++x){
                            if(items.value[month][day].myCalendars[x].info.calendarId === calendarId){
                                if(items.value[month][day].myCalendars[x].events.length > 0){
                                    if(items.value[month][day].myCalendars[x].events[0].eventidDB != eventidDB)
                                    hasEvent = true
                                    console.log("Day has event" + dayId)
                                }
                            }
                        }
                    }
                }  
            }
        }
        return hasEvent
    }*/

function hasOverlappingEventUp(newStartDatum, dateto, calendarId, eventidDB) {
    
    const newStart = new Date(newStartDatum);
    const varDateTo = new Date(dateto)
    for(let i = 0; i < events.value.length; ++i){
        if(events.value[i].calendarId === calendarId && eventidDB != events.value[i].eventidDB){
            const eventStart = new Date(events.value[i].datefrom);
            if ((newStart < eventStart && varDateTo > eventStart) ) {
                return true; 
            }
        }
    }
    return false; // Keine Überlappung gefunden
}

function hasOverlappingEventDown(newStartDatum, datefrom, calendarId, eventidDB) {
    
    const newStart = new Date(newStartDatum);
    const varDatefrom = new Date(datefrom)
    for(let i = 0; i < events.value.length; ++i){
        if(events.value[i].calendarId === calendarId && eventidDB != events.value[i].eventidDB){
            const eventEnd = new Date(events.value[i].dateto);
            if ((newStart > eventEnd && varDatefrom < eventEnd) ) {
                return true; 
            }
        }
    }
    return false; // Keine Überlappung gefunden
}


    /*function countDays(eventStart, eventEnd){
        const start = new Date(eventStart);
        const end = new Date(eventEnd);
        // Differenz in Millisekunden berechnen
        const differenzInMillisekunden = end - start;
        // Millisekunden in Tage umrechnen
        const tage = differenzInMillisekunden / (1000 * 60 * 60 * 24);
        // Ganze Zahl zurückgeben
        return Math.round(tage+1);
    }*/

    function hasAllreadyEventUp(dayId, calendarId, eventidDB){
        const event = events.value.filter(ev => ev.eventidDB === eventidDB)
        //const daysCount = countDays(dayId, event[0].dateto)
        if(hasOverlappingEventUp(dayId, event[0].dateto, calendarId, eventidDB)=== true){
            return true
        }else{
            var hasEvent = false
            for (let month = 0; month < items.value.length; ++month){
                for (let day = 0; day < 32; ++day){
                    if(items.value[month][day] != undefined){
                        if(items.value[month][day].id === dayId){
                            for(let x = 0; x < items.value[month][day].myCalendars.length; ++x){
                                if(items.value[month][day].myCalendars[x].info.calendarId === calendarId){
                                    if(items.value[month][day].myCalendars[x].events.length > 0){
                                        if(items.value[month][day].myCalendars[x].events[0].eventidDB != eventidDB)
                                        hasEvent = true
                                        console.log("Day has event" + dayId)
                                    }
                                }
                            }
                        }
                    }  
                }
            }
        }
    
        return hasEvent
    }

    function hasAllreadyEventDown(dayId, calendarId, eventidDB){
        const event = events.value.filter(ev => ev.eventidDB === eventidDB)
        if(hasOverlappingEventDown(dayId, event[0].datefrom, calendarId, eventidDB)=== true){
            return true
        }else{
            var hasEvent = false
            for (let month = 0; month < items.value.length; ++month){
                for (let day = 0; day < 32; ++day){
                    if(items.value[month][day] != undefined){
                        if(items.value[month][day].id === dayId){
                            for(let x = 0; x < items.value[month][day].myCalendars.length; ++x){
                                if(items.value[month][day].myCalendars[x].info.calendarId === calendarId){
                                    if(items.value[month][day].myCalendars[x].events.length > 0){
                                        if(items.value[month][day].myCalendars[x].events[0].eventidDB != eventidDB)
                                        hasEvent = true
                                        console.log("Day has event" + dayId)
                                    }
                                }
                            }
                        }
                    }  
                }
            }
        }
        
        return hasEvent
    }

    function setDragBackColor(dayId,count){
        try{
            for (let month = 0; month < items.value.length; ++month){
                for (let day = 0; day < 32; ++day){
                    if(items.value[month][day] != undefined){
                        if(items.value[month][day].id === dayId){
                            for(let daycount = 0; daycount < count; ++daycount){
                                for (let i = 0; i < items.value[month][day+daycount].myCalendars.length; ++i){
                                    if(items.value[month][day+daycount].myCalendars[i].info.calendarId === state.settings.currentDraggingCalendarId){
                                        items.value[month][day+daycount].myCalendars[i].dragShowSoftColor = true
                                    }
                                } 
                            }
                        }
                    }  
                }
            }
        }catch(e){
            console.log('Error: calenderCore.setDragBackColor ' + e)
        }
    }

    function resetDragBackColor(){
        for (let month = 0; month < items.value.length; ++month){
            for (let day = 0; day < 32; ++day){
                if(items.value[month][day] != undefined){
                    for(let x = 0; x < items.value[month][day].myCalendars.length; ++x){
                        items.value[month][day].myCalendars[x].dragShowSoftColor = false
                        
                    }
                }  
            }
        }
    }

    function HideArrows(){
        for (let month = 0; month < items.value.length; ++month){
            for (let day = 0; day < 32; ++day){
                if(items.value[month][day] != undefined){
                    for(let x = 0; x < items.value[month][day].myCalendars.length; ++x){
                        items.value[month][day].myCalendars[x].showarrowpullUp = false
                        items.value[month][day].myCalendars[x].showarrowpullDown = false  
                    }
                }  
            }
        }
    }

    function updatedateEvent(event, dayId){

        //let datenext = new Date(dayId);
        //let dateprev = new Date(dayId);
        //datenext.setDate(datenext.getDate() + 1);
        //dateprev.setDate(dateprev.getDate() - 1);
        
        for (let month = 0; month < items.value.length; ++month){
            for (let day = 0; day < 32; ++day)
            if(items.value[month][day] != undefined){
                if(items.value[month][day].events.length > 0){
                    for(let index3 = 0; index3 < items.value[month][day].events.length; ++index3){
                        if(items.value[month][day].events[index3].eventidDB == event.eventidDB ){
                            items.value[month][day].events.splice(index3, 1);
                            if(items.value[month][day].events.length == 0){
                                initDay(items.value[month][day]);
                               // initDay(items.value[x][y+1]);
                               // initDay(items.value[x][y-1]);
                            }else{
                                for(let x = 0; x < items.value[month][day].myCalendars.length; ++x){
                                    if(items.value[month][day].myCalendars[x].info.calendarId === event.calendarId){
                                        items.value[month][day].myCalendars[x].events = []
                                        items.value[month][day].myCalendars[x].showarrowpullUp = false,
                                        items.value[month][day].myCalendars[x].showarrowpullDown = false
                                    }
                                }
                            }
                        }
                    }               
                }
            }
        }


        /*var test34 = null

        for (let x = 0; x < items.value.length; ++x){
            for (let y = 0; y < 32; ++y)
            if(items.value[x][y] != undefined){
                for(let mc = 0; mc < items.value[x][y].myCalendars.length; mc++){
                    for(let mce = 0; mce < items.value[x][y].myCalendars[mc].events.length; mce++){
                       // if(items.value[x][y].myCalendars[mc].events[mce].find(ev => ev.eventidDB === event.eventidDB) === undefined){
                       //     initDay(items.value[x][y]);
                       // }
                       test34 = items.value[x][y].myCalendars[mc].events[mce].filter(ev => ev.eventidDB === event.eventidDB)
                       console.log("Event: " + test34)
                    }
                }
            }
        }*/

        const currentDate = new Date(dayId);
        currentDate.setDate(currentDate.getDate() );
       
        let allDates = getDatesBetween(
            new Date(event.datefrom),
            new Date(event.dateto) 
        );
        
        for(let index3 = 0; index3 < allDates.length; ++index3){
            updateSingleDay(items.value[dayDictionary.value[allDates[index3]].x][dayDictionary.value[allDates[index3]].y] , event)
        }

        for(let index3 = 0; index3 < allDates.length; ++index3){
            updateSingleDayHolidays(items.value[dayDictionary.value[allDates[index3]].x][dayDictionary.value[allDates[index3]].y] , event)
        }

        state.settings.FixedWithMousUp = false
    }

    function getDatesBetween(startDate, endDate) {
        const currentDate = new Date(startDate.getTime());
        const dates = [];
        while (currentDate <= endDate) {
          //dates.push(new Date(currentDate));
          dates.push(format(currentDate, 'yyyy-MM-dd'));
          currentDate.setDate(currentDate.getDate() + 1);
        }
        return dates;
    }

   /* function getStartDate(d,rule){
        var month = d.getMonth() + 1;
        var month2 = month < 10 ? '0' + month : month
        var day = d.getDate();
        var day2 = day < 10 ? '0' + day : day;


        var varDtStart = 'DTSTART:'+ d.getFullYear()+''+ month2 +''+ day2 +'T143000Z\nRRULE:'+ rule
        return varDtStart
        //console.log(varDtStart)
        
    }*/

    /*function getNextDay(currentDay,r2){
        //console.log("getNextDay: " + new Date(new Date(currentDay).getTime() + (24 * 60 * 60 * 1000*r2)))
        return(format(new Date(new Date(currentDay).getTime() + (24 * 60 * 60 * 1000*r2)),'yyyy-MM-dd'))
        //console.log("getNexDay: "  + new Date(currentDay) + " - " + r2)
    }*/

    function setUpArrows(calId,calEvent){
        //-------------- Now setting the arrow up and arrow down image -----------------------
        for (let month = 0; month < items.value.length; ++month){
            for(let day = 0; day < 31; day++){
                for(let mc = 0; mc < items.value[month][day].myCalendars.length; ++mc){
                    //if(calId != items.value[month][day].myCalendars[mc].info.calendarId){
                        items.value[month][day].myCalendars[mc].showarrowpullUp = false
                        items.value[month][day].myCalendars[mc].showarrowpullDown = false
                    //}
                }
            }
        }
        for (let month = 0; month < items.value.length; ++month){
            for(let day = 0; day < 31; day++){
                for(let mc = 0; mc < items.value[month][day].myCalendars.length; ++mc){
                    if(items.value[month][day].id == calEvent.datefrom && calId == items.value[month][day].myCalendars[mc].info.calendarId ){
                        items.value[month][day].myCalendars[mc].showarrowpullUp = true
                    }
                    if(items.value[month][day].id == calEvent.dateto && calId == items.value[month][day].myCalendars[mc].info.calendarId ){
                        items.value[month][day].myCalendars[mc].showarrowpullDown = true
                    }
                }
            }
        }
    }

    function dayTheCalendarHasAlreadyAnEvent(itemId, newStartDate, newEndDate, currentDragginCalendarId, currentDragginEventIdDB){
        let correctEndDate = new Date(itemId)
        let insert = true;
        for (var d = newStartDate; d <= newEndDate; d.setDate(d.getDate() + 1)) {
            if(insert === false){
              break;
            }
            else{
                let newYearTo = d.getYear() + 1900
                let newMonthTo = d.getMonth() + 1
                let newDayTo = d.getDate()
                if(newMonthTo < 10) newMonthTo = "0"+newMonthTo;
                if(newDayTo < 10) newDayTo = "0"+newDayTo;

                for (let month = 0; month < items.value.length; ++month){// Calendar Months
                    for(let day = 0; day < 31; day++){// Calendar days
                        //----------- if Calendarday.id === new day to -------------------
                        if(((newYearTo + "-" + newMonthTo + "-" + newDayTo) === items.value[month][day].id)){
                            //--------- go through the calendards of the day -----------------
                            for(let i3 = 0; i3 < items.value[month][day].myCalendars.length; ++i3){
                                //---------- find the current calendar through the calendarId ----------
                                if(items.value[month][day].myCalendars[i3].info.calendarId === currentDragginCalendarId){
                                    if(items.value[month][day].myCalendars[i3].events.length > 0){
                                        if(items.value[month][day].myCalendars[i3].events[0].eventidDB != currentDragginEventIdDB ){
                                            insert = false;
                                            d.setDate(d.getDate() - 1)
                                            correctEndDate = d
                                        }
                                    }else{
                                        correctEndDate = d
                                    } 
                                }
                            } 
                        }
                    }
                }
            }
        }
        
        return correctEndDate
    }

    function setEditMode(date, calendarId){
        console.log(calendarId)
        try{
            for (let month = 0; month < items.value.length; ++month){
                for(let day = 0; day < 31; day++){
                    for(let mc = 0; mc < items.value[month][day].myCalendars.length; ++mc){
                        if(items.value[month][day].date == date && calendarId == items.value[month][day].myCalendars[mc].info.calendarId ){
                            items.value[month][day].myCalendars[mc].editmode = true
                        }
                        else{
                            items.value[month][day].myCalendars[mc].editmode = false
                        }
                    }
                }
            }
        }
        catch(e){
            console.log("Error: CalenderCore - setEditMode " + e)
        }
    }

    function unSetEditMode(){
        try{
            for (let month = 0; month < items.value.length; ++month){
                for(let day = 0; day < 31; day++){
                    for(let mc = 0; mc < items.value[month][day].myCalendars.length; ++mc){
                        items.value[month][day].myCalendars[mc].editmode = false                    
                    }
                }
            }
        }
        catch(e){
            console.log("Error: CalenderCore - setEditMode " + e)
        }
    }
  

    function updateCalender(){
        try{
            for (let month = 0; month < items.value.length; ++month){
                for(let day = 0; day < 31; day++){
                    this.initDay(items.value[month][day]);
                }
            }
            for(let index2 = 0; index2 < myCalendars.value.length; ++index2){                       // Loop through my Calendars
                if(myCalendars.value[index2].visible == true){                                      // Only process if Calendars visible == true
                    for (let index = 0; index < myCalendars.value[index2].events.length; ++index){  // Loop through all events in processed calendars
                        let allDates = getDatesBetween(                                             // Check the dates of each event
                            new Date(events.value[myCalendars.value[index2].events[index]].datefrom),
                            new Date(events.value[myCalendars.value[index2].events[index]].dateto)
                        );
                        for(let index3 = 0; index3 < allDates.length; ++index3){
                            try{
                                updateSingleDay(items.value[dayDictionary.value[allDates[index3]].x][dayDictionary.value[allDates[index3]].y] , events.value[myCalendars.value[index2].events[index]])
                            }catch{
                                //console.log("Event vor 2024 oder nach 2025")
                            }
                        }
                       
                        //-------------------- RRULE START ---------------------------------

                       /* if(events.value[myCalendars.value[index2].events[index]].rRules != undefined && events.value[myCalendars.value[index2].events[index]].rRules.toLowerCase() != "once" ){
                            var ruleString = events.value[myCalendars.value[index2].events[index]].rRules
                            
                            var testrruleset = rrulestr(getStartDate(new Date(events.value[myCalendars.value[index2].events[index]].datefrom),ruleString))
                            console.log(testrruleset.toText())

                            let countDays = getDatesBetween(                                             // Check the dates of each event
                            new Date(events.value[myCalendars.value[index2].events[index]].datefrom),
                            new Date(events.value[myCalendars.value[index2].events[index]].dateto)
                        );
                            console.log("countDays: " + countDays.length)
                            
                            var Alle = testrruleset.between(datetime(2024, 1, 1), datetime(2025, 12, 31));
                            for (let i12 = 0; i12 < Alle.length; i12++) {
                              
                              if(i12 > 0){
                                try{
                                    for(let r2 = 0; r2 < countDays.length; r2++){
                                        let myfilter =  getNextDay(items.value[dayDictionary.value[format(Alle[i12],'yyyy-MM-dd')].x][dayDictionary.value[format(Alle[i12],'yyyy-MM-dd')].y].date, r2)

                                        //let date = new Date(items.value[dayDictionary.value[format(Alle[i12],'yyyy-MM-dd')].x][dayDictionary.value[format(Alle[i12],'yyyy-MM-dd')].y].date)
                                        //let date2 = new Date(date.getTime() + (24 * 60 * 60 * 1000*r2)) 
                                        var test = items.value[dayDictionary.value[myfilter].x][dayDictionary.value[myfilter].y].date 
                                        console.log(test)
                                        
                                        updateSingleDay(items.value[dayDictionary.value[myfilter].x][dayDictionary.value[myfilter].y] , events.value[myCalendars.value[index2].events[index]])
                                    }
                                }catch(e){
                                    console.log(e + "Event vor 2024 oder nach 2025")
                                }  
                                
                              }
                              
                            } 
        
                          }*/
                          //-------------------- RRULE END -----------------------------------
                    }
                }
            }
            for(let index2 = 0; index2 < myCalendars.value.length; ++index2){                       // Loop through my Calendars
                if(myCalendars.value[index2].visible == true){                                      // Only process if Calendars visible == true
                    for (let index = 0; index < myCalendars.value[index2].events.length; ++index){  // Loop through all events in processed calendars
                        let allDates = getDatesBetween(                                             // Check the dates of each event
                            new Date(events.value[myCalendars.value[index2].events[index]].datefrom),
                            new Date(events.value[myCalendars.value[index2].events[index]].dateto)
                        );
                      
                        for(let index3 = 0; index3 < allDates.length; ++index3){
                            try{
                                updateSingleDayHolidays(items.value[dayDictionary.value[allDates[index3]].x][dayDictionary.value[allDates[index3]].y] , events.value[myCalendars.value[index2].events[index]])
                            }catch{
                                //event vor 2024 oder nach 2025")
                            }
                        }

                    }
                }
            }
            
            state.settings.FixedWithMousUp = false

        }
        catch(e){
            console.log("Error: updateCalender " + e)
        }
        
    }

    function initDay(day){   
        
        if(day.events){
            day.events = []; 
        }
        day.showTooltip = false;   
        day.cursor = "default";

        if(day.weekday == "So" || day.weekday == "Sa"){
            day.backColor = state.settings.currentTheme == 'light'? state.settings.BackColorWeekend: '#201F1D';
            //day.backColor = state.settings.BackColorWeekend;
            day.BackColorWeekend = state.settings.currentTheme == 'light'? state.settings.BackColorWeekend: '#201F1D';
            //day.BackColorWeekend = state.settings.BackColorWeekend
            day.weekDayColor = state.settings.BackColorNormalDay
            //day.backColorWeekDay = state.settings.BackColorWeekend;
            day.backColorWeekDay = state.settings.currentTheme == 'light'? state.settings.BackColorWeekend: '#201F1D';
            
            day.weekdayFontWeight = 300
        }
        else{
            day.backColorWeekDay = state.settings.BackColorNormalDay;   
            day.weekDayColor = "#0b3558"  
            //day.backColor = state.settings.BackColorNormalDay;  
            day.backColor = state.settings.currentTheme == 'light'? '#FFFFFF': '#191816';
            day.weekdayFontWeight = 300             
        }

        
            day.myCalendars = [];
            for(let index2 = 0; index2 < myCalendars.value.length; ++index2){
                day.myCalendars.push({
                        events: [],
                        info: myCalendars.value[index2],
                        softcolor: getCalendarColor(myCalendars.value[index2].color),
                        color:myCalendars.value[index2].color,
                        menueopen: false,
                        editmode: false,
                        date: day.date,
                        type: myCalendars.value[index2].type,
                        dragmode:false,
                        showarrowpullUp:false,
                        showarrowpullDown:false,
                        dragShowSoftColor: false,
                        
                    })
            }
        

    }

    function showUpArrow(myCal, theDay){
        for(let index = 0; index < theDay.events.length; index++){
            if (theDay.events[index].calendarId === myCal.calendarId){
                if(theDay.id === theDay.events[index].datefrom && state.settings.curentActivePushAndPullCalendarId === myCal.calendarId ){
                    return true
                }else{
                    return false
                }
            }
        }
    }

    function showDownArrow(myCal, theDay){
        for(let index = 0; index < theDay.events.length; index++){
            if (theDay.events[index].calendarId === myCal.calendarId){
                if(theDay.id === theDay.events[index].dateto && state.settings.curentActivePushAndPullCalendarId === myCal.calendarId){
                    return true
                }else{
                    return false
                }
            }
        }
    }

    function hasEvent(myCal_CalendarId, theDay){
        var events = []
        for(let index = 0; index < theDay.events.length; index++){
            if (theDay.events[index].calendarId == myCal_CalendarId){
                events.push(theDay.events[index])
            }
        }
        return events
    }

    function  getCalendarColor(cl){
        if(cl == "#6AD069")
            return "#DAFDD8"
        else if(cl == "#4BA0C4")
            return "#C4EDFF"
        else if(cl == "#5C70D7")
            return "#D1D8FF"
        else if(cl == "#9D41D5")
            return "#E5BBFF"
        else if(cl == "#D272DA")
            return "#FABDFF"
        else
            return "#ff0000"

    }

    function updateSingleDayHolidays(day,event){
        if(event.publicHoliday != undefined && event.publicHoliday == true){
            day.backColorWeekDay = '#C6C3BB'
            day.weekDayColor = '#FFFFFF'
            day.showTooltip = true;
        }
    }

    function updateSingleDay(day, event){
        try{
            if(day == undefined){ // We currently only have 1 year in the dayDictionary
                return
            }
    
            if(event == undefined){ 
                return
            }
    
            if(day.weekday == "So" || day.weekday == "Sa"){
                day.backColorWeekDay = state.settings.BackColorWeekend;
                day.weekDayColor = "#0b3558"
                day.weekdayFontWeight = 300
            }
            else{
                day.weekDayColor = "#0b3558"
                //day.backColorWeekDay = this.events.value[index].color;    
                day.weekdayFontWeight = 300    
            }

          
            day.dayHasEvent = true;
            
            day.events.push({
                eventId: event.eventidDB,
                eventName: event.title,
                additionalInfos: event.additionalInfos,
                eventColor : event.color,
                icon: event.icon,
                menuopen: event.menuopen,
                eventInfoBoxOpen: event.eventInfoBoxOpen,
                creatorUID: event.creatorUID,
                calendarName: event.calendarName   ,
                calendarId: event.calendarId,
                eventidDB: event.eventidDB,
                datefrom: event.datefrom,
                dateto: event.dateto,
                fromHour: event.fromHour,
                fromMinute: event.fromMinute,
                toHour: event.toHour,
                toMinute: event.toMinute,
                isFullDay: event.isFullDay,
                index: event.index,
                draggable: "false",
                cursor:"default",
                rRule: event.rRules,
                location: event.location,
                deletemode: false,
                type: event.type
                })
            
            //if(day.events.length > 1){    
                //day.showTooltip = true;
            //}
            //else{
                //  day.showTooltip = false;
            //}
            day.myCalendars = []
            for(let index23 = 0; index23 < myCalendars.value.length; ++index23){                
                day.myCalendars.push({
                    events: hasEvent(myCalendars.value[index23].calendarId,day),
                    info: myCalendars.value[index23],
                    softcolor: getCalendarColor(myCalendars.value[index23].color),
                    color:myCalendars.value[index23].color,
                    menueopen: false,
                    editmode: false,
                    date: day.date,
                    type:myCalendars.value[index23].type,
                    dragmode:false,
                    showarrowpullUp:showUpArrow(myCalendars.value[index23],day),
                    showarrowpullDown:showDownArrow(myCalendars.value[index23],day),
                    dragShowSoftColor: false
                })
            }
            
        }catch(e){
            console.log("Error in updateSingleDay: " + e)
        }
       
    }

    return{
        updateCalender,
        updatedateEvent,
        updateSingleDay,
        initDay,
        events,
        items,
        setEditMode,
        unSetEditMode,
        setUpArrows,
       dayTheCalendarHasAlreadyAnEvent,
       HideArrows,
       hasAllreadyEventUp,
       hasAllreadyEventDown,
       resetDragBackColor,
       setDragBackColor
    }
}

