<template>
    <div :class="$store.state.settings.currentTheme + '-theme'">
        
            <v-card style="
                padding: 8px;
                border-radius: 8px;
                background-color: white;
                margin-left: 34px;
                margin-top: -26px;
            ">

            <div class="annicual-button-grid">

                <!---------------------------------------------------------------------------------------->
                <!----------------------------------- School Holidays ------------------------------------>
                <!---------------------------------------------------------------------------------------->

                <v-dialog v-model="menuschool" :close-on-content-click="false" :openOnHover="false"  location="end">
                
                <template v-slot:activator="{ props }">
                  <div 
                    v-bind="props" 
                    @click="openMenuSchool"
                    class="row1"
                  >
                    <div v-if="showLeftIcon" class="left-icon-back">
                        <PhConfetti class="left-icon"/>
                    </div>
                    <div class="center-column">
                        {{schoolholidays}}
                    </div>
                    <div v-if="showRightIcon" class="right-icon-back">
                        <div class="right-icon-container">
                        <PhPlus class="right-icon" />
                        </div>
                    </div>
                </div>
                </template>

                <AnnucalHolidays
                  @close-menu="closemenu"  
                  :headline = schoolholidays
                  prop_mode="new"
                  calenderType="3"
                  :allowDelete=false
                />

              </v-dialog>
                 
                <!---------------------------------------------------------------------------------------->
                <!----------------------------------- Public Holidays ------------------------------------>
                <!---------------------------------------------------------------------------------------->

                <v-dialog v-model="menuPulic" :close-on-content-click="false" :openOnHover="false"  location="end">
                
                <template v-slot:activator="{ props }">
                  <div 
                    v-bind="props" 
                    @click="openMenuPublic"
                    class="row2"
                  >
                    <div v-if="showLeftIcon" class="left-icon-back">
                        <PhIsland class="left-icon"/>
                    </div>
                    <div class="center-column">
                        {{publicholidays}}
                    </div>
                    <div v-if="showRightIcon" class="right-icon-back">
                        <div class="right-icon-container">
                        <PhPlus class="right-icon" />
                        </div>
                    </div>
                </div>
                </template>

                <AnnucalHolidays
                  @close-menu="closemenu"  
                  :headline = publicholidays
                  prop_mode="new"
                  calenderType="2"
                  :allowDelete=false
                />

              </v-dialog>

            </div>
            
        </v-card>
    </div>
    
  </template>
  
<script setup>
  
import { ref } from 'vue';
import { useI18n } from 'vue-i18n'
import AnnucalHolidays from "./AnnucalHolidays.vue"
import { useStore } from 'vuex';

const store = useStore();
const { state } = store;

const { t } = useI18n()

const schoolholidays = ref(t('schoolholidays'))
const publicholidays = ref(t('publicholidays'))

const showLeftIcon = ref(true);
const showRightIcon = ref(true);

const menuPulic = ref(false);
const menuschool = ref(false);

function openMenuPublic(){
  state.currentCalendarType = 2
    menuPulic.value = true
    
}

function openMenuSchool(){
  state.currentCalendarType = 3
    menuschool.value = true
    
}

function closemenu(){
    menuPulic.value = false
    menuschool.value = false
}
  
  
</script>
  
  <style scoped>


.annicual-button-grid {
  display: grid;
  grid-template-rows: auto; /* Automatische Höhe für jede Row */
  grid-auto-columns: auto; /* Automatische Breite für Spalten */
  
}

.row1 {
  display: grid; /* Jede Row bleibt ein Grid */
  grid-template-columns: 30px auto 30px; /* Spaltenaufteilung */
  align-items: center; /* Zentrierung vertikal */
  margin-bottom: 2px;
  padding:8px;
  background-color: white; /* Standardhintergrundfarbe */
  border-radius: 4px; /* Optional: Abgerundete Ecken */
}

.row2 {
  display: grid; /* Jede Row bleibt ein Grid */
  grid-template-columns: 30px auto 30px; /* Spaltenaufteilung */
  align-items: center; /* Zentrierung vertikal */
  background-color: white; /* Standardhintergrundfarbe */
  padding:8px;
  border-radius: 4px; /* Optional: Abgerundete Ecken */
}

.row1:hover,
.row2:hover {
  cursor: pointer; /* Zeigt den Zeiger an */
  background-color: #e6e5e55a; /* Hover-Hintergrundfarbe */
}
  
  .left-icon {
    width: 16px;
    height: 16px;
  }
  
  .left-icon-back {
    
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .right-icon-back {
    display: flex;
    justify-self: end; /* Horizontal nach rechts ausgerichtet */
    align-items: center;
  }
  
  .right-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #E7E5DF;
    border-radius: 4px; /* Runde Form */
    width: 16px; /* Größe des grauen Quadrats */
    height: 16px;
    margin-right: 4px;
  }
  
  .right-icon {
    width: 12.8px;
    height: 12.8px;
  }
  
  .center-column {
    text-align: left;
    font-size: 12px;
    font-weight: 400;
    color: var(--standard-text-color);
    letter-spacing: -0.6px;
    
  }

  </style>
  