<template>

    <!-------------------------------------------------------------------------------------------->
    <!---------------------------------------- Log In  ------------------------------------------->
    <!-------------------------------------------------------------------------------------------->
    <div style="
            margin-right: -32px;
            margin-top: -4px;
        ">
        <AnnucalVCard
            :first_column_width='0'
            :calendarColor = props.calendarColor
            :showCalendarName = true
            :showvCardActions = false
            :VcardWitdh = '190'
            :calendarName=topheadline
            @close-menu="closeMenu"
        >
            <!-------------------------------------------------------------------------------------------->
            <!------------------------------  Middle Slot - Annucal VCard -------------------------------->
            <!-------------------------------------------------------------------------------------------->

            <template v-slot:middle>
                <!--------------------------------  Log In --------------------------------------> 
                    <div v-if="isAuthenticated  == false">
                        <AnnucalLink 
                            variant="text"
                            style="padding-top: 4px"
                            icon="PhUserCircle"
                            linktext="login" 
                            @click="openloginDialog"
                        />
                            
                        <v-dialog v-model="loginDialog" >
                            <annucal-sign-in
                            :showCalendarName = true
                            @close-menu="closeSignInMenu"
                            :creatorUID=this.$store.state.user.userID
                            prop_mode="login"
                            :Headline = loginHeadline
                            @closeDialog="closeloginDialog"
                        />
                        </v-dialog>
                      
                    </div>   

                <!--------------------------------  Log Out --------------------------------------> 
                <div v-if="isAuthenticated  == true">
                        <AnnucalLink 
                        variant="text"
                        style="padding-top: 12px"
                        icon="PhSignOut"
                        linktext="logout" 
                        @click="openLogoutDialog"
                    />
                        
                    <v-dialog v-model="loginDialog" >
                        <annucal-sign-in
                        :showCalendarName = true
                        @close-menu="closeSignInMenu"
                        :creatorUID=this.$store.state.user.userID
                        prop_mode="logout"
                        :Headline = logoutHeadline
                        @closeDialog="closeloginDialog"
                    />
                    </v-dialog>
                  
               
                </div>   
                

                <!-------------------------------  Einstellungen ------------------------------------>
                <div style="padding-top: 4px">
                    <AnnucalLink
                        variant="text"
                        icon="PhGear"
                        linktext= "settings" 
                        @click="openSettingsDialog"
                    />
                    <v-dialog 
                        v-model="settingsDialog" 
                        max-width="600">
                        <annucal-settings @closeDialog="closeSettingsDialog"/>
                        </v-dialog>
                </div>

            </template>

        </AnnucalVCard>
    </div>

</template>

<script setup>

import { defineProps, defineEmits, ref, computed, onMounted} from 'vue'
import {useStore} from "vuex";
import AnnucalVCard from "./AnnucalVcard.vue"
import AnnucalLink from "./AnnucalLink.vue"
import AnnucalSignIn from "./AnnucalSignIn.vue"
import AnnucalSettings from './AnnucalSettings.vue';
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const settingsDialog = ref(false)
const loginDialog = ref(false)

const topheadline = ref(t('menu') )
const loginHeadline = ref(t('login') )
const logoutHeadline = ref(t('logout') )

function openSettingsDialog() {
    settingsDialog.value = true;
    state.settings.AnnucalVcardHeadline = "Einstellungen"
}

function openloginDialog(){
    state.settings.AnnucalSignInPageVisible = true
    state.settings.AnnucalVcardHeadline = "Anmelden"
    state.settings.AnnucalSaveText = t("login")
    state.settings.Log_Status = "login"
    state.settings.showRegister = false
    state.settings.showForgotPassword = false
    loginDialog.value = true;
}

function openLogoutDialog(){
    state.settings.AnnucalSignInPageVisible = true
    state.settings.AnnucalVcardHeadline = "Abmelden"
    state.settings.AnnucalSaveText = t("logout")
    state.settings.Log_Status = "logout"
    state.settings.showRegister = false
    state.settings.showForgotPassword = false
    loginDialog.value = true;
}   

function closeloginDialog(){
    loginDialog.value = false;
    closeMenu()
}

function closeSettingsDialog() {
    settingsDialog.value = false;
    closeMenu()
}

const store = useStore();
const { state } = store;

const isAuthenticated = computed(() => store.getters['user/isAuthenticated']);

const props = defineProps({
    creatorUID:{
        type: String,
        required: true
    }
});

onMounted(() => initialize());

const initialize = () => {
    state.settings.AnnucalVcardHeadline = "menu"
}   



const emit = defineEmits(['close-menu'])

function closeMenu(){
    emit('close-menu')
}

function closeSignInMenu(){
    signInmenu.value = false
}




const signInmenu = ref(false);




</script>