<template>

  <!---------------------------------------------------------------------------------->
  <!------------------------------ App Vertical Grid --------------------------------->
  <!---------------------------------------------------------------------------------->

  <div class="app-container">

    <!---------------------------------------------------------------------------------->
    <!----------------------------------- Navbar --------------------------------------->
    <!---------------------------------------------------------------------------------->
    
    <header class="navbar">
      <div></div>
      <exchange-navbar
          :title="brandName"
          :items="menuItems"
        />
    </header>

    <!---------------------------------------------------------------------------------->
    <!----------------------------------- Main Grid ------------------------------------>
    <!---------------------------------------------------------------------------------->

    <div class="main-grid">
      <!---------------------------------------------------------------------------------->
      <!----------------------------------- Sidebar -------------------------------------->
      <!---------------------------------------------------------------------------------->
      <!-- Linke Spalte mit dynamischer Breite von 5% -->
      <div class="sidebar">
        <div class="layer-container">
            <calendar-side-control-vue/>
      </div>
    </div>

    <!---------------------------------------------------------------------------------->
    <!------------------------------- Calendar Grid View 1 ----------------------------->
    <!---------------------------------------------------------------------------------->
      <div class="scrollable-grid" v-if="this.$store.state.settings.calendarView == 1" >
        <!-- Scrollbares Grid mit 32 Zeilen und 24 Spalten -->
        <div class="grid">
          <div v-for ="(monthitem, index) in items" v-bind:key="index" > 
              <!--------------------------------- YEAR HEADLINE ----------------------------------->
              <div v-for ="(item, index2) in monthitem" v-bind:key="index2"> 
        
                <div 
                  style="
                  z-index: 0;

                  " 
                  v-if="!item.id.includes('noday')">
                  <PopoverCalenderday 
                    :item=item 
                  />


                </div>

                <div style="z-index: 0;" v-if="item.id.includes('noday')"></div>
              
              </div>
           
          </div>
     
        </div>
   
      </div>

      <!---------------------------------------------------------------------------------->
      <!------------------------------- Calendar Grid View 2 ----------------------------->
      <!---------------------------------------------------------------------------------->

      <div class="scrollable-grid" v-if="this.$store.state.settings.calendarView == 2" >
        <!-- Scrollbares Grid mit 32 Zeilen und 24 Spalten -->
        <div class="grid">
          <div v-for ="(monthitem, index) in items" v-bind:key="index" > 
              <!--------------------------------- YEAR HEADLINE ----------------------------------->
              <div v-for ="(item, index2) in monthitem" v-bind:key="index2"> 
        
                <div 
                  style="
                  z-index: 0;

                  " 
                  v-if="!item.id.includes('noday')">


                  <PopoverCalenderdayTranspose 
                    :item=item 
                  />
                </div>

                <div style="z-index: 0;" v-if="item.id.includes('noday')"></div>
              
              </div>
           
          </div>
     
        </div>
   
      </div>
 
    </div>

  </div>
</template>

<script>

import ExchangeNavbar from "./components/ExchangeNavbar.vue";
import CalendarSideControlVue from "./components/CalendarSideControl.vue"
import PopoverCalenderdayTranspose from "./components/PopoverCalenderdayTranspose.vue";
import PopoverCalenderday from "./components/PopoverCalenderday";
import calenderCore from "./composition/calenderCore";
import { useStore } from "vuex";
import { computed } from "vue";
import { v4 as uuidv4 } from 'uuid';
import { useI18n } from 'vue-i18n'


export default {

  setup() {
    const { locale } = useI18n();

    return {
      currentLanguage: locale,
    };
  },

  name: "MyGridComponent",
  components: {
    ExchangeNavbar,    
    CalendarSideControlVue,
    PopoverCalenderday,
    PopoverCalenderdayTranspose
  },

  data(){
      return {
        insertEventisOpen: false,
        deleteEventisOpen: false,
        selectedDate: "",
        selectedEvent: "",
        deleteBackgroundColor: "#00ff00",
        calenderCore1 : calenderCore(),
        uniqueId: uuidv4(),
        t: useI18n()
    
      }
    },
   
    computed:{

        items(){
              return this.$store.state.calender.items;
          },
        events(){
              return this.$store.state.events.items;
          }
        },
  
        async created(){
          this.$store.dispatch("getCalender");
          this.$store.dispatch("getTimezones");
          this.$store.dispatch("getPublicHolidays"); 
          this.$store.dispatch("getShoolholidays"); 
          this.$store.dispatch("getContent"); 
            
          const calenderCore1 = calenderCore();
          calenderCore1.updateCalender();
  
          const store = useStore();
          const isAuthenticated = computed(() => store.getters["user/isAuthenticated"]);
          
  
          //--------------------------------------------------------------------------------------------//
          //-------------------------------- If is authenticated ---------------------------------------//
          //--------------------------------------------------------------------------------------------//
          
          if(isAuthenticated.value === true){
            this.$store.state.settings.Authenticated = true
            if(this.$store.state.settings.fetchedDataFromDb == false){
              await this.$store.dispatch("getmyCalendarsFromDB");
              await this.$store.dispatch("getmyEventsFromDB",this.$store.state.user.userID);
              await this.$store.dispatch("refreshIndexList");
              await this.$store.dispatch("getavailableCalendarsFromDB", this.$store.state.user.userID);
              await store.dispatch('getPublicHolidaysFromApi', { userId: this.$store.state.user.userID, localCalendars: this.$store.state.storedmycalendars.items, language: this.$i18n.locale });
              this.$store.state.mycalendars.items = this.$store.state.storedmycalendars.items
              this.$store.state.availablecalendars.items = this.$store.state.storedavailablecalendars.items
              this.$store.state.settings.fetchedDataFromDb = true;
              this.$store.state.events.items = this.$store.state.storedevents.items
              await this.$store.dispatch("getmydaysWithEventsFromDB", {
                    events: this.$store.state.events.items, 
                    myCalendars: this.$store.state.mycalendars.items, 
                    calender: this.$store.state.calender.items});
              calenderCore1.updateCalender();
              this.$store.state.settings.startPageOpen = false; 
            }
          }
      
          //--------------------------------------------------------------------------------------------//
          //-------------------------------- If is NOT authenticated -----------------------------------//
          //--------------------------------------------------------------------------------------------//
  
          else{
            await this.$store.dispatch("initEventItems");
            this.$store.state.events.items = this.$store.state.storedevents.items
            this.addFirstCalendarIfNotSignedIn()
            calenderCore1.updateCalender();
            this.$store.state.settings.startPageOpen = false; 
          }
  
        },
  
         async mounted() {
          window.addEventListener('resize', this.getDimensions);
          document.addEventListener( "keydown", this.onKeydown );
          document.addEventListener( "keyup", this.onKeyup );
        },
  
        unmounted() {
          window.removeEventListener('resize', this.getDimensions);
        },
  
        methods: {
  
          addFirstCalendarIfNotSignedIn(){
            const id = uuidv4()
            let newCalendar = {
              color: '#6AD069', //calendarColor.value, 
              icon: 'ph-CalendarCheck', //icon.value,
              name: 'Privat',
              externalCalendarLink: '',//externalCalendarLink.value,
              description: '',
              visible: true,
              private: true,
              creatorUID: 'notSignedIn',
              creatorName: "Annucal",
              calendarImage:  "",
              type: '1',
              specialday:'',
              country: '',
              subdivision: '',
              subdivisionCode: '',
              loadedPublicHolidays: false,
              calendarId:id,
              docId:id,
              events:[]
          }
    
          this.$store.state.mycalendars.items.push(newCalendar)
          
          this.calenderCore1.updateCalender();
  
          },
          
  
          getDimensions() {
            this.$store.dispatch("getCalender"); 
          },
  
          onKeyup (e) {
            if(e.key == 'd'){
            this.$store.state.settings.ctrlPressed = false;
            this.$store.state.settings.selectedCursor = 'default'
            
          }
          },
  
          onKeydown  (e) {
          if(e.key == 'd'){
            this.$store.state.settings.ctrlPressed = true;
            this.$store.state.settings.selectedCursor = 'grab'
            
          }
        }      
      }
    
};
</script>

<style scoped>

.cell-svg {
  position: absolute;
  right: 4px; /* Rechtsbündig in der Zelle */
  top: 50%;
  transform: translateY(-50%); /* Vertikal zentriert */
  z-index: 1;
}

/* Hauptcontainer, der den gesamten Bildschirm abdeckt */
.app-container {
  display: grid;
  grid-template-rows: 36px 1fr; /* Navbar nimmt mindestens 50px oder bis zu 5% der Höhe ein */
  height: 100vh;
  overflow: hidden;
  width: 100%;
  background-color: #E7E5DF;
}

/* Navbar */
.navbar {
  display: grid;
  grid-template-columns: 50px 1fr; /* Sidebar nimmt mindestens 50px oder bis zu 5% der Breite ein */
  background-color: #E7E5DF;
  color: white;
  font-size: min(calc(1vh + 1vw), 1.5rem); /* Dynamische Schriftgröße für die Navbar mit einer Mindestgröße */
  height: 100%; /* Passt sich an die Höhe von 5% an */
  
}

/* Hauptbereich mit zwei Spalten */
.main-grid {
  display: grid;
  grid-template-columns: 70px 1fr; /* Sidebar nimmt mindestens 50px oder bis zu 5% der Breite ein */
  /*  height: calc(100vh - min(100px, 5%));Verbleibende Höhe nach der Navbar */
  overflow: hidden;
  padding-bottom: 12px;  
  
}

/* Linke Spalte mit 5% Breite */
.sidebar {
  overflow-y: auto;
  background-color: #E7E5DF;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 23px;
}


/* Scrollbarer Bereich für das gesamte Grid */
.scrollable-grid {
  overflow-x: auto;
  background-color: #E7E5DF;
  height: 100%; /* Füllt die verfügbare Höhe aus */
}

/* Grid-Struktur mit 32 Zeilen und 24 Spalten */
.grid1 {
  display: grid;
  grid-template-columns: repeat(24, minmax(1vw, 1fr)); /* 24 Spalten */
  /*height: calc(100vh - min(50px, 5%));  Verbleibende Höhe nach der Navbar */
  height: 100vh;
  height: 100%; /* Füllt den vertikalen Raum vollständig aus */
  width: 200%; /* Macht 12 Spalten sichtbar, der Rest scrollt */

}

/* Grid-Struktur mit 32 Zeilen und 24 Spalten */
.grid {
  display: grid;
  grid-template-columns: repeat(24, minmax(1vw, 1fr)); /* 24 Spalten */
  /*height: calc(100vh - min(50px, 5%));  Verbleibende Höhe nach der Navbar */
  height: 100vh;
  height: 100%; /* Füllt den vertikalen Raum vollständig aus */
  width: 200%; /* Macht 12 Spalten sichtbar, der Rest scrollt */

}

/* Monat-Zelle für die erste Zeile */
.month-cell {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 4px;
  background-color: #e0e0e0;
  font-weight: bold;
  font-size: min(calc(0.9vh + 0.5vw), 1.2rem); /* Dynamische Schriftgröße für die Monatszeile */
}

/* Stil für den Monatstext */
.month-text {
  font-size: 1em;
}

/* Stil für das Jahr 2024, kleinere Schrift */
.year-text {
  font-size: 0.8em;
  margin-left: 4px;
  color: #666;
}

/* Einheitliche Zellen */
.grid-cell {
  
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  font-size: min(calc(0.8vh + 0.6vw), 1rem); /* Dynamische Schriftgröße für nummerierte Zellen mit Mindestgröße */
}

/* Inneres Grid in jeder nummerierten Zelle */
.inner-grid {
  display: grid;
  grid-template-columns: 1fr 3fr; /* 2 Spalten, wobei die zweite dreimal so breit ist wie die erste */
  width: 100%;
  height: 100%;
  gap: 4px;
}

/* Zellen des inneren Grids */
.inner-cell {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d9d9d9;
  /*font-size: min(calc(0.6vh + 0.5vw), 0.7rem); *//* Kleinere dynamische Schriftgröße für inneres Grid mit Mindestgröße */
  border: 1px solid #bbb;
}

/* Bei Bildschirmen unter 800px: feste Breite der Spalten */
@media (max-width: 1240px) {
  .grid {
    grid-template-columns: repeat(24, minmax(100px, 1fr)); /* Feste Breite von 80px pro Spalte */
    width: auto; /* Passt die Breite an die Spaltenanzahl an */
  }
}

/* Bei Bildschirmen unter 750px: feste Höhe der Zeilen */
@media (max-height: 750px) {
  .grid {
    grid-template-rows: repeat(32, 5vw); /* Feste Höhe von 24px pro Zeile */
    height: auto; /* Ermöglicht vertikales Scrollen bei kleinerer Höhe */
  }
  .grid-cell,
  .month-cell,
  .inner-cell {
    font-size: 0.6rem; /* Kleinere, feste Schriftgröße bei geringer Höhe */
  }
}

.layer-container{
  /*background-color: var(--calendar-background-color); */
  background-color: white;
  z-index: 2;
  width: 40px;
  border-radius: 6px;
}
</style>

















