export default {
  "Januar": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Januar"])};fn.source="Januar";return fn;})(),
  "Februar": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Februar"])};fn.source="Februar";return fn;})(),
  "März": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["März"])};fn.source="März";return fn;})(),
  "April": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["April"])};fn.source="April";return fn;})(),
  "Mai": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mai"])};fn.source="Mai";return fn;})(),
  "Juni": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juni"])};fn.source="Juni";return fn;})(),
  "Juli": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juli"])};fn.source="Juli";return fn;})(),
  "August": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["August"])};fn.source="August";return fn;})(),
  "September": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["September"])};fn.source="September";return fn;})(),
  "Oktober": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oktober"])};fn.source="Oktober";return fn;})(),
  "November": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["November"])};fn.source="November";return fn;})(),
  "Dezember": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dezember"])};fn.source="Dezember";return fn;})(),
  "Monday": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montag"])};fn.source="Montag";return fn;})(),
  "Tuesday": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienstag"])};fn.source="Dienstag";return fn;})(),
  "Wednesday": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mittwoch"])};fn.source="Mittwoch";return fn;})(),
  "Thursday": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donnerstag"])};fn.source="Donnerstag";return fn;})(),
  "Friday": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freitag"])};fn.source="Freitag";return fn;})(),
  "Saturday": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samstag"])};fn.source="Samstag";return fn;})(),
  "Sunday": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonntag"])};fn.source="Sonntag";return fn;})(),
  "Montag": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montag"])};fn.source="Montag";return fn;})(),
  "Dienstag": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienstag"])};fn.source="Dienstag";return fn;})(),
  "Mittwoch": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mittwoch"])};fn.source="Mittwoch";return fn;})(),
  "Donnerstag": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donnerstag"])};fn.source="Donnerstag";return fn;})(),
  "Freitag": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freitag"])};fn.source="Freitag";return fn;})(),
  "Samstag": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samstag"])};fn.source="Samstag";return fn;})(),
  "Sonntag": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonntag"])};fn.source="Sonntag";return fn;})(),
  "Public holidays": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feiertage"])};fn.source="Feiertage";return fn;})(),
  "Calendar weeks": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalenderwochen"])};fn.source="Kalenderwochen";return fn;})(),
  "Log in": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])};fn.source="Anmelden";return fn;})(),
  "No account yet": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noch kein Konto"])};fn.source="Noch kein Konto";return fn;})(),
  "Repeat password": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort wiederholen"])};fn.source="Passwort wiederholen";return fn;})(),
  "Forgot password": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort vergessen"])};fn.source="Passwort vergessen";return fn;})(),
  "Register now": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jetzt registrieren"])};fn.source="Jetzt registrieren";return fn;})(),
  "Reset now": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jetzt zurücksetzen"])};fn.source="Jetzt zurücksetzen";return fn;})(),
  "Register": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrieren"])};fn.source="Registrieren";return fn;})(),
  "Already registered?": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schon registriert?"])};fn.source="Schon registriert?";return fn;})(),
  "Log in now": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jetzt anmelden"])};fn.source="Jetzt anmelden";return fn;})(),
  "close": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen"])};fn.source="Schließen";return fn;})(),
  "newCalendar": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuer Kalender"])};fn.source="Neuer Kalender";return fn;})(),
  "description": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])};fn.source="Beschreibung";return fn;})(),
  "calendarColor": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalenderfarbe"])};fn.source="Kalenderfarbe";return fn;})(),
  "subscribetoexternalcalendar": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Externen Kalender abonnieren"])};fn.source="Externen Kalender abonnieren";return fn;})(),
  "private": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["privat"])};fn.source="privat";return fn;})(),
  "public": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["öffentlich"])};fn.source="öffentlich";return fn;})(),
  "howtosubscribe1": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie kann ich meine externen Kalender abonnieren?"])};fn.source="Wie kann ich meine externen Kalender abonnieren?";return fn;})(),
  "howtosubscribe2": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um einen Deiner Kalender zu abonnieren kannst Du hier den ICal Link Deines Kalenders eintragen."])};fn.source="Um einen Deiner Kalender zu abonnieren kannst Du hier den ICal Link Deines Kalenders eintragen.";return fn;})(),
  "howtosubscribe3": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was ist ein Ical Link? (Link zur Erklärung?)"])};fn.source="Was ist ein Ical Link? (Link zur Erklärung?)";return fn;})(),
  "howtosubscribe4": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wo finde ich den ICal Link zu meinem Google Kalender? (Link zu Youtube Anleitung?)"])};fn.source="Wo finde ich den ICal Link zu meinem Google Kalender? (Link zu Youtube Anleitung?)";return fn;})(),
  "howtosubscribe5": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wo finde ich den ICal Link zu meinem Apple Kalender? (Link zu Youtube Anleitung?)"])};fn.source="Wo finde ich den ICal Link zu meinem Apple Kalender? (Link zu Youtube Anleitung?)";return fn;})(),
  "cancel": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])};fn.source="Abbrechen";return fn;})(),
  "save": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])};fn.source="Speichern";return fn;})(),
  "unsubscribe": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deabonnieren"])};fn.source="Deabonnieren";return fn;})(),
  "newevent": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Ereignis"])};fn.source="Neues Ereignis";return fn;})(),
  "additionalinformation": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusätzliche Informationen"])};fn.source="Zusätzliche Informationen";return fn;})(),
  "repetitions": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiederholungen"])};fn.source="Wiederholungen";return fn;})(),
  "once": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einmalig"])};fn.source="Einmalig";return fn;})(),
  "daily": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Täglich"])};fn.source="Täglich";return fn;})(),
  "weekly": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wöchentlich"])};fn.source="Wöchentlich";return fn;})(),
  "monthly": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monatlich"])};fn.source="Monatlich";return fn;})(),
  "ownselection": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigene Auswahl"])};fn.source="Eigene Auswahl";return fn;})(),
  "allday": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ganztägig"])};fn.source="Ganztägig";return fn;})(),
  "from": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["von"])};fn.source="von";return fn;})(),
  "until": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bis"])};fn.source="bis";return fn;})(),
  "availableCalendarsSearch": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfügbare Kalender suchen"])};fn.source="Verfügbare Kalender suchen";return fn;})(),
  "language": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache"])};fn.source="Sprache";return fn;})(),
  "currentCalendar": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktueller Kalender"])};fn.source="Aktueller Kalender";return fn;})(),
  "timeframe": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitraum"])};fn.source="Zeitraum";return fn;})(),
  "repeatevery": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiederholen alle"])};fn.source="Wiederholen alle";return fn;})(),
  "end": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ende"])};fn.source="Ende";return fn;})(),
  "repeat": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiederholen am"])};fn.source="Wiederholen am";return fn;})(),
  "savecalendar": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalender speichern"])};fn.source="Kalender speichern";return fn;})(),
  "closewithx": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen X"])};fn.source="Schließen X";return fn;})(),
  "logouttext1": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du bist aktuell angemeldet als "])};fn.source="Du bist aktuell angemeldet als ";return fn;})(),
  "logouttext2": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchtest Du Dich jetzt abmelden?"])};fn.source="Möchtest Du Dich jetzt abmelden?";return fn;})(),
  "logout": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abmelden"])};fn.source="Abmelden";return fn;})(),
  "menu": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menü"])};fn.source="Menü";return fn;})(),
  "login": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])};fn.source="Anmelden";return fn;})(),
  "settings": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen"])};fn.source="Einstellungen";return fn;})(),
  "addcalendar": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalender hinzufügen"])};fn.source="Kalender hinzufügen";return fn;})(),
  "subscribecalendar": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalender abonnieren"])};fn.source="Kalender abonnieren";return fn;})(),
  "publiccalendar": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffentliche Kalender"])};fn.source="Öffentliche Kalender";return fn;})(),
  "Mo": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mo"])};fn.source="Mo";return fn;})(),
  "Di": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Di"])};fn.source="Di";return fn;})(),
  "Mi": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi"])};fn.source="Mi";return fn;})(),
  "Do": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do"])};fn.source="Do";return fn;})(),
  "Fr": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fr"])};fn.source="Fr";return fn;})(),
  "Sa": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sa"])};fn.source="Sa";return fn;})(),
  "So": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["So"])};fn.source="So";return fn;})(),
  "shoolandpublicholidays": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feiertage - Schulferien"])};fn.source="Feiertage - Schulferien";return fn;})(),
  "publicholidays": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feiertage"])};fn.source="Feiertage";return fn;})(),
  "schoolholidays": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schulferien"])};fn.source="Schulferien";return fn;})(),
  "entercountry": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land auswählen"])};fn.source="Land auswählen";return fn;})(),
  "createcalendar": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalender erstellen"])};fn.source="Kalender erstellen";return fn;})(),
  "calendarname": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalender Name"])};fn.source="Kalender Name";return fn;})(),
  "editcalendar": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalender bearbeiten"])};fn.source="Kalender bearbeiten";return fn;})(),
  "novalidemailaddress": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine gültige Emailadresse"])};fn.source="Keine gültige Emailadresse";return fn;})(),
  "emailaddressrequired": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email-Adresse erforderlich"])};fn.source="Email-Adresse erforderlich";return fn;})(),
  "passwordrequired": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Password erforderlich"])};fn.source=" Password erforderlich";return fn;})(),
  "password": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])};fn.source="Passwort";return fn;})(),
  "passworddonotmatch": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Passwörter stimmen nicht überein"])};fn.source="Die Passwörter stimmen nicht überein";return fn;})(),
  "view": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ansicht"])};fn.source="Ansicht";return fn;})(),
  "account": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto"])};fn.source="Konto";return fn;})(),
  "selectlanguage": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache auswählen"])};fn.source="Sprache auswählen";return fn;})(),
  "savechanges": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderungen speichern"])};fn.source="Änderungen speichern";return fn;})(),
  "signedinas": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angemeldet als"])};fn.source="Angemeldet als";return fn;})(),
  "notsignedin": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht angemeldet"])};fn.source="Nicht angemeldet";return fn;})(),
  "showcalendarweek": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalenderwochen anzeigen"])};fn.source="Kalenderwochen anzeigen";return fn;})(),
  "commingsoon": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir freuen uns, dass Ihr Teil der Annucal-Community seid! Unser Ziel ist es den bestmöglichen Jahresplaner zu bieten und wir arbeiten ständig daran das Benutzerlebnis weiter zu verbessern. In den kommenden Monaten werden wir viele spannende neue Funktionen hinzufügen, die die Bedienung noch einfacher und effizienter machen. Annucal entwickelt sich stetig weiter und diese Funktion wird bald zur Verfügung stehen!"])};fn.source="Wir freuen uns, dass Ihr Teil der Annucal-Community seid! Unser Ziel ist es den bestmöglichen Jahresplaner zu bieten und wir arbeiten ständig daran das Benutzerlebnis weiter zu verbessern. In den kommenden Monaten werden wir viele spannende neue Funktionen hinzufügen, die die Bedienung noch einfacher und effizienter machen. Annucal entwickelt sich stetig weiter und diese Funktion wird bald zur Verfügung stehen!";return fn;})(),
  "yourteam": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Euer AnnuCal-Team"])};fn.source="Euer AnnuCal-Team";return fn;})(),
  "dearusers": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liebe Annucal. Nutzer"])};fn.source="Liebe Annucal. Nutzer";return fn;})(),
  "enterbundesland": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bundesland auswählen"])};fn.source="Bundesland auswählen";return fn;})(),
  "enterkanton": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanton auswählen"])};fn.source="Kanton auswählen";return fn;})(),
  "enterregion": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region auswählen"])};fn.source="Region auswählen";return fn;})(),
  "enterstate": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bundesstaat auswählen"])};fn.source="Bundesstaat auswählen";return fn;})(),
  "selectedlanguage": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DE"])};fn.source="DE";return fn;})(),
  "searchcalendar": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalender suchen"])};fn.source="Kalender suchen";return fn;})(),
  "showtoday": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuellen Tag anzeigen"])};fn.source="Aktuellen Tag anzeigen";return fn;})(),
  "calendar": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalender"])};fn.source="Kalender";return fn;})(),
  "eventname": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name des Events"])};fn.source="Name des Events";return fn;})(),
  "delete": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])};fn.source="Löschen";return fn;})()
}