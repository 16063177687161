<template>
    <v-card 
      style="
        border-radius: 8px;
        margin-top:-25px;
        margin-left: 34px;
        box-shadow: 0 0 32px rgba(0, 0, 0, 0.2);
        padding: 8px;

      "
    >
    <div style="  overflow: hidden;">
      <!---------------------------------------------------------------------------------------->
      <!-------------------------------------- Add calendar ------------------------------------>
      <!---------------------------------------------------------------------------------------->
    
      <div      
        @click="createNewCalendar"
        class="row1"
      >
        <!-------------------------------- Left Icon ---------------------------------------->

        <div v-if="showLeftIcon" class="left-icon-back">
            <PhCalendarPlus class="left-icon"/>
        </div>

        <!---------------------------------- Text -------------------------------------------->

        <div class="center-column">
            {{addcalendar}}
        </div>

        <!-------------------------------- Right Icon ---------------------------------------->

        <div v-if="showRightIcon" class="right-icon-back">
            <div class="right-icon-container">
            <PhPlus class="right-icon" />
            </div>
        </div>
    
      </div>  
          

      <!---------------------------------------------------------------------------------------->
      <!----------------------------------- Subscribe calendar --------------------------------->
      <!---------------------------------------------------------------------------------------->
        
          <v-dialog max-width="600">

                <template v-slot:activator="{ props: activatorProps }">
                  <div   
                    v-bind="activatorProps" 
                    @click="openSettingsDialog"
                    class="row2"
                     >
                    <div v-if="showLeftIcon" class="left-icon-back">
                        <PhCalendarDots class="left-icon"/>
                    </div>
                    <div class="center-column">
                        {{subscribecalendar}}
                    </div>
                    <div v-if="showRightIcon" class="right-icon-back">
                        <div class="right-icon-container">
                        <PhPlus class="right-icon" />
                        </div>
                    </div>
               
                      </div>

                </template>

              <!---------------------------------------------------------------------------------------->
            <!-------------------------------- Comming Soon message ---------------------------------->
            <!---------------------------------------------------------------------------------------->

            <template v-slot:default="{ isActive }">
              <AnnucalVCard
                :first_column_width='0'
                :calendarColor = props.calendarColor
                :showCalendarName = true
                :showvCardActions = false
                :VcardWitdh = '490'
                :calendarName=subscribecalendar
                @close-menu="isActive.value = false"
              >
                <template v-slot:middle>
                  <div 
                    style="padding-top: 20px;"
                    class="text-style">{{commingsoonText}}</div>
                              <br>
                              <div class="text-style" style="font-weight: 500">{{ yourteamText }}</div>
                </template>
              </AnnucalVCard>   
            </template>
          </v-dialog>

<!---------------------------------------------------------------------------------------->
      <!-----------------------------------  Public calendar --------------------------------->
      <!---------------------------------------------------------------------------------------->
        
          <v-dialog max-width="600">

                <template v-slot:activator="{ props: activatorProps }">
                  <div   
                    v-bind="activatorProps" 
                    @click="openSettingsDialog"
                    class="row2"
                     >
                    <div v-if="showLeftIcon" class="left-icon-back">
                        <PhCalendarStar class="left-icon"/>
                    </div>
                    <div class="center-column">
                        {{searchcalendar}}
                    </div>
                    <div v-if="showRightIcon" class="right-icon-back">
                        <div class="right-icon-container">
                        <PhPlus class="right-icon" />
                        </div>
                    </div>
               
                      </div>

                </template>

              <!---------------------------------------------------------------------------------------->
            <!-------------------------------- Comming Soon message ---------------------------------->
            <!---------------------------------------------------------------------------------------->

            <template v-slot:default="{ isActive }">
              <AnnucalVCard
                :first_column_width='0'
                :calendarColor = props.calendarColor
                :showCalendarName = true
                :showvCardActions = false
                :VcardWitdh = '490'
                :calendarName=subscribecalendar
                @close-menu="isActive.value = false"
              >
                <template v-slot:middle>
                  <div 
                    style="padding-top: 20px;"
                    class="text-style">{{commingsoonText}}</div>
                              <br>
                              <div class="text-style" style="font-weight: 500">{{ yourteamText }}</div>
                </template>
              </AnnucalVCard>   
            </template>
          </v-dialog>
           

           
         
        </div>
    </v-card>
</template>

<script setup>
import { ref, defineProps, computed, onMounted, defineEmits } from 'vue';


import {useStore} from "vuex";
import { useI18n } from 'vue-i18n'
import { v4 as uuidv4 } from 'uuid';
import calenderCore from "../composition/calenderCore";
import AnnucalVCard from "./AnnucalVcard.vue"

const calenderCore1 = calenderCore();

const isAuthenticated = computed(() => store.getters["user/isAuthenticated"]);


const { t } = useI18n()

const showLeftIcon = ref(true);
const showRightIcon = ref(true);


const commingsoonText = t('commingsoon')
const yourteamText = t('yourteam')
const searchcalendar = t('searchcalendar')
const subscribecalendar = t('subscribecalendar')
const addcalendar = t('addcalendar')




const store = useStore();
    const { state } = store;

// Define props
const props = defineProps({
  id: {
    type: String,
    required: true
  },
  calendarColor: {
    type: String,
    default: '#DAFDD8' // Default value if not provided
  }
});

onMounted(() => {
  store.state.settings.Log_Status = ""
  store.state.settings.AnnucalSaveText = t("savecalendar")
  store.state.settings.AnnucalVcardHeadline = t("createcalendar")
  store.state.settings.normlaErrorMessage = ""
});

const mycalendars = computed({
    get() {
      return state.mycalendars.items;
    },
})

const emits = defineEmits(['closeCalendarMenu'])

async function createNewCalendar (){
  
  store.state.settings.savingModus = true

  let newCalendar = {
      color: getCalendarColor(), //calendarColor.value, 
      icon: 'ph-CalendarCheck', //icon.value,
      name: t('calendar'),
      externalCalendarLink: '',//externalCalendarLink.value,
      description: '',
      visible: true,
      private: true,
      creatorUID: store.state.user.userID == null ? 'notsignedin' :  store.state.user.userID ,
      creatorName: "Annucal",
      calendarImage:  "",
      type: '1',
      specialday:'',
      country: '',
      subdivision: '',
      subdivisionCode: '',
      loadedPublicHolidays: false,
      events:[]
  }

    if(isAuthenticated.value == true){
        const newCalendarData = await store.dispatch("createCalendar", newCalendar);
        newCalendar.icon = newCalendarData.imageURL
        newCalendar.calendarId = newCalendarData.calendarId
        newCalendar.docId = newCalendarData.MyCalendarsdocId
        store.state.mycalendars.items.push(newCalendar)
    }else{
        const cId = uuidv4()
        newCalendar.calendarId = cId
        newCalendar.docId = cId
        store.state.mycalendars.items.push(newCalendar)
    }

    calenderCore1.updateCalender();

    emits('closeCalendarMenu')
}


const availableColors = ref(['#6AD069','#4BA0C4','#5C70D7','#9D41D5','#D272DA'])

function getCalendarColor(){
  for(let c = 0; c < availableColors.value.length; ++c){
    var hasColor = false
    for (let i = 0; i < mycalendars.value.length; ++i){
      if(mycalendars.value[i].color === availableColors.value[c]){
        hasColor = true
      }
    }
    if(hasColor === false){
      return availableColors.value[c]
    }
  }
  return '#0000ff'
}


</script>

  
  <style scoped>

.text-style{
    font-size: 14px;
    font-weight: 300;
    color:var(--standard-text-color);
    letter-spacing: -0.6px;
}

  .grid-container {
    display: grid;
    grid-template-columns: 130px auto;
    background-color: #ffffff;
    padding: 16px; /* Gleichmäßiges Padding */
  }

  .grid-item-links-active {
    font-size: 14px;
    text-align: left;
    font-weight: 300;
    letter-spacing: -0.6px;
    user-select: none;
  }
  
  .grid-item-links-inactive {
    font-size: 14px;
    text-align: left;
    font-weight: 300;
    letter-spacing: -0.6px;
    color: #85827C;
    user-select: none;
  }
  
  .grid-item-rechts {}
  
  .plus-button-active {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18px; /* Gleiche Größe wie Checkbox */
    height: 18px; /* Gleiche Größe wie Checkbox */
    background-color: #e7e5df;
    border: 1px solid #e7e5df;
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
    margin-top: 2px;
    cursor: pointer;
  }

  .plus-button-inactive {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18px; /* Gleiche Größe wie Checkbox */
    height: 18px; /* Gleiche Größe wie Checkbox */
    background-color: #f1f1f1;
    border: 1px solid #f1f1f1;
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
    margin-top: 2px;
    
  }
  
  .plus-icon {
    color: #191816;
    font-size: 14px;
    font-weight: bold;
    transition: color 0.3s ease;
  }
  
  .plus-button-active:hover {
    background-color: #191816;
  }
  
  .plus-button-active:hover .plus-icon {
    color: white;
  }


.row1 {
  display: grid; /* Jede Row bleibt ein Grid */
  grid-template-columns: 30px auto 30px; /* Spaltenaufteilung */
  align-items: center; /* Zentrierung vertikal */
  margin-bottom: 2px;
  padding:8px;
  background-color: white; /* Standardhintergrundfarbe */
  border-radius: 4px; /* Optional: Abgerundete Ecken */
}

.row2 {
  display: grid; /* Jede Row bleibt ein Grid */
  grid-template-columns: 30px auto 30px; /* Spaltenaufteilung */
  align-items: center; /* Zentrierung vertikal */
  background-color: white; /* Standardhintergrundfarbe */
  padding:8px;
  border-radius: 4px; /* Optional: Abgerundete Ecken */
}

.row1:hover,
.row2:hover {
  cursor: pointer; /* Zeigt den Zeiger an */
  background-color: #e6e5e55a; /* Hover-Hintergrundfarbe */
}
  
  .left-icon {
    width: 16px;
    height: 16px;
  }
  
  .left-icon-back {
    
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .right-icon-back {
    display: flex;
    justify-self: end; /* Horizontal nach rechts ausgerichtet */
    align-items: center;
  }
  
  .right-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #E7E5DF;
    border-radius: 4px; /* Runde Form */
    width: 16px; /* Größe des grauen Quadrats */
    height: 16px;
    margin-right: 4px;
  }
  
  .right-icon {
    width: 12.8px;
    height: 12.8px;
  }
  
  .center-column {
    text-align: left;
    font-size: 12px;
    font-weight: 400;
    color: var(--standard-text-color);
    letter-spacing: -0.6px;
    
  }
  </style>
  