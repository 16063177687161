<template>
  <div :class="this.$store.state.settings.currentTheme + '-theme'">

    <!-------------------------------------------------------------------------------------------->
    <!-------------------------------------- Logo / Year ----------------------------------------->
    <!-------------------------------------------------------------------------------------------->
    <div class="navbar-grid" >
      <div >
        <!--<button @click="toggleLightMode">Toggle Theme - {{ currentTheme }}</button>-->
        <div class="annucal-h4 text-headline-left">Annucal.</div>
        <!--<PhCaretDown class="icon-class" />-->
      </div>

      <!-------------------------------------------------------------------------------------------->
      <!----------------------------------------- Search ------------------------------------------->
      <!-------------------------------------------------------------------------------------------->
     <!-- <div class="search-grid">
        <PhMagnifyingGlass class="icon-class" />
        <div class="annucal-h4 text-headline-search">Kalender suchen</div> 
      </div>-->
      <div></div>

      <!-------------------------------------------------------------------------------------------->
      <!------------------------------------------ Menu -------------------------------------------->
      <!-------------------------------------------------------------------------------------------->
      <div class="menue-grid">
        <div class="annucal-h4 text-headline-menu">{{$t('menu')}}</div> 
        <v-menu v-model="menu" :close-on-content-click="false" :openOnHover="true"  location="end">
          <template v-slot:activator="{ props }">
            <div class="menue-grid" v-bind="props" @click="openMenu">
              <PhList class="icon-class" />
              </div>
          </template>
          <AnnucalMenu
            @close-menu="menu = false"
            :creatorUID=this.$store.state.user.userID
          />
        </v-menu>
      </div>
    </div>
</div>

</template>

<script setup>

import { ref } from 'vue';
import { useStore } from 'vuex';
import AnnucalMenu from "./AnnucalMenu.vue"


//import calenderCore from "../composition/calenderCore";
//import useAuth from '../composition/useAuth';

function openMenu(){
    menu.value = true
    state.settings.AnnucalVcardHeadline = 'menu'
}

//const calenderCore1 = calenderCore();

// Setup Composition API
const store = useStore();
const { state } = store;

const menu = ref(false);

// Computed properties
//const screenWidth = computed(() => state.settings.screenWidth);
//const currentTheme = computed(() => state.settings.currentTheme);
//const availableCalendarItems = computed(() => state.availablecalendars.items);

// Methods
/*const toggleLightMode = () => {
  store.state.settings.currentTheme =
    store.state.settings.currentTheme === 'light' ? 'dark' : 'light';
    calenderCore1.updateCalender();
};*/



</script>



  
<style  scoped>

.text-headline-left{
  padding-left:19px;
  display: flex;
  align-items: center;
  justify-content: left;
  color:var(--text-color);
  user-select: none; 
  font-size: 22px; /* Dynamische Schriftgröße für die Navbar mit einer Mindestgröße */
  font-weight: 800;
  letter-spacing: -0.6px;
}

.text-headline-search{
  display: flex;
  align-items: center;
  justify-content: center;
  color:var(--text-color);
  padding-left: 4px;
  user-select: none; 
}

.text-headline-menu{
  display: flex;
  align-items: center;
  justify-content: right;
  color:var(--text-color);
  user-select: none; 
  padding-right: 6px;
  font-size: 22px; /* Dynamische Schriftgröße für die Navbar mit einer Mindestgröße */
  font-weight: 800;
  letter-spacing: -0.6px;
}

.navbar-grid {
    display: grid;
    width:100%;
    grid-template-columns: 30% 40% 30%;
    background-color: var(--calendar-background-color);
}

.searchbox{
  display: flex;
  align-items: center;
  justify-content: center;
}

.menue-grid {
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    justify-content: right;
    padding-right:2px;
}

.search-grid {
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    justify-content: center;
}

.logo-grid {
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    justify-content: left;
}

.icon-class{
  font-size: 25px;
  padding-top:2px;
  transition: transform .2s; /* Animation */
  color:black
}

.icon-class:hover{
  color:#aaaaaa;
  cursor: pointer;
}

.text-h1
{
  color: #345434;
  font-size: 12px; 
  font-weight: 800
}

  
  .grid-item-2-3 {
              grid-column: 2 / 3;
              grid-row: 3 / 4;
              background-color: #E7E5DF;
          }
  
  .grid-container-4px {
    display: grid;
    grid-template-columns: 24px auto 24px;
    grid-template-rows: 24px 32px 80px 32px 24px 32px 56px 4px 20px 32px 48px 32px 56px;
    gap: 1px;
    background-color: #ff0000;
    padding: 0px;
   
  }
  
  .grid-container-4px > div {
    background-color: rgba(0, 0, 0, 0.8);
    text-align: center;
    padding: 0px 0;
  
  
  }
  
  .item1-4px {
    grid-row-start: 1;
    grid-row-end: 3;
    border: 1px solid rgb(51, 0, 255);
  }
  

  
  .settings{
    padding-bottom: 0px;
  }
  
  .vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  
  .gridyear{
    background-color: #E7E5DF;
    height: 100%;
    font-size: xx-large;
    font-weight: 300;
    color:rgb(88, 88, 88);
    display: flex;
    align-items:center;
    justify-content:center;
    -webkit-user-select: none;  /* Chrome all / Safari all */
    -moz-user-select: none;     /* Firefox all */
    -ms-user-select: none;      /* IE 10+ */
    user-select: none; 
  
    
  } 
  

  
  .menuitem{
    background-color: aqua;
    display: table-cell;
    width: 50px;
    padding: 10px;
    vertical-align: middle;
    -webkit-user-select: none;  /* Chrome all / Safari all */
    -moz-user-select: none;     /* Firefox all */
    -ms-user-select: none;      /* IE 10+ */
    user-select: none; 
    
  }
  
  @media (min-width: 100px){
    .item1 {
      background-color: rgb(154, 154, 154);
      display: table-cell;
      width: 95px;
      padding-left: 30px;
      padding-top: 16px;
      vertical-align: middle;
    } 
  
    .logo{
      width:145px;
      padding-left:45px;
      padding-top:3px;
    }
  
    .topyear{
      margin-top: 5px;
      margin-right: 30px;
    }

  
  }
  
  @media (min-width: 700px){
    .item1 {
      background-color: rgb(154, 154, 154);
      display: table-cell;
      width: 260px;
      padding-left: 40px;
      padding-top: 20px;
      vertical-align: middle;
    } 
    .logo{
      width:160px;
      padding-left:45px;
  
    }
    .topyear{
      margin-top: 6px;
      margin-right: 30px;
      font-weight: 900;
      font-size: larger;
    }
  
    .item2 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    background-color: rgb(255, 255, 255);
    justify-items: right;
    
    padding-right: 0px;
    display: absolut;
    align-items:center;
    justify-content:right;
  }
  
  
  
  }
  
  @media (min-width: 1200px){
    .item1 {
      background-color: rgb(154, 154, 154);
      display: table-cell;
      width: 33%;
      padding-left: 40px;
      padding-top: 18px;
      vertical-align: middle;
      margin-top:0px;
    }
  
    .logo{
      width:170px;
      padding-left:45px;
       display: flex;
    align-items:center;
    justify-content:left;
     
    }
  
    .topyear{
      margin-top: 25px;
      margin-right: 28px;
      font-weight: 900;
      font-size: xx-large;
      color: rgb(93, 93, 93);
       display: flex;
    align-items:center;
    justify-content:center;
    }
  
  
    .item2 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    background-color: #E7E5DF;
    justify-items: right;
    margin-top: -5px;
    
    display: flex;
    align-items:center;
    justify-content:right;
    }
  }
  
  
  
  </style>
  


  
