
<template>
  <div :class="this.$store.state.settings.currentTheme + '-theme'">

    <!-------------------------------------------------------------------------------------------->
    <!---------------------------  Show months in the first row ---------------------------------->
    <!-------------------------------------------------------------------------------------------->
    
    <div  class="month-year" v-if="item.name == 1">
        <div class="month-year-left">
          {{ $t( item.month)}}          
        </div>
        <div class="month-year-right">
          '{{item.id.substring(2, 4)}}
        </div>
    </div>
       
    <!-------------------------------------------------------------------------------------------->
    <!----------------------------------  Left Box / Day ----------------------------------------->
    <!-------------------------------------------------------------------------------------------->
    
    <div class="container" >

      <svg v-if="item.kw != 'empty.svg' && this.$store.state.settings.showKalenderwochen === true"
          width="24" 
          :height="svgHeight" 
          viewBox="0 0 20 20" 
          class="cell-svg"
        >
            <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" fill="#bbbbbb" font-weight="bold" font-size="12">{{ extractNumberWithLeadingZero(item.kw) }}</text>
        </svg>

      <div class="weekday-main-div" 
          @mouseover="this.$store.state.settings.arrowsVisible = false"
          v-bind:style="{ 
              backgroundColor: item.backColorWeekDay,
              cursor: this.$store.state.settings.selectedCursor,
          }"
      >
      <div class="weekday-left">
        {{item.name}}
      </div>
      <div class="weekday-right">
        {{ $t( item.weekday)}}     
      </div>
    
      <!-------------------------------------------------------------------------------------------->
      <!----------------------------------  Daily overview ----------------------------------------->
      <!-------------------------------------------------------------------------------------------->

      <div style="margin-top: 2px" class="icon" >
          <PhInfo  />
      </div>
        <v-menu v-if="item.dayHasEvent === true"
          v-model="dailyOverviewVisible"
          :open-on-click = true
          activator="parent"
          location="right"
          :close-on-content-click="false"
          :openOnHover="true" 
        >  
          <div style="
              margin-right: 5px;
            ">
              <dailyoverview
                  :item="item"
                  mode="show"
                  calendarId="all"
                  @close-menu="closeMenu"
              />
          </div> 
      </v-menu>
  </div>
    
  <!-------------------------------------------------------------------------------------------->
  <!----------------------------------  Right Box / Day ---------------------------------------->
  <!-------------------------------------------------------------------------------------------->

  <div 
      @dragenter="dragEnterMainDiv($event, item)"
      @dragover.prevent="dragOverDays[item.id] = true"
      @drop="onEventDrop(item)"
      class="right-div"
      v-bind:style="{ 
        backgroundColor:  item.backColor, 
      }"        
  >

  <!-------------------------------------------------------------------------------------------->
  <!-------------------------------- Each Calender in this day --------------------------------->
  <!-------------------------------------------------------------------------------------------->

  <div class="event-box" >
    <div 
      v-for="(myCal, index) in filteredCalendars" 
        :draggable= 'myCal.events.length> 0 &&  this.$store.state.settings.newEventMode === false ? true : false' 
        @dragstart="dragStart($event, myCal, item.id)"
        @drag="onDrag($event)"
        @dragend="onDragEnd($event)"
        :key="index" 
        :style="{ 
          backgroundColor:  myCal.dragShowSoftColor === true ? myCal.softcolor  : 
                            hoveredDiv === index && this.$store.state.settings.pushAndPullMode === false && justCreatedNewEvent === false   ?
                            myCal.softcolor  : 
                            myCal.events.length > 0  && myCal.info.visible === true  ? 
                            myCal.color  : 
                            item.backColor,
          borderStyle: myCal.dragmode === true && this.$store.state.settings.pushAndPullMode === true? 'solid' : 'none',
          borderWidth: myCal.dragmode === true && this.$store.state.settings.pushAndPullMode === true? '1px' : '0px',
          borderRadius: '2px'
        }" 
        class="child-div draggable"

        @click="
          myCal.events.length > 0 && justCreatedNewEvent === false ? myCal.menueopen = true : myCal.menueopen = false
        "
        

        @mousedown="
            myCal.dragmode = false,
            createNewEvent(
              myCal.info.name, 
              myCal.info.calendarId,
              myCal.info.color,
              item,
              myCal.events.length
            )"
        
        @mouseover="
          justCreatedNewEvent = false
          if(myCal.events.length > 0 && this.$store.state.settings.newEventMode === false){
            this.$store.state.settings.arrowsVisible = true
            this.$store.state.settings.showPhushAndPushBoxes = true
            setUpAndDownPullArrows(myCal.info.calendarId, myCal.events[0])
          }
          hoveredDiv = index; 
          checkMouseOverDay(item),
          checkMouseOverDayInNewEventMode(item)
        "
        @mouseup="
            myCal.dragmode = true,
            mouseup()
          " 

        @mouseleave=" 
          this.$store.state.settings.showPhushAndPushBoxes = false
          hoveredDiv = null; 
              clearUpAndDownPullArrows()"
        
      > 
      <!---------------------------------------------------------------------------------------------->
      <!-------------- Box at the top, which is only displayed when edit mode is active -------------->
      <!---------------------------------------------------------------------------------------------->
      
        <div v-if="myCal.showarrowpullUp === true && this.$store.state.settings.arrowsVisible === true" 
          class="floating-box top-box"
          @mousedown="setpushAndPullMode(myCal.events[0], 'up')"
        >
          <PhCaretUp  :size="11" />
        </div>
    
      <!---------------------------------------------------------------------------------------------->
      <!--------------- Box below, which is only displayed when edit mode is active ------------------>
      <!---------------------------------------------------------------------------------------------->
      
        <div v-if="myCal.showarrowpullDown === true && this.$store.state.settings.arrowsVisible ===  true"
          class="floating-box bottom-box"
          @mousedown="setpushAndPullMode(myCal.events[0], 'down')"
        >
          <PhCaretDown :size="11" />
        </div>

      <!-------------------------------------------------------------------------------------------->
      <!---------------------------------- creates a new event ------------------------------------->
      <!-------------------------------------------------------------------------------------------->
          
        <PhPlus v-if="myCal.events.length == 0"
          @mouseover="stopPullMode()"
          :size="15" 
          class="plus-icon" 
        /> 

      <!-------------------------------------------------------------------------------------------->
      <!--------------------------------------- Edit Event ---------------------------------------->
      <!-------------------------------------------------------------------------------------------->

      <PhPencilSimple 
            
              v-if="myCal.events.length > 0 && this.$store.state.settings.pushAndPullMode === false"
              v-bind="attrs"
              :size="15" 
              class="plus-icon" 
            /> 

      <v-dialog v-model="myCal.menueopen">
          <div v-if="myCal.events.length > 0"
            style="
            margin-left: 22px;
            margin-right: 22px;
          ">
            <enter-new-event
              @close-menu="closeMenu"
              :calendarId = myCal.info.calendarId
              :calendarColor = myCal.info.color
              :calendarName = myCal.info.name
              prop_mode= 'edit'
              :eventName = myCal.events[0].eventName
              :datefrom= myCal.events[0].datefrom
              :datefrom_json= 'new Date(myCal.events[0].datefrom)'
              :dateto= myCal.events[0].dateto
              :dateto_json="new Date(myCal.events[0].dateto)"
              :eventidDB = myCal.events[0].eventidDB
            />
          </div> 

          </v-dialog>
        
          <!-------------------------------------------------------------------------------------------->
          <!-------------------------- shows number of events if more then 1 --------------------------->
          <!-------------------------------------------------------------------------------------------->

          <div  v-if="myCal.events.length > 1  && hoveredDiv == null"
            style="
              font-size: 10px;
              color:white;
              ">
                {{ myCal.events.length }}
          </div>
          
      </div>
          
          
          <!-------------------------------------------------------------------------------------------->
          <!---------------------------- shows event info while dragging ------------------------------->
          <!-------------------------------------------------------------------------------------------->

          <div v-if="isDragging"
            class="drag-text"
            :style="{ top: `${mouseY}px`, left: `${mouseX}px`, position: 'fixed', pointerEvents: 'none' }">
            <v-card 
              min-width="150"
              style="
              border-radius: 8px;
              padding: 15px">

                <div style="
                  display: flex;
                  align-items: center; /* Zentriert die Elemente horizontal */
                ">

                <!-- Circle -->
                <div 
                    class="circle"
                    v-bind:style="{ 
                        backgroundColor: currentDragginCalendarcolor, 
                    }"  
                />

                <!-- Month -->
                <div style="
                    padding-left: 5px;
                    font-size: 18px;
                    font-weight: 700;
                    letter-spacing: -0.8px;
                ">{{currentDragginCalendarName}}</div>
            </div>
            <div style="
              font-weight: 300;
              letter-spacing: -0.8px;
              font-size: 16px;"
            >
              {{ currentDragginEventName }}
            </div>
            </v-card>
          </div>

          <!-------------------------------------------------------------------------------------------->
          <!-------------------------------------------------------------------------------------------->
          <!-------------------------------------------------------------------------------------------->

        </div>
      </div>
    </div>
  </div>  
</template>
    
    <script>
    import { useStore } from "vuex";
    import { defineComponent, ref, computed, mergeProps } from 'vue'
    import calenderCore from "../composition/calenderCore";
    import { useVuelidate } from '@vuelidate/core'
    import { required,maxLength } from '@vuelidate/validators'
    import { useToast } from "vue-toastification"
    import { useElementSize } from '@vueuse/core'
    import { Timestamp } from "firebase/firestore";
    import enterNewEvent from "./EnterNewEvent.vue"
    import dailyoverview from "./DailyOverview.vue"
    import { v4 as uuidv4 } from 'uuid';
    
    const toast = useToast();
    const windowHeight = ref(window.innerHeight);
    const newdayHight = ref(windowHeight.value/38);

    export default defineComponent({
    
    name: 'Popover',
    props: {
      item:Object,
    }, 
    
    components: {
      enterNewEvent,
      dailyoverview

     // Tooltip,
     // AddNewItemBoxVue,
     //PhIcon: PhSun // Du kannst ein anderes Icon wählen
    },
    
    data () {
        return {
          newEventMenueNew: false,
          newEventMenueNewRight: false,
          newEventMenueEdit: false,
          Titel: "",
          date: null,
          insertEventisOpen: false,
          deleteEventisOpen: false,
          backColor: "#ffffff",
          eventName: "",
          selectedDate: "",
          name:"",
          backColorTemp: "#ffffff",
          backColorWeekDayTemp: "#ffffff",
          currentEventText: "",
          mouseDownDate: "",
          selectedEvent: "",
          deleteBackgroundColor: "#00ff00",
          selected_dateFormatedJson: "",
          selectedDay: null,
          weekDayColor:"#ff0000",
          v$: useVuelidate(),
          rows:1,
          mouseOverEventId: -1,
          changeEventTimeWithMouse: false,
          newdayHight,
          colors: ['#DAFDD8', '#C4EDFF', '#D1D8FF', '#E5BBFF', '#FABDFF'],
          hoveredDiv: null,
          menuVisible: false,
          dailyOverviewVisible: false,
          isMouseDown: false, 
          ArrowwCalendarId: '',
          ArrowsUpDayId: '',
          ArrowsDownDayId: '',
          dragMode: true,
          isDragging: false,
          mouseX: 0,
          mouseY: 0,
          currentDragginCalendarcolor: 'ffffff',
          currentDragginEventIdDB:'xxx',
          currentDragginEventName:'',
          currentDragginCalendarName: '',
          currentDragginCalendarId : '',
          currentDraggingDayId: '',
          currentDragginOverDayId: '',
          dragOverDays: {}, // Speichert, ob über jedem individuellen div gedragt wird
          svgContent: "@/assets/images/kw/01.svg",
          justCreatedNewEvent: false,
          showEditMenu: false
        }
      },
    
      mounted() {      
        window.addEventListener("resize", this.calculateWindowWidth);   
        window.addEventListener('mousedown', this.handleMouseDown);
        window.addEventListener('mouseup', this.handleMouseUp); 
      },   
      
      beforeUnmount() {      
        window.removeEventListener("resize", this.calculateWindowWidth);  
        window.removeEventListener('mousedown', this.handleMouseDown);
        window.removeEventListener('mouseup', this.handleMouseUp); 
      },

    
      computed:{

        isAuthenticated() {
          return this.$store.getters['user/isAuthenticated'];
        },

        userId(){
          return this.$store.state.user.data != undefined ?  this.$store.state.user.data.uid : "notsignedin"
        },
    
        filteredCalendars() {
          return this.item.myCalendars.filter(myCal => myCal.type === '1');
          //return this.item.myCalendars
        },
    
        currentCalendarColor(){
          return this.$store.state.settings.currentCalendarColor;
        },
        events(){
            return this.$store.state.events.items;
          }
        },
        items(){
              return this.$store.state.calender.items;
        },
        myCalendarItems(){
              return this.$store.state.mycalendars.items;
        },
    
        async created(){
          if(this.$store.state.settings.EventsLoaded == false){
            this.calenderCore1 = calenderCore();
            await this.$store.dispatch("getMyEvents").then (
            setTimeout(() => {  this.calenderCore1.updateCalender(); }, 500)
          )
          this.$store.state.settings.EventsLoaded = true
          }
        },
      
        
    
    
  methods:{

    openEditEventMenu(eventsCount){
      if(eventsCount > 0 && this.$store.state.settings.pushAndPullMode === false){
        try{
          this.$store.state.settings.showEditEventMenu = true 
        }catch(e){
          console.log(e)
        }
      }
    },

    async createNewEvent(calendarName, calendarId, calendarColor,item, eventsCount){

      if(eventsCount > 0){
        return // Only 1 event per Calendar. At least for now
      }

      this.justCreatedNewEvent = true

      let newEvent = {
          calendarId: calendarId,
          eventidDB: uuidv4() ,
          color: calendarColor, 
          title: 'Event',
          datefrom:  item.id ,
          dateto: item.id,
          datefromJson: new Date(item.id), 
          datetoJson:new Date(item.id),
          icon: 'PHCalendar', 
          fromHour: '08',
          fromMinute: '00',
          toHour: '18',
          toMinute: '00',
          isFullDay: this.$store.state.dayevent.isFullDay,
          creatorUID: this.userId,
          calendarName: calendarName,
          additionalInfos: '',
          rRules: "ONCE",
          location: "",
          removedDays: [],
          type: "1"
      }

      this.events.push(newEvent)
      this.$store.state.settings.currentEvent = newEvent

      // Refresh Events Indexlist
      for(let index = 0; index < this.events.length; ++index){
        this.events[index].index = index;
      }

      // Write each event index in the events of each mycalendars
      for (let calenderIndex = 0; calenderIndex < this.$store.state.mycalendars.items.length; calenderIndex++) {
        this.$store.state.mycalendars.items[calenderIndex].events = []
          for (let eventIndex = 0; eventIndex < this.events.length; eventIndex++){
              if(this.$store.state.mycalendars.items[calenderIndex].calendarId == this.events[eventIndex].calendarId){
                this.$store.state.mycalendars.items[calenderIndex].events.push(eventIndex)
              }
          } 
      } 
      
      this.calenderCore1.updateCalender()
      this.setNewEventMode(true)

    },

     extractNumberWithLeadingZero(fileName) {
      // Extrahiere die Zahl vor .svg
      const match = fileName.match(/^(\d+)\.svg$/);
      
      if (match) {
        let number = parseInt(match[1], 10); // Wandelt die Zahl in eine Ganzzahl um
        return number < 10 ? `0${number}` : `${number}`; // Fügt eine führende Null hinzu, wenn die Zahl kleiner als 10 ist
      }
      
      return null; // Gibt null zurück, falls das Format nicht passt
    },

    stopPullMode(){
      if(this.$store.state.settings.arrowsVisible === true ){
        this.$store.state.settings.arrowsVisible = false
      }
    },

    updateMousePosition(event) {
      this.mouseX = event.clientX + 10; // Position etwas versetzt
      this.mouseY = event.clientY + 10;
    },
    
    dragStart(event,myCal, itemId){
      try{
        this.calenderCore1.HideArrows()
        this.isDragging = true
        this.updateMousePosition(event);
        this.currentDragginCalendarId = myCal.info.calendarId
        this.$store.state.settings.currentDraggingCalendarId = myCal.info.calendarId
        this.currentDragginCalendarcolor = myCal.info.color
        this.currentDragginCalendarName = myCal.info.name
        this.currentDragginEventName = myCal.events[0].eventName
        this.$store.state.settings.currentDragginEventIdDB = myCal.events[0].eventId
        this.currentDraggingDayId = itemId
        event.dataTransfer.effectAllowed = 'move'; // Verhindert das Kopier-Symbol
      }catch(e){
        console.log("Error - PopoverCalendarDay - dragStart: " + e)
      }
    },

    onEventDrop(item){
      
      // ---------------- Check if we drop on a day which has allready an event for this calendar -----

      for(let ix = 0; ix < item.myCalendars.length; ++ix){
        if(item.myCalendars[ix].events.length > 0){
          if(item.myCalendars[ix].events[0].calendarId === this.$store.state.settings.currentDraggingCalendarId){
            this.$store.state.settings.arrowsVisible = false
            this.calenderCore1.HideArrows()
            this.dragOverDays = {}
            this.calenderCore1.resetDragBackColor()
            return
          }
        }
      }

      // ---------------- Generate Start and EndDate for this Event -----------------------

      for (let index = 0; index < this.events.length; ++index){
        if(this.events[index].eventidDB === this.$store.state.settings.currentDragginEventIdDB){
          let date_1 = new Date(this.events[index].dateto);
          let date_2 = new Date(this.events[index].datefrom);
          let difference = date_1.getTime() - date_2.getTime();
          let TotalDays = Math.ceil(difference / (1000 * 3600 * 24) );
          const newStartDate = new Date(item.id);
          const newEndDate = new Date(item.id);
          newEndDate.setDate(newEndDate.getDate() + TotalDays)
          let correctEndDate = new Date(item.id)
        
          let newYearFrom = newStartDate.getYear() + 1900
          let newMonthFrom = newStartDate.getMonth() + 1
          let newDayFrom = newStartDate.getDate()
        
          let newYearTo = newEndDate.getYear() + 1900
          let newMonthTo = newEndDate.getMonth() + 1
          let newDayTo = newEndDate.getDate()
        
          if(newMonthFrom < 10) newMonthFrom = "0"+newMonthFrom;
          if(newDayFrom < 10) newDayFrom = "0"+newDayFrom;
          if(newMonthTo < 10) newMonthTo = "0"+newMonthTo;
          if(newDayTo < 10) newDayTo = "0"+newDayTo;


          // ---------------- Check if another event is already there, then we stop -----------------------

          correctEndDate = this.calenderCore1.dayTheCalendarHasAlreadyAnEvent(
            item.id,
            newStartDate,
            newEndDate, 
            this.$store.state.settings.currentDraggingCalendarId,
            this.$store.state.settings.currentDragginEventIdDB
          )

          correctEndDate.setDate(correctEndDate.getDate() - 1)
      
          newYearTo = correctEndDate.getYear() + 1900
          newMonthTo = correctEndDate.getMonth() + 1
          newDayTo = correctEndDate.getDate()
      
          if(newMonthTo < 10) newMonthTo = "0"+newMonthTo;
          if(newDayTo < 10) newDayTo = "0"+newDayTo;



          this.events[index].datefrom =  newYearFrom + "-" + newMonthFrom + "-" + newDayFrom,
          this.events[index].dateto = newYearTo + "-" + newMonthTo + "-" + newDayTo,
          this.events[index].datefromJson = this.getMonthName(newMonthFrom) + " " + newDayFrom + ", " + newYearFrom + " 12:00:00 UTC" ,
          this.events[index].datetoJson = this.getMonthName(newMonthTo) + " " + newDayTo + ", " + newYearTo + " 12:00:00 UTC"      

          this.$store.state.settings.arrowsVisible === false

           //------------------- SAVE EVENT CHANGES IN FIRESTORE DATABASE START -----------------------------------------
  
        if(this.isAuthenticated === true){
          let newEvent = {
            calendarId: this.events[index].calendarId, //[state.settings.currentDraggingEventIndex].calendarId,
            eventidDB : this.events[index].eventidDB,
            color: this.events[index].color, 
            title: this.events[index].title,
            datefrom: newYearFrom + "-" + newMonthFrom + "-" + newDayFrom,
            dateto: newYearTo + "-" + newMonthTo + "-" + newDayTo,
            datefromJson: this.getMonthName(newMonthFrom) + " " + newDayFrom + ", " + newYearFrom + " 12:00:00 UTC" ,
            datetoJson: this.getMonthName(newMonthTo) + " " + newDayTo + ", " + newYearTo + " 12:00:00 UTC",
            fromHour: '08',//events[state.settings.currentDraggingEventIndex].fromHour,
            fromMinute: '00',//events[state.settings.currentDraggingEventIndex].fromMinute,
            toHour: '18',//events[state.settings.currentDraggingEventIndex].toHour,
            toMinute: '00',//events[state.settings.currentDraggingEventIndex].toMinute,
            isFullDay: true,//events[state.settings.currentDraggingEventIndex].isFullDay,
            additionalInfos: '',//events[state.settings.currentDraggingEventIndex].additionalInfos,
            rRules: "ONCE",//events[state.settings.currentDraggingEventIndex].rRules,  
            removedDays: []    
          }
        this.$store.dispatch("updateStoredEvent", newEvent);
        console.log("newEvent: " + newEvent)
        
      }
          this.$store.state.settings.arrowsVisible = false
          this.calenderCore1.HideArrows()
          this.calenderCore1.updateCalender()
          

        }        
      }
      this.hoveredDiv = null; 
      this.dragOverDays = {}
      this.calenderCore1.resetDragBackColor()
      this.closeEditMode()
      this.calenderCore1.updateCalender()
    },


     getMonthName  (month) {
      
      switch(month) {
      case "01":
        return "Januar"
      case "02":
        return "February"
      case "03":
        return "March"
      case "04":
        return "April"
      case "05":
        return "May"
      case "06":
        return "June"
      case "07":
        return "July"
      case "08":
        return "August"
      case "09":
        return "Sepetember"
      case 10:
        return "October"
      case 11:
        return "November"
      case 12:
        return "December"
     
    }
     },

    onDrag(event) {
      this.updateMousePosition(event);
      event.dataTransfer.dropEffect = 'move'; // Zeigt an, dass das Element verschoben wird
    },
    onDragEnd() {
      this.isDragging = false;
      //Object.keys(this.dragOverDays).forEach(dayId => {
      //  this.dragOverDays[dayId] = false;
      //});
      // Cursor nach Ende des Drag-Vorgangs zurücksetzen
      document.body.style.cursor = 'default';
      this.isDragOverMainDiv = false; // Zurücksetzen des Status
      this.$store.state.settings.showPhushAndPushBoxes = false
      this.hoveredDiv = null; 
      this.clearUpAndDownPullArrows()
      this.calenderCore1.resetDragBackColor()
    },

    getDragOverlayEffectDays(item){

    // --------------------- loop through all events --------------------------
    for (let index = 0; index < this.events.length; ++index){
      // -------------- find the current Event which is dragged ---------------
      if(this.events[index].eventidDB === this.$store.state.settings.currentDragginEventIdDB){
        // ----------------- count the days of the event ----------------------
        let date_1 = new Date(this.events[index].dateto);
        let date_2 = new Date(this.events[index].datefrom);
        let difference = date_1.getTime() - date_2.getTime();
        let TotalDays = Math.ceil(difference / (1000 * 3600 * 24) );
        const newStartDate = new Date(item.id);
        const newEndDate = new Date(item.id);
        newEndDate.setDate(newEndDate.getDate() + TotalDays)
        let correctEndDate = new Date(item.id)
      
        let newYearFrom = newStartDate.getYear() + 1900
        let newMonthFrom = newStartDate.getMonth() + 1
        let newDayFrom = newStartDate.getDate()
      
        let newYearTo = newEndDate.getYear() + 1900
        let newMonthTo = newEndDate.getMonth() + 1
        let newDayTo = newEndDate.getDate()
      
        if(newMonthFrom < 10) newMonthFrom = "0"+newMonthFrom;
        if(newDayFrom < 10) newDayFrom = "0"+newDayFrom;
        if(newMonthTo < 10) newMonthTo = "0"+newMonthTo;
        if(newDayTo < 10) newDayTo = "0"+newDayTo;


        // ---------------- Check if another event is already there, then we stop -----------------------

        correctEndDate = this.calenderCore1.dayTheCalendarHasAlreadyAnEvent(
          item.id,
          newStartDate,
          newEndDate, 
          this.$store.state.settings.currentDraggingCalendarId,
          this.$store.state.settings.currentDragginEventIdDB
        )

        correctEndDate.setDate(correctEndDate.getDate() - 1)
    
        newYearTo = correctEndDate.getYear() + 1900
        newMonthTo = correctEndDate.getMonth() + 1
        newDayTo = correctEndDate.getDate()
    
        if(newMonthTo < 10) newMonthTo = "0"+newMonthTo;
        if(newDayTo < 10) newDayTo = "0"+newDayTo;

        const start = new Date(newYearFrom + "-" + newMonthFrom + "-" + newDayFrom); // datefrom
        const end = new Date(newYearTo + "-" + newMonthTo + "-" + newDayTo); // dateto
        
        // Berechnen der Differenz in Millisekunden und Umwandlung in Tage
        const diffTime = Math.abs(end - start);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        
        return diffDays + 1;

      }  
    }
  },
 
  dragEnterMainDiv(event, day) {
      
      const daysToProcess = this.getDragOverlayEffectDays(day)
      this.calenderCore1.resetDragBackColor()

      
        this.$store.state.settings.currentDraggingDayId = day.id

        //----------- set the Backgroundcolor for the calendar in that day --------------------------
        this.calenderCore1.setDragBackColor(day.id, daysToProcess)
        //----------------------------------------------------------------------------------------------
      
    },

    dragLeaveMainDiv(event, dayId) {
      //this.dragOverDays[dayId] = false; // Entferne den Status für das spezifische div
      if(this.$store.state.settings.currentDraggingDayId != ''){
        this.$store.state.settings.currentDraggingDayId = ''
      }
      console.log(dayId)
    },


    checkArrowsUp(from,dayId){
      
      let date1 = new Date(from);
      let date2 = new Date(dayId);

      if (date1 === date2) {
        return true
      } else {
        return false
      }
    },

    checkArrowsDown(to,dayId){
      
      let date1 = new Date(to);
      let date2 = new Date(dayId);

      if (date1 === date2) {
        return true
      } else {
        return false
      }
    },

    clearUpAndDownPullArrows(){
        //if(this.ArrowwCalendarId != ''){
          this.ArrowwCalendarId = ''
          this.ArrowsUpDayId = ''
          this.ArrowsDownDayId = ''
        //}
    },

    setUpAndDownPullArrows(calId,calEvent){
      this.$store.state.settings.curentActivePushAndPullCalendarId = calId
        if(this.$store.state.settings.pushAndPullMode === false){
          this.calenderCore1.setUpArrows(calId,calEvent)
          this.ArrowwCalendarId = calId
          this.ArrowsUpDayId = calEvent.datefrom
          this.ArrowsDownDayId = calEvent.dateto
      } 
    },

    showPullArrowUp(event, item2){
      if(event != undefined){
        for(let i = 0; i < item2.events.length; i++){
          if(event.datefrom == item2.id){
            if(item2.events[i].eventidDB === event.eventidDB){
                return true
              }else{
                return false
              }
            }
        }
      }else{
        return false
      }
    },     

    showPullArrowDown(event, item2){
      if(event != undefined){
        for(let i = 0; i < item2.events.length; i++){
          if(event.dateto == item2.id){
            if(item2.events[i].eventidDB === event.eventidDB){
                return true
              }else{
                return false
              }
            }
        }
        }else{
          return false
        }
      
    },     
    
    handleMouseDown() {
      this.isMouseDown = true;
    },
    handleMouseUp() {
      this.isMouseDown = false;
    },

    checkMouseDown(event) {
      // Überprüfe, ob die linke Maustaste gedrückt ist
      if (event && event.buttons === 1) {
        return true
      } else if (!event && this.isMouseDown) {
        return false
      }
    },

    isFirstDateLaterUp(newDate, dateto) {

      const DateNew = new Date(newDate)
      const toDate = new Date(dateto)
      
      if(DateNew <= toDate){
        return true
      }else{  
        return false
      }

    },

    isFirstDateLaterDown(newDate, datefrom) {

      const DateNew = new Date(newDate)
      const fromDate = new Date(datefrom)

      if(DateNew >= fromDate){
        return true
      }else{  
        return false
      }

      },
    
    checkMouseOverDay(day){

      //----------------------- Are we in push and pull mode? --------------------------------------
      if(this.$store.state.settings.pushAndPullMode === true ||  this.$store.state.settings.pushAndPullMode === true){

        if(this.isMouseDown == true){
          var ev = this.events.find(ev => ev.eventidDB === this.$store.state.settings.currentEvent.eventidDB)
            //----------------------- Are we going up? --------------------------------------
          if(this.$store.state.settings.pushAndPullTarget === 'up'){
              if(ev.datefrom != day.id && this.isFirstDateLaterUp(day.id, ev.dateto) === true){
                if(this.calenderCore1.hasAllreadyEventUp(day.id, ev.calendarId, ev.eventidDB) === false){
                  this.$store.state.settings.pushAndPullMode = true
                  ev.datefrom = day.id
                  ev.datefromJson = new Date(day.id)
                  this.calenderCore1.updatedateEvent(ev,day.id)
                  this.$store.state.settings.eventNeedsSaving = ev.eventidDB
                }
              } 
            }else{ 
            //----------------------- Are we going down? --------------------------------------
                if(ev.dateto != day.id && this.isFirstDateLaterDown(day.id, ev.datefrom) === true ){
                  if(this.calenderCore1.hasAllreadyEventDown(day.id, ev.calendarId, ev.eventidDB) === false){
                    this.$store.state.settings.pushAndPullMode = true
                    ev.dateto = day.id
                    ev.datefromJson = new Date(day.id)
                    this.calenderCore1.updatedateEvent(ev,day.id)
                    this.$store.state.settings.eventNeedsSaving = ev.eventidDB
                  }
                }
              }
            }
        }
    },

    setNewEventMode(mode){
      this.$store.state.settings.newEventMode = mode
    },

    checkMouseOverDayInNewEventMode(day){

      //----------------------- Are we in push and pull mode? --------------------------------------
      if(this.$store.state.settings.newEventMode === true && this.$store.state.settings.pushAndPullMode === false){

        if(this.isMouseDown == true){
          var ev = this.events.find(ev => ev.eventidDB === this.$store.state.settings.currentEvent.eventidDB)
            //----------------------- Are we going up? --------------------------------------
         /* if(this.$store.state.settings.pushAndPullTarget === 'up'){
              if(ev.datefrom != day.id && this.isFirstDateLaterUp(day.id, ev.dateto) === true){
                if(this.calenderCore1.hasAllreadyEventUp(day.id, ev.calendarId, ev.eventidDB) === false){
                  ev.datefrom = day.id
                  ev.datefromJson = new Date(day.id)
                  this.calenderCore1.updatedateEvent(ev,day.id)
                  this.$store.state.settings.eventNeedsSaving = ev.eventidDB
                }
              } 
            }else{ */
            //----------------------- Are we going down? --------------------------------------
                if(ev.dateto != day.id && this.isFirstDateLaterDown(day.id, ev.datefrom) === true ){
                  if(this.calenderCore1.hasAllreadyEventDown(day.id, ev.calendarId, ev.eventidDB) === false){
                    ev.dateto = day.id
                    ev.datefromJson = new Date(day.id)
                    this.calenderCore1.updatedateEvent(ev,day.id)
                    this.$store.state.settings.eventNeedsSaving = ev.eventidDB
                  }
                }
             // }
            }
        }
      },
          
          async updateEvent(){
            
              //  calenderCore1.updateCalender();  
    },

    setpushAndPullMode(event, target){
      this.$store.state.settings.pushAndPullMode = true
      this.$store.state.settings.currentEvent = event
      this.$store.state.settings.pushAndPullTarget = target
      this.dragMode = false
      this.$store.state.settings.newEventMode = false
    },

    closeEditMode(){
      for (let month = 0; month < this.$store.state.calender.items.length; ++month){
        for(let day = 0; day < 31; day++){
            for (let mc = 0; mc < this.$store.state.calender.items[month][day].myCalendars.length; ++mc){
              this.$store.state.calender.items[month][day].myCalendars[mc].menueopen = false
              this.$store.state.calender.items[month][day].myCalendars[mc].editmode = false
            }
          }
        }

      this.dailyOverviewVisible = false
    },

    
    closeMenu() {
      this.closeEditMode()
    },
    
      calenderContainsEvent(myCalendar, myitem){
        for (let i = 0; i < myitem.events.length; i++) {
            if(myCalendar.calendarId == myitem.events[i].calendarId){
              return true
            }
        }  
        return false
      },
    
      getEventBoxColor(myCalendar, myitem){
    
        for (let i = 0; i < myitem.events.length; i++) {
            if(myCalendar.calendarId == myitem.events[i].calendarId){
              return myCalendar.color
            }
        }  
        return myitem.backColor
      },
    
        getCalendarColor(cl){
            if(cl == "#6AD069")
                return "#DAFDD8"
            else if(cl == "#4BA0C4")
                return "#C4EDFF"
            else if(cl == "#5C70D7")
                return "#D1D8FF"
            else if(cl == "#9D41D5")
                return "#E5BBFF"
            else if(cl == "#D272DA")
                return "#FABDFF"
            else
                return "#ff0000"
    
        },
    
      mergeProps,
    
      calculateWindowWidth() {        this.windowWidth = window.innerWidth;      },
    
      returnbgimage(day){
        if(this.$store.state.settings.showKalenderwochen == true){
          return '@/assets/images/kw/' + day.kw;
        }
        else{
          return('@/assets/images/kw/' + "empty.svg");
        }
      },
    
      closeEventInfoBox(){
        this.newEventMenueNew = false
        for (let i2 = 0; i2 < this.$store.state.calender.items.length; i2++) {
          for (let i3 = 0; i3 < this.$store.state.calender.items[i2].events.length; i3++) {
            this.$store.state.calender.items[i2].events[i3].eventInfoBoxOpen = false
          }
        }
      },
    
      
      closeNewEventMenue(){
        this.newEventMenueNew = false
        this.newEventMenueNewRight = false
        
        for (let x = 0; x < this.$store.state.calender.items.length; x++){
          for (let y = 0; y < this.$store.state.calender.items[x].length; y++){
              for (let i3 = 0; i3 < this.$store.state.calender.items[x][y].events.length; i3++){
              this.$store.state.calender.items[x][y].events[i3].menuopen = false
            }
          }
        }
      },
    
    
    
      clickOnDayNumber(item){
        this.$store.state.settings.eventName = "";
        this.$store.state.settings.eventColor =  '#ebebeb';
        this.$store.state.settings.SlotHeader = "Neues Ereignis";     
        this.$store.state.settings.ShowDeleteButton = false; 
        this.$store.state.settings.SlotMode = "New";
        this.$store.state.settings.currentEventIdDB = "No Event"
        this.insertEventisOpen = true;
        this.selectedDate = item.dateFormated_De;
        this.$store.state.settings.slectedDayId = item.id;
        this.$store.state.settings.currentdatefromJson = item.dateFormated_Json;
        this.selected_dateFormatedJson = item.dateFormated_Json;
        this.selectedDay = item;
        this.$store.state.settings.currentdatefrom = item.dateFormated_Json// this.GetSelectedEvent(this.SelectedDateHasEvent(item.id)).datefrom
        this.$store.state.settings.currentdateto = item.dateFormated_Json//  this.GetSelectedEvent(this.SelectedDateHasEvent(item.id)).dateto
        this.$store.state.settings.currentdatefromJson =  item.dateFormated_Json// this.GetSelectedEvent(this.SelectedDateHasEvent(item.id)).datefromJson
        this.$store.state.settings.currentdatetoJson = item.dateFormated_Json//  this.GetSelectedEvent(this.SelectedDateHasEvent(item.id)).datetoJson
        this.$store.state.dayevent.dateFrom = new Date(item.id)
        this.$store.state.dayevent.dateTo = new Date(item.id)
      },
    
    
      click(item){
        if(this.SelectedDateHasEvent(item.id) != false){
          // Edit Event
          this.$store.state.settings.eventName = this.GetSelectedEvent(this.SelectedDateHasEvent(item.id)).title;
          this.$store.state.settings.eventColor =  item.backColor;
          this.$store.state.settings.SlotHeader = "Ereignis bearbeiten";
          this.$store.state.settings.ShowDeleteButton = true;
          this.$store.state.settings.SlotMode = "Edit";
          this.$store.state.settings.currentEventId = this.GetSelectedEvent(this.SelectedDateHasEvent(item.id)).id;
          this.$store.state.settings.currentEventIdDB = this.GetSelectedEvent(this.SelectedDateHasEvent(item.id)).eventidDB
          this.deleteBackgroundColor = item.backColor ;
          this.deleteEventisOpen = true;
          this.selectedEvent = this.GetSelectedEvent(this.SelectedDateHasEvent(item.id));
          this.selectedDay = null;
          this.$store.state.settings.currentdatefrom = this.GetSelectedEvent(this.SelectedDateHasEvent(item.id)).datefrom
          this.$store.state.settings.currentdateto = this.GetSelectedEvent(this.SelectedDateHasEvent(item.id)).dateto
          this.$store.state.settings.currentdatefromJson = this.GetSelectedEvent(this.SelectedDateHasEvent(item.id)).datefromJson
          this.$store.state.settings.currentdatetoJson = this.GetSelectedEvent(this.SelectedDateHasEvent(item.id)).datetoJson
        }
        // Insert Event
        else{
            this.$store.state.settings.eventName = "";
            this.$store.state.settings.eventColor =  '#ebebeb';
            this.$store.state.settings.SlotHeader = "Neues Ereignis";     
            this.$store.state.settings.ShowDeleteButton = false; 
            this.$store.state.settings.SlotMode = "New";
            this.$store.state.settings.currentEventIdDB = "No Event"
            this.insertEventisOpen = true;
            this.selectedDate = item.dateFormated_De;
            this.$store.state.settings.slectedDayId = item.id;
            this.$store.state.settings.currentdatefromJson = item.currentdatefromJson;
            this.selected_dateFormatedJson = item.dateFormated_Json;
            this.selectedDay = item;
        }
      },
    
      doubleclick(item){
      
        if(this.SelectedDateHasEvent(item.id) != false){
          // Edit Event
          this.$store.state.settings.eventName = this.GetSelectedEvent(this.SelectedDateHasEvent(item.id)).title;
          this.$store.state.settings.eventColor =  item.backColor;
          this.$store.state.settings.SlotHeader = "Ereignis bearbeiten";
          this.$store.state.settings.ShowDeleteButton = true;
          this.$store.state.settings.SlotMode = "Edit";
          this.$store.state.settings.currentEventId = this.GetSelectedEvent(this.SelectedDateHasEvent(item.id)).id;
          this.$store.state.settings.currentEventIdDB = this.GetSelectedEvent(this.SelectedDateHasEvent(item.id)).eventidDB
          this.deleteBackgroundColor = item.backColor ;
          this.deleteEventisOpen = true;
          this.selectedEvent = this.GetSelectedEvent(this.SelectedDateHasEvent(item.id));
          this.selectedDay = null;
          this.$store.state.settings.currentdatefrom = this.GetSelectedEvent(this.SelectedDateHasEvent(item.id)).datefrom
          this.$store.state.settings.currentdateto = this.GetSelectedEvent(this.SelectedDateHasEvent(item.id)).dateto
          this.$store.state.settings.currentdatefromJson = this.GetSelectedEvent(this.SelectedDateHasEvent(item.id)).datefromJson
          this.$store.state.settings.currentdatetoJson = this.GetSelectedEvent(this.SelectedDateHasEvent(item.id)).datetoJson
        }
        // Insert Event
        else{
            this.$store.state.settings.eventName = "";
            this.$store.state.settings.eventColor =  '#ebebeb';
            this.$store.state.settings.SlotHeader = "Neues Ereignis";     
            this.$store.state.settings.ShowDeleteButton = false; 
            this.$store.state.settings.SlotMode = "New";
            this.$store.state.settings.currentEventIdDB = "No Event"
            this.insertEventisOpen = true;
            this.selectedDate = item.dateFormated_De;
            this.$store.state.settings.slectedDayId = item.id;
            this.$store.state.settings.currentdatefromJson = item.currentdatefromJson;
            this.selected_dateFormatedJson = item.dateFormated_Json;
            this.selectedDay = item;
        }
      
    },
    
    /*mousedown(item, event, id){
      
      if(id == 2){
        this.$store.state.settings.ismousedown = true
      
          if(this.$store.state.user.userID == event.creatorUID){
            this.changeEventTimeWithMouse = true
    
          if(this.TerminHasOnlyOneDay(event)){
            this.$store.state.settings.mouseDownEventStartDate = item.id;
            this.$store.state.settings.mouseDownEventEndDate = item.id
            this.$store.state.settings.selectedEventIDtoChangeDate = event.eventidDB;
            this.$store.state.settings.selectedEventIndexToChange = event.index;
            this.$store.state.settings.FirstDayOfEventSelected  = true;
            this.$store.state.settings.LastDayOfEventSelected = true;
          }
          else if (this.IsFirstDayOfEvent(event, item.id) == true){
            this.$store.state.settings.mouseDownEventStartDate = item.id;
            this.$store.state.settings.selectedEventIDtoChangeDate = event.eventidDB;
            this.$store.state.settings.selectedEventIndexToChange = event.index;
            this.$store.state.settings.FirstDayOfEventSelected = true;
            this.$store.state.settings.LastDayOfEventSelected = false;
            
          }
            else if(this.IsLastDayOfEvent(event, item.id) == true){
              this.$store.state.settings.mouseDownEventEndDate = item.id;
              this.$store.state.settings.selectedEventIDtoChangeDate = event.eventidDB;
              this.$store.state.settings.selectedEventIndexToChange = event.index;
              this.$store.state.settings.FirstDayOfEventSelected = false;
              this.$store.state.settings.LastDayOfEventSelected = true;
            }
          }
      }
    },
    */
    
    mouseup(){

      this.setNewEventMode(false)
      this.dragMode = false

      this.$store.state.settings.pushAndPullMode = false
      this.calenderCore1.resetDragBackColor()
      this.clearUpAndDownPullArrows()

      this.mouseOverEventId = -1
    
      if(this.$store.state.settings.eventNeedsSaving != "no"){
        for (let index = 0; index < this.events.length; ++index) {
            if (this.events[index].eventidDB == this.$store.state.settings.eventNeedsSaving)
            {
              if(this.$store.state.settings.Authenticated == true){
                let newEvent = {
                  calendarId: this.events[index].calendarId,
                  eventidDB : this.events[index].eventidDB,
                  color: this.events[index].color, 
                  title: this.events[index].title,
                  datefrom: this.events[index].datefrom,
                  dateto: this.events[index].dateto,
                  datefromJson: this.events[index].datefromJson ,
                  datetoJson: this.events[index].datetoJson,   
                  fromHour: this.events[index].fromHour,
                  fromMinute: this.events[index].fromMinute,
                  toHour: this.events[index].toHour,
                  toMinute: this.events[index].toMinute,
                  isFullDay: this.events[index].isFullDay,
                  additionalInfos: this.events[index].additionalInfos,
                  editedOn: Timestamp.now(),
                  rRules: this.events[index].rRules,
                  removedDays: [] 
              } 
              this.$store.dispatch("updateStoredEvent", newEvent); 
              }  
            }
        }
    
        this.$store.state.settings.eventNeedsSaving = "no"
      }
        
    },
    
    /*mouseenter(item, id){
    
      if(id == 2){
          this.mouseOverEventId = item.eventId
          //this.$store.state.settings.selectedCursor = "grab"
      }
     
      if(this.$store.state.settings.ismousedown == true){
          
            if(this.$store.state.settings.LastDayOfEventSelected == true){
              this.addDayToEventBottom(item);
            }
            else if(this.$store.state.settings.FirstDayOfEventSelected == true){
              this.addDayToEventTop(item);
            }
            
      }
     
      if(this.$store.state.settings.ismousedown == false){
          if(this.$store.state.settings.selectedCursor != item.cursor){
            this.$store.state.settings.selectedCursor = item.cursor;
          }
      } 
    
      //Highlight day under MouseMove
      this.backColorTemp = item.backColor;
      this.backColorWeekDayTemp = item.backColorWeekDay;
      item.backColorWeekDay = '#f5fc81';
    
    },*/
    
    /*mouseleave(item){ 
      
      //item.backColor = this.backColorTemp;
      
      item.backColorWeekDay = this.backColorWeekDayTemp;
      
      if(this.$store.state.settings.ismousedown == true){
       
       if(this.$store.state.settings.FixedWithMousUp == false){
       // console.log("jetzt updaten")
        this.calenderCore1.updateCalender();
        this.$store.state.settings.FixedWithMousUp = true
       }
        
      }
       
    },*/
    
    /*mousemove(item)
    {
      
      if(this.$store.state.settings.ismousedown == true)
      {
        if(item.backColor == this.backColor)
        {
          item.backColor = item.backColorWeekDay;
        }
    
        if(this.$store.state.settings.ctrlPressed == true){
          this.calenderCore1.updateCalender();
        }
      }
    },*/
    
    addDayToEventBottom(item){ 
      if(this.$store.state.settings.ismousedown == true && this.$store.state.settings.ctrlPressed == false)
      {
        this.$store.state.settings.selectedCursor = "ns-resize";
        //Add day to event
        if(this.$store.state.settings.mouseDownEventEndDate != false){    
          
          var dt1 = new Date(this.$store.state.settings.mouseDownEventEndDate);
          var dt2 = new Date(item.id);
    
          if(dt2 >= dt1){ 
          //if(Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate()) ) /(1000 * 60 * 60 * 24)) > -1){
            //for (let index = 0; index < this.events.length; ++index) {
              //if (this.events[index].eventidDB == this.$store.state.settings.selectedEventIDtoChangeDate)
              {
                  //if(this.IsLastEventDateWithinNextEvent(this.events[index].id,item)== false){
                      this.events[this.$store.state.settings.selectedEventIndexToChange].dateto = item.id;
                      this.events[this.$store.state.settings.selectedEventIndexToChange].datetoJson =  item.dateFormated_Json ,
                      this.calenderCore1.updatedateEvent(this.events[this.$store.state.settings.selectedEventIndexToChange], item.id);
                      this.$store.state.settings.addDayOnEvent = false;
                      this.$store.state.settings.eventNeedsSaving = this.events[this.$store.state.settings.selectedEventIndexToChange].eventidDB
                //} 
              //} 
            }
          }
          //Remove day from event
          if(dt1 > dt2){ 
          //else if(Math.floor((Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate()) - Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) ) /(1000 * 60 * 60 * 24)) > 0){
            //for (let index = 0; index < this.events.length; ++index) {
              //if (this.events[index].eventidDB == this.$store.state.settings.selectedEventIDtoChangeDate)
              //{
                var dt_from = new Date (this.events[this.$store.state.settings.selectedEventIndexToChange].datefrom);
                var dt_to = new Date(item.id);
                
                if(Math.floor((Date.UTC(dt_from.getFullYear(), dt_from.getMonth(), dt_from.getDate()) - Date.UTC(dt_to.getFullYear(), dt_to.getMonth(), dt_to.getDate()) ) /(1000 * 60 * 60 * 24)) < 1){
                    this.events[this.$store.state.settings.selectedEventIndexToChange].dateto = item.id;
                    this.events[this.$store.state.settings.selectedEventIndexToChange].datetoJson =  item.dateFormated_Json;
                    this.calenderCore1.updatedateEvent(this.events[this.$store.state.settings.selectedEventIndexToChange], item.id);
                    this.$store.state.settings.eventNeedsSaving = this.events[this.$store.state.settings.selectedEventIndexToChange].eventidDB
                } 
              //}
            //}
          }
        } 
      }
    
    },
    
    addDayToEventTop(item){
      if(this.$store.state.settings.ismousedown == true && this.$store.state.settings.ctrlPressed == false)
      {
        //Add day to event
        this.$store.state.settings.selectedCursor = "ns-resize";
        if(this.$store.state.settings.mouseDownEventStartDate != false){    
            var dt1 = new Date(this.$store.state.settings.mouseDownEventStartDate);
            var dt2 = new Date(item.id);
    
            if(dt1 >= dt2){
            //if(Math.floor((Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate()) - Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) ) /(1000 * 60 * 60 * 24)) >   -1){
              //for (let index = 0; index < this.events.length; ++index) {
              //  if (this.events[index].eventidDB == this.$store.state.settings.selectedEventIDtoChangeDate)
               // {
                  
                   // if(this.IsFirstEventDateWithinPreviousEvent(this.events[index].id,item)== false){
                        this.events[this.$store.state.settings.selectedEventIndexToChange].datefrom = item.id;
                        this.events[this.$store.state.settings.selectedEventIndexToChange].datefromJson = item.dateFormated_Json;
                        this.$store.state.settings.mouseDownEventStartDate = item.id;
                        this.calenderCore1.updatedateEvent(this.events[this.$store.state.settings.selectedEventIndexToChange], item.id);
                        this.$store.state.settings.addDayOnEvent = false;
                        this.$store.state.settings.eventNeedsSaving = this.events[this.$store.state.settings.selectedEventIndexToChange].eventidDB
                    //}
                //}
             // }
            } 
            //Remove day from event
            else if(dt2 > dt1){
            //else if(Math.floor((Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate()) - Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) ) /(1000 * 60 * 60 * 24)) < 0){
              //for (let index = 0; index < this.events.length; ++index) {
              //  if (this.events[index].eventidDB == this.$store.state.settings.selectedEventIDtoChangeDate)
              //  {
                  var dt_from = new Date (item.id);
                  var dt_to = new Date(this.events[this.$store.state.settings.selectedEventIndexToChange].dateto);
                   
                  if(dt_from <= dt_to){
                  //if(Math.floor((Date.UTC(dt_from.getFullYear(), dt_from.getMonth(), dt_from.getDate()) - Date.UTC(dt_to.getFullYear(), dt_to.getMonth(), dt_to.getDate()) ) /(1000 * 60 * 60 * 24)) < 1){
                      this.events[this.$store.state.settings.selectedEventIndexToChange].datefrom = item.id;
                      this.events[this.$store.state.settings.selectedEventIndexToChange].datefromJson = item.dateFormated_Json;
                      this.$store.state.settings.mouseDownEventStartDate = item.id;
                      this.calenderCore1.updatedateEvent(this.events[this.$store.state.settings.selectedEventIndexToChange], item.id);
                      this.$store.state.settings.eventNeedsSaving = this.events[this.$store.state.settings.selectedEventIndexToChange].eventidDB
                  } 
                //}
              //}
            }
          } 
      }
    
    },
    
    ChangeEventNameAndColor(Eventcolor, EventName){
      for (let index = 0; index < this.events.length; ++index) {
          if (this.events[index].id == this.$store.state.settings.currentEventId)
          {
            this.events[index].title = EventName
            this.events[index].color = Eventcolor
            this.events[index].datefrom = this.$store.state.settings.currentdatefrom
            this.events[index].dateto = this.$store.state.settings.currentdateto
            this.events[index].datefromJson = this.$store.state.settings.currentdatefromJson
            this.events[index].datetoJson = this.$store.state.settings.currentdatetoJson
          }
      } 
    },
    
    async saveChanges(Eventcolor, EventName, e){
    
      this.eventName = EventName
      var isValid = await this.v$.$validate();
    
      //if(this.$store.state.settings.eventColor == "#ebebeb"){
      //  isValid = false;
      //  toast.error("Bitte eine Farbe auswählen")
     // }
    
      if (isValid){
          let tempdocid = Math.floor(Math.random() * 100000000)
          if(this.$store.state.settings.SlotMode == "Edit"){
              this.ChangeEventNameAndColor(Eventcolor, EventName);
    
              if(this.$store.state.settings.Authenticated == true){
                let newEvent = {
                  userid: this.$store.state.user.userID,
                  eventidDB : this.$store.state.settings.currentEventIdDB,
                  color: this.$store.state.settings.currentCalendarColor, 
                  title: EventName,
                  datefrom: this.$store.state.settings.currentdatefrom,
                  dateto: this.$store.state.settings.currentdateto,
                  datefromJson: this.$store.state.settings.currentdatefromJson ,
                  datetoJson: this.$store.state.settings.currentdatetoJson    
                }
                this.$store.dispatch("updateEvent", newEvent);
              }
          }
          else{
            if(this.$store.state.settings.Authenticated == true){
              if(this.$store.state.settings.currentEventIdDB == "No Event"){
                let newEvent = {
                  calendarId: this.$store.state.settings.currentCalendarId,
                  color: this.$store.state.settings.currentCalendarColor, 
                  title: EventName,
                  datefrom: this.$store.state.settings.slectedDayId,
                  dateto: this.$store.state.settings.slectedDayId,
                  datefromJson: this.selected_dateFormatedJson ,
                  datetoJson: this.selected_dateFormatedJson    
                }
                const docid =  await this.$store.dispatch("createEvent", newEvent);
                tempdocid = docid.id
              }
            }
            this.events.push({
              calendarId: this.$store.state.settings.currentCalendarId,
              eventidDB : tempdocid,
              color: this.$store.state.settings.currentCalendarColor, 
              title: EventName,
              datefrom: this.$store.state.settings.slectedDayId,
              dateto: this.$store.state.settings.slectedDayId,
              datefromJson: this.selected_dateFormatedJson,
              datetoJson: this.selected_dateFormatedJson  
            })
          }
          if(this.selectedDay != null){
              this.selectedDay.cursor = "s-resize";
          } 
          this.calenderCore1.updateCalender();
          this.handleHide(e);
        }
        else{
          if(this.v$.eventName.required.$invalid){
            toast.error("Bitte einen Titel für das Ereignis eintragen")
          }
          else if(this.v$.eventName.maxLength.$invalid){
            toast.error("Der Eventname darf aus max. 25 Zeichen bestehen")
          }
        }
    },
    
    deleteEvent(){
      for (let index = 0; index < this.events.length; ++index){
        if (this.events[index].id == this.$store.state.settings.currentEventId){
            this.events.splice(index, 1); 
            this.calenderCore1.updateCalender();
            if(this.$store.state.settings.Authenticated == true){
              let eventToDelete = {
                userid: this.$store.state.user.userID,
                eventidDB : this.$store.state.settings.currentEventIdDB, 
              }
              this.$store.dispatch("deleteEvent", eventToDelete);
            }
        }
      }
      this.calenderCore1.updateCalender();
      this.handleHide();
    },
    
      updateEvents()
      {
          for (let index = 0; index < this.items.length; ++index){
            this.updateSingleDay(this.items[index]);
          }
      },
    
      updateSingleDay(day){
        var dayHasEvent = false;
        for (let index = 0; index < this.events.length; ++index) {
          if((day.id >= this.events[index].datefrom && day.id <= this.events[index].dateto))
          {
            day.backColor = this.events[index].color;
            day.eventText = this.events[index].title;
            dayHasEvent = true;
          }
        }
        if (dayHasEvent == false){
            day.backColor = day.backColorWeekDay;
            day.eventText = "";
            if(day.weekday == "So" || day.weekday == "Sa"){
                  day.backColor = this.$store.state.settings.BackColorWeekend;
                  day.dayHasEvent = true;
              }
              else{
                  day.backColor = this.$store.state.settings.BackColorNormalDay;              
              }
        }
      },
    
      GetSelectedEvent(eventID){
        for (let index = 0; index < this.events.length; ++index) {
            if (this.events[index].id == eventID)
            {
                return this.events[index];   
            }
        } 
      },
    
    
      SelectedDateHasEvent(selectedDate){
        for (let index = 0; index < this.events.length; ++index) {
          if (selectedDate >= this.events[index].datefrom && selectedDate <= this.events[index].dateto)
          {
            return this.events[index].id;
          }
        }
        return false;
      },
    
      TerminHasOnlyOneDay(event){
        
        var dt1 = new Date(event.datefrom);
        var dt2 = new Date(event.dateto);
    
        if(Math.floor((Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate()) - Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) ) /(1000 * 60 * 60 * 24)) == 0){
          return true;
        }
        else{
          return false;
        }
       
      },
    
      IsFirstDayOfEvent(event, selectedDate){
      
        if(event.datefrom == selectedDate){
              return true;
            }else{
              return false;
            }
        
      },
    
      IsLastDayOfEvent(event, selectedDate){
    
        if(event.dateto == selectedDate){
              return true;
            }else{
              return false;
            }
        
      },
    
      DayDifference(day1, day2){
        var dt1 = new Date(day1);
        var dt2 = new Date(day2);
        return Math.floor((Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate()) - Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) ) /(1000 * 60 * 60 * 24));
      },
    
      //IsLastEventDateWithinNextEvent(eventID, currentItem){
    
        //return false; // We have now more events on one day
        
        // Find selected event
        /*for (let index1 = 0; index1 < this.events.length; ++index1) {
            if (this.events[index1].id == eventID)
            {
              // Get StartDate and EndDate from selected event
              var dt0 = new Date(this.events[index1].datefrom);
              var dt1 = new Date(currentItem.id);
    
              // Run again through all events
              for (let index = 0; index < this.events.length; ++index){
    
                  // only process the other events and not the selected one
                  if(eventID != this.events[index].id){
    
                      // get DateFrom for the current Event
                      var dt2 = new Date(this.events[index].datefrom);
    
                      // Is DateFrom of the selected event before the DateFrom of the current event
                      
                      if(this.DayDifference(dt0, dt2) < 0){
                          if(this.DayDifference(dt1, dt2) > -1){
                              return true;
                          }
                      }
                  }
              }
              return false;
          }
        }*/
      //},
      
      IsFirstEventDateWithinPreviousEvent(eventID,currentItem){
        
        // Find selected event
        for (let index1 = 0; index1 < this.events.length; ++index1) {
            if (this.events[index1].id == eventID)
            {
              // Get StartDate and EndDate from selected event
              var dt0 = new Date(currentItem.id);
              var dt1 = new Date(this.events[index1].dateto);
    
              // Run again through all events
              for (let index = 0; index < this.events.length; ++index){
    
                  // only process the other events and not the selected one
                  if(eventID != this.events[index].id){
    
                      // get DateFrom for the current Event
                      var dt2 = new Date(this.events[index].dateto);
    
                      // Is DateFrom of the selected event before the DateFrom of the current event
                      if(this.DayDifference(dt0, dt2) < 1){
                          if(this.DayDifference(dt1, dt2) > -1){
                              return true;
                          }
                      }
                  }
              }
              return false;
          }
        }
      },
    
      closePopup(){
        this.toggle.value = false;
      },
    
      changeEventColor(selectedColor){
        this.$store.state.settings.eventColor = selectedColor;
      }
    },
    
    validations () {
      return {
        eventName: { 
          required,
          maxLength: maxLength(25), 
        }
      }
    },
    
    setup() {
    
      
      const windowHeight = ref(window.innerHeight)
      const toggle = ref(false)
      const store = useStore();
      const { state } = store;
      const calenderCore1 = calenderCore();      
      const isAuthenticated = computed(() => store.getters["user/isAuthenticated"]);
      const el = ref(null)
      const { width, height } = useElementSize(el)
      
      const handleWeekDayClick = () => {
      }
    
      const dayHight = ref(windowHeight.value/35)
    
    
    
    
      //const dayHight = computed({
      //  get() {
      //    return windowHeight.value/35
      //  },
      //})
      
    
      const startDrag = (e, myCal, itemId) =>{
        state.settings.isdragging = true
        
        console.log("Start Dragging: " + myCal.info.calendarId + " - " + itemId)
         
        //console.log("TESt: " + divs[i])//.style.backgroundColor = "red"
        
        //followerDiv.value["Q0mLUrp8na9KoNDYvoBr"].style.color = "red"
    
        //refs.Q0mLUrp8na9KoNDYvoBr.style.backgroundColor = "red"
        state.settings.arrowsVisible = false
    
        //state.settings.currentDraggingEventIndex = currentEvent.index
        
        //document.querySelector("Q0mLUrp8na9KoNDYvoBr").opacity = '0.4';
        state.settings.isdragging = true;
        state.settings.selectedCursor = "alias";
        e.dataTransfer.dropEffect = 'move';
        e.dataTransfer.effectAllowed = 'move';
        //e.dataTransfer.setData('itemID', item.id);
        e.dataTransfer.effectAllowed = true;
        
        
        //var img = document.createElement("img");
        //img.src = "http://kryogenix.org/images/hackergotchi-simpler.png";
        //event.dataTransfer.setDragImage(img, 0, 0);
        
      }



 
    
     const getMonthName = (month) =>{
      
      switch(month) {
      case "01":
        return "Januar"
      case "02":
        return "February"
      case "03":
        return "March"
      case "04":
        return "April"
      case "05":
        return "May"
      case "06":
        return "June"
      case "07":
        return "July"
      case "08":
        return "August"
      case "09":
        return "Sepetember"
      case 10:
        return "October"
      case 11:
        return "November"
      case 12:
        return "December"
     
    }
     }

    
    const onDrop_bkp = (event, item, events) => {
    
      state.settings.ctrlPressed = false;
      state.settings.ismousedown = false;
      state.settings.selectedCursor = "default";
      
      let date_1 = new Date(events[state.settings.currentDraggingEventIndex].dateto);
      let date_2 = new Date(events[state.settings.currentDraggingEventIndex].datefrom);
      let difference = date_1.getTime() - date_2.getTime();
      let TotalDays = Math.ceil(difference / (1000 * 3600 * 24) );
      const newStartDate = new Date(item.id);
      const newEndDate = new Date(item.id);
      newEndDate.setDate(newEndDate.getDate() + TotalDays)
      //let correctEndDate = new Date(item.id)
    
      let newYearFrom = newStartDate.getYear() + 1900
      let newMonthFrom = newStartDate.getMonth() + 1
      let newDayFrom = newStartDate.getDate()
    
      let newYearTo = newEndDate.getYear() + 1900
      let newMonthTo = newEndDate.getMonth() + 1
      let newDayTo = newEndDate.getDate()
    
      if(newMonthFrom < 10) newMonthFrom = "0"+newMonthFrom;
      if(newDayFrom < 10) newDayFrom = "0"+newDayFrom;
      if(newMonthTo < 10) newMonthTo = "0"+newMonthTo;
      if(newDayTo < 10) newDayTo = "0"+newDayTo;
    
      //let insert = true;
    
      /*for (var d = newStartDate; d <= newEndDate; d.setDate(d.getDate() + 1)) {
        if(insert == false){
          break;
        }
        else{
          newYearTo = d.getYear() + 1900
          newMonthTo = d.getMonth() + 1
          newDayTo = d.getDate()
          if(newMonthTo < 10) newMonthTo = "0"+newMonthTo;
          if(newDayTo < 10) newDayTo = "0"+newDayTo;
    
          for (let index3 = 0; index3 < events.length; ++index3){
            if(insert == true){
              if(events[state.settings.currentDraggingEventIndex].id != events[index3].id){
                if(((newYearTo + "-" + newMonthTo + "-" + newDayTo) == events[index3].datefrom)){
                insert = false;
                d.setDate(d.getDate() - 1)
                correctEndDate = d
              }
              }
              else{
                correctEndDate = d
              }
            }
          }
        }
      }*/
      
      //correctEndDate.setDate(correctEndDate.getDate() - 1)
    
      //newYearTo = correctEndDate.getYear() + 1900
      //newMonthTo = correctEndDate.getMonth() + 1
      //newDayTo = correctEndDate.getDate()
    
      //if(newMonthTo < 10) newMonthTo = "0"+newMonthTo;
      //if(newDayTo < 10) newDayTo = "0"+newDayTo;
    
    
      events[state.settings.currentDraggingEventIndex].datefrom =  newYearFrom + "-" + newMonthFrom + "-" + newDayFrom,
      events[state.settings.currentDraggingEventIndex].dateto = newYearTo + "-" + newMonthTo + "-" + newDayTo,
      events[state.settings.currentDraggingEventIndex].datefromJson = getMonthName(newMonthFrom) + " " + newDayFrom + ", " + newYearFrom + " 12:00:00 UTC" ,
      events[state.settings.currentDraggingEventIndex].datetoJson = getMonthName(newMonthTo) + " " + newDayTo + ", " + newYearTo + " 12:00:00 UTC"      
    
    
      //------------------- SAVE EVENT CHANGES IN FIRESTORE DATABASE START -----------------------------------------
    
      if(isAuthenticated.value == true){
        let newEvent = {
          calendarId: events[state.settings.currentDraggingEventIndex].calendarId,
          userid: state.user.userID,
          eventidDB : events[state.settings.currentDraggingEventIndex].eventidDB,
          color: events[state.settings.currentDraggingEventIndex].color, 
          title: events[state.settings.currentDraggingEventIndex].title,
          datefrom: newYearFrom + "-" + newMonthFrom + "-" + newDayFrom,
          dateto: newYearTo + "-" + newMonthTo + "-" + newDayTo,
          datefromJson: getMonthName(newMonthFrom) + " " + newDayFrom + ", " + newYearFrom + " 12:00:00 UTC" ,
          datetoJson: getMonthName(newMonthTo) + " " + newDayTo + ", " + newYearTo + " 12:00:00 UTC",
          fromHour: events[state.settings.currentDraggingEventIndex].fromHour,
          fromMinute: events[state.settings.currentDraggingEventIndex].fromMinute,
          toHour: events[state.settings.currentDraggingEventIndex].toHour,
          toMinute: events[state.settings.currentDraggingEventIndex].toMinute,
          isFullDay: events[state.settings.currentDraggingEventIndex].isFullDay,
          additionalInfos: events[state.settings.currentDraggingEventIndex].additionalInfos,
          rRules: events[state.settings.currentDraggingEventIndex].rRules,  
          removedDays: []    
        }
        store.dispatch("updateStoredEvent", newEvent);
        
      }
    
      //------------------- SAVE EVENT CHANGES IN FIRESTORE DATABASE END -----------------------------------------
    
      calenderCore1.updateCalender();
    
    }
      
      return {
        toggle,
        startDrag,
        calenderCore1,
        handleWeekDayClick,
        el,
        width,
        height,
        windowHeight,
        dayHight,
        onDrop_bkp,
        
        
      }
    } 
    })
    </script>
    
<style scoped>


.cell-svg {
  position:absolute;
  right: 4px; /* Rechtsbündig in der Zelle */
  top: 50%;
  transform: translateY(-50%); /* Vertikal zentriert */
  z-index: 1;
  pointer-events: none;
}

    
.container {
  display: flex;
  align-items: start;   
  position: relative;
  
}

@media (min-height: 900px) {
  .weekday-main-div {
    position: relative;
    display:flex; 
    background-color: var(--calendar-background-color);
    border-top-left-radius: 2px;  /* obere linke Ecke */
    border-bottom-left-radius: 2px;  /* untere linke Ecke */
    height: 2.79vh;
    min-height: 20px;
    width: 45px;
    margin-right: 2px; 
    margin-bottom: 2px;
    justify-content:flex-start;
    transition: background-color 0.01s ease-in-out 0.05s; /* 0.5s delay */
    overflow: hidden; /* Damit der Inhalt beim Hover nicht sichtbar ist */
  }
}
    
@media (max-height: 900px) {
  .weekday-main-div {
    position: relative;
    display:flex; 
    background-color: var(--calendar-background-color);
    border-top-left-radius: 2px;  /* obere linke Ecke */
    border-bottom-left-radius: 2px;  /* untere linke Ecke */
    height: 2.69vh;
    min-height: 20px;
    width: 45px;
    margin-right: 2px; 
    margin-bottom: 2px;
    justify-content:flex-start;
    transition: background-color 0.01s ease-in-out 0.05s; /* 0.5s delay */
    overflow: hidden; /* Damit der Inhalt beim Hover nicht sichtbar ist */
  }
}


@media (min-height: 900px) {
  .right-div {
    width: 100%;
    height: 2.79vh;
    min-height: 20px;
    background-color: var(--calendar-background-color);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2px;
    font-size: 10px; /* Schriftgröße angepasst */
  border-top-right-radius: 2px; /*   obere rechte Ecke */
  border-bottom-right-radius: 2px;  /* untere rechte Ecke */

    margin-right: 6px;
    justify-content: flex-start;
  }
}
@media (max-height: 900px){
  .right-div {
    width: 100%;
    height: 2.69vh;
    min-height: 20px;
    background-color: var(--calendar-background-color);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2px;
    font-size: 10px; /* Schriftgröße angepasst */
    border-top-right-radius: 2px; /*   obere rechte Ecke */
    border-bottom-right-radius: 2px;  /* untere rechte Ecke */
    margin-right: 6px;
    justify-content: flex-start;
  
  }
}

    
.weekday-left {
  color: #191816;
  font-size: 10px;
  font-weight: 700;
  align-items: left;
  justify-content: left;
  padding-top: 2px;
  padding-left: 3px;
  padding-right: 1px;
  line-height: 1; /* Zeilenhöhe angepasst */
  padding-top: 3px; /* Padding entfernt */
  transition: opacity 0.3s ease; /* Weicher Übergang für die Sichtbarkeit */
  letter-spacing: -0.6px;
  height: 100%;
  min-width: 5px;
}

.weekday-right {
  padding-top: 2px;
  color: #191816;
  height: 100%;
  align-items: left;
  justify-content: left;
  font-size: 10px;
  font-weight:100;
  line-height: 1; /* Zeilenhöhe angepasst */
  padding-top: 3px; /* Padding entfernt */
  transition: opacity 0.3s ease; /* Weicher Übergang für die Sichtbarkeit */
  letter-spacing: -0.2px;
}
    
    .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0; /* Icon initial unsichtbar */
    transition: opacity 0.3s ease; /* Weicher Übergang für die Sichtbarkeit */
    color: white; /* Icon weiß */
    }
    
    .weekday-main-div:hover {
    background-color: #191816 !important;
    cursor: pointer !important;
    }
    
    .weekday-main-div:hover .weekday-left,
    .weekday-main-div:hover .weekday-right {
    opacity: 0; /* Text beim Hover unsichtbar */
    }
    
    .weekday-main-div:hover .icon {
    opacity: 1; /* Icon beim Hover sichtbar */
    }
    
    .child-div {
    flex: 1;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0px;
    
    }
    
    .plus-icon {
      display: none;
    }
    
    .child-div:hover .plus-icon {
      display: block;
      cursor: pointer;
      font-weight: 100;
      color: 191816; 
    }
    
    .event-box {
      position: relative; /* Kommt von dem Dragging Vorschlag von ChatGpt */
    display: flex;
    width: 100%; /* 100% der Hauptdiv-Breite */
    height: 100%;

    cursor: pointer;
    }
    
    .child-div {
      position: relative; /* Ermöglicht die absolute Positionierung der Boxen */
    }
    
    .floating-box:hover{
      cursor: grabbing;
    }
    
    .floating-box {
      position: absolute; /* Platziert die Boxen über bzw. unter dem .child-div */
      width: 100%; /* Die Boxen sind genau so breit wie das .child-div */
      height: 15px; /* Höhe der Boxen */
      background-color: #191816; /* Schwarze Füllfarbe */
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: grabbing; /* Zeigt beim Hover eine Hand als Cursor an */
      color: rgb(255, 255, 255);
    }
    
    /* Obere Box */
    .top-box {
      top: -15px; /* Verschiebt die Box vollständig nach oben */
      border-top-left-radius: 4px; /* Runde die obere linke Ecke */
      border-top-right-radius: 4px; /* Runde die obere rechte Ecke */
    }
    
    /* Untere Box */
  .bottom-box {
      bottom: -15px; /* Verschiebt die Box vollständig nach unten */
      border-bottom-left-radius: 4px; /* Runde die untere linke Ecke */
      border-bottom-right-radius: 4px; /* Runde die untere rechte Ecke */
      z-index: 99;
    }
  .bottom-box:hover{
    cursor: grabbing;
    }
    
  .drag-text {
    z-index: 9999;
    position: absolute;
    background-color: transparent;
    padding: 5px;
    pointer-events: none; /* verhindert, dass es die Mausinteraktionen stört */
  }

  .draggable {

  user-select: none;
  
}

.circle {
  
width: 18px;
height: 18px;
border-radius: 50%;

}

.month-year{
  display: flex;
  align-items: end;

}

.month-year-left{
  margin-bottom: 3px;
  color: black;
  height: 20px;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: -0.6px;
}

.month-year-right{
  padding-left: 3px;
  margin-bottom: 4.3px;
  padding-right: 10px;
  font-size: 9px;
  font-weight: 500;

}




    
    </style>
    
    
    
    