<!-- AnnucalCheckbox.vue -->
<template>
  <label class="custom-checkbox-container">
    <input
      type="checkbox"
      class="custom-checkbox"
      v-model="currentValue"
    />
    <span class="custom-checkbox-mark">
      <PhCheck  class="checkmark" />
    </span>
    <slot></slot>
  </label>
</template>

<script setup>
import { computed } from 'vue';
import { defineProps, defineEmits } from 'vue';

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(['update:modelValue']);

const currentValue = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  }
});
</script>

<style scoped>
.custom-checkbox-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;
}

.custom-checkbox {
  display: none;
}

.custom-checkbox-mark {
  position: relative;
  width: 16px;
  height: 16px;
  background-color: white;
  border: 1px solid #A39F96;
  border-radius: 5px; /* Abgerundete Ecken */
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.custom-checkbox:checked + .custom-checkbox-mark {
  background-color: #6278E9; /* Hintergrundfarbe im ausgewählten Zustand */
  border: 2px solid #6278E9;
}

.checkmark {
  color: white; /* Farbe des Häkchens */
  font-size: 12px;
}
</style>
