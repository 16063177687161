<template>
  <div :class="this.$store.state.settings.currentTheme + '-theme'">
    <div class="grid-padding">
        <div class="grid-container" >
          <!--------------------------------- EACH MONTH LOOP ---------------------------------->
          <div v-for ="(monthitem, index) in items" v-bind:key="index" > 
            <div v-if="index < 12">
              <!--------------------------------- YEAR HEADLINE ----------------------------------->
              <div v-for ="(item, index2) in monthitem" v-bind:key="index2"> 
                <PopoverCalenderday  :item=item  />
              </div>
            </div>
            <!-------------------------------- EACH DAY LOOP --------------------------------->
            <div v-if="index > 11">
              <div v-for ="(item, index2) in monthitem" v-bind:key="index2">     
                <PopoverCalenderday :item=item  />
              </div>
            </div>
          </div>
      </div> 
    </div>
  </div>
</template>
  
<script>
  
  import PopoverCalenderday from "../components/PopoverCalenderday";
  import calenderCore from "../composition/calenderCore";
  import { useStore } from "vuex";
  import { computed } from "vue";
  import { v4 as uuidv4 } from 'uuid';
  
  export default{
  
    components:{
      PopoverCalenderday,
    },
  
    data(){
      return {
        insertEventisOpen: false,
        deleteEventisOpen: false,
        selectedDate: "",
        selectedEvent: "",
        deleteBackgroundColor: "#00ff00",
        calenderCore1 : calenderCore(),
        uniqueId: uuidv4()
        
      }
    },
  
   
    computed:{
  
        items(){
          
              return this.$store.state.calender.items;
          },
        events(){
              return this.$store.state.events.items;
          }
        },
  
        async created(){
          this.$store.dispatch("getCalender");
          this.$store.dispatch("getTimezones");
          this.$store.dispatch("getPublicHolidays"); 
          this.$store.dispatch("getShoolholidays"); 
          this.$store.dispatch("getContent"); 
            
          const calenderCore1 = calenderCore();
          calenderCore1.updateCalender();
  
          const store = useStore();
          const isAuthenticated = computed(() => store.getters["user/isAuthenticated"]);
          
  
          //--------------------------------------------------------------------------------------------//
          //-------------------------------- If is authenticated ---------------------------------------//
          //--------------------------------------------------------------------------------------------//
          console.log("IsAuth: " + isAuthenticated.value)
          if(isAuthenticated.value === true){
            this.$store.state.settings.Authenticated = true
            if(this.$store.state.settings.fetchedDataFromDb == false){
              await this.$store.dispatch("getmyCalendarsFromDB");
              await this.$store.dispatch("getmyEventsFromDB",this.$store.state.user.userID);
              await this.$store.dispatch("refreshIndexList");
              await this.$store.dispatch("getavailableCalendarsFromDB", this.$store.state.user.userID);
              await store.dispatch('getPublicHolidaysFromApi', { userId: store.state.user.userID, localCalendars: this.$store.state.storedmycalendars.items });
              this.$store.state.mycalendars.items = this.$store.state.storedmycalendars.items
              this.$store.state.availablecalendars.items = this.$store.state.storedavailablecalendars.items
              this.$store.state.settings.fetchedDataFromDb = true;
              this.$store.state.events.items = this.$store.state.storedevents.items
              await this.$store.dispatch("getmydaysWithEventsFromDB", {
                    events: this.$store.state.events.items, 
                    myCalendars: this.$store.state.mycalendars.items, 
                    calender: this.$store.state.calender.items});
              calenderCore1.updateCalender();
              this.$store.state.settings.startPageOpen = false; 
            }
          }
      
          //--------------------------------------------------------------------------------------------//
          //-------------------------------- If is NOT authenticated -----------------------------------//
          //--------------------------------------------------------------------------------------------//
  
          else{
            await this.$store.dispatch("initEventItems");
            this.$store.state.events.items = this.$store.state.storedevents.items
            this.addFirstCalendarIfNotSignedIn()
            calenderCore1.updateCalender();
            this.$store.state.settings.startPageOpen = false; 
          }
  
        },
  
         async mounted() {
          window.addEventListener('resize', this.getDimensions);
          document.addEventListener( "keydown", this.onKeydown );
          document.addEventListener( "keyup", this.onKeyup );
        },
  
        unmounted() {
          window.removeEventListener('resize', this.getDimensions);
        },
  
        methods: {
  
          addFirstCalendarIfNotSignedIn(){
            const id = uuidv4()
            let newCalendar = {
              color: '#6AD069', //calendarColor.value, 
              icon: 'ph-CalendarCheck', //icon.value,
              name: 'Privat',
              externalCalendarLink: '',//externalCalendarLink.value,
              description: '',
              visible: true,
              private: true,
              creatorUID: 'notSignedIn',
              creatorName: "Annucal",
              calendarImage:  "",
              type: '1',
              specialday:'',
              country: '',
              subdivision: '',
              subdivisionCode: '',
              loadedPublicHolidays: false,
              calendarId:id,
              docId:id,
              events:[]
          }
    
          this.$store.state.mycalendars.items.push(newCalendar)
          
          this.calenderCore1.updateCalender();
  
          },
          
  
          getDimensions() {
            this.$store.dispatch("getCalender"); 
          },
  
          onKeyup (e) {
            if(e.key == 'd'){
            this.$store.state.settings.ctrlPressed = false;
            this.$store.state.settings.selectedCursor = 'default'
            
          }
          },
  
          onKeydown  (e) {
          if(e.key == 'd'){
            this.$store.state.settings.ctrlPressed = true;
            this.$store.state.settings.selectedCursor = 'grab'
            
          }
        }      
      }
    }
  
  </script>
  
  <style scoped>
  
  .fab {
    position: fixed;
    bottom: 16px;
    right: 16px;
    z-index: 2000;
  }
  
  .vl {
    border-left: 1px solid rgb(96, 96, 96);
    height: 36px;
  }
    .mycontainer {
    display: Grid;
    flex-direction:column;
    flex-wrap: wrap;
    max-height: 120vh;
      background-color: #C6C3BB;
  }
  .item {
    margin: 0.5rem;
    height: 7px;
  }
  
  @media (min-width: 100px){
    .grid-container2 {
      display: grid;
      grid-auto-flow: column;
      padding-left: 0px;
      grid-template-columns: repeat(24, 25%); 
      overflow-x: scroll;
      overscroll-behavior-inline:none;
      overscroll-behavior-block: contain;
      background-color: var(--calendar-background-color);
    }
  }
  
  
  
  @media (min-width: 800px){
    .grid-container2 {
      display: grid;
      grid-auto-flow: column;
      padding-left: 0px;
      grid-template-columns: repeat(24, 12%); 
      overflow-x: scroll;
      overscroll-behavior-inline: contain;
      align-content: stretch;
      background-color: var(--calendar-background-color);
      
    }
  }
  
  @media (min-width: 1200px){
    .grid-container2 {
      display: grid;
      grid-auto-flow: column;
      padding-left: 0px;
      grid-template-columns: repeat(24, 8.4%); 
      overflow-x: scroll;
      overscroll-behavior-inline: contain;
      align-content: stretch;
      background-color: var(--calendar-background-color);
      height: 1200px;
    }
  }
  
  
  .grid-container {
    display: grid;
    grid-auto-flow: column;
    padding-left: 0px;
    grid-template-columns: repeat(24, minmax(110px, 8.4%));
    overflow-x: scroll;
    overscroll-behavior-inline: contain;
    align-content: stretch;
    background-color: var(--calendar-background-color);
    height: 100%;
  }
  
   
  .day-grid-container{
    display: grid;
    
    
  }
  
  day-grid-container:hover{
  
  }
  
  .day-item {
    border-radius: 0px;
    cursor: pointer;
    border: 0px solid rgba(210, 202, 202, 0.802);
    background-color: rgba(255, 255, 255, 0.758);
    padding-top: 5px;
    color:#434343;
    font-size: medium;
    font-weight:300;
    text-align: center;
    user-select: none;
  }
  
  .grid-padding{
    padding-left: 10px;
    padding-right: 10px;
    
    padding-top: 0px;
    user-select: none;
    background-color: var(--calendar-background-color);
   
    
  }
  
  .top-grid-item-year {
    padding-top: 14px;
    font-size: 28px;
    font-weight:900;
    text-align: center;
    vertical-align:top;
    user-select: none;
  }
  
  .the-sliding-grid{
      height: 100vh;
      display: grid;
      place-items: center;
      background: #222;
    }
  
    .slider-container{
      height: 500px;
      width: 800px; 
      display: flex;
      overflow: auto;
      scroll-snap-type: x mandatory;
    }
  
    .slider-container-slide{
      scroll-snap-align: start;
    }
  
    .slider-container-img{
      width: 800px;
      height: 100%;
      object-fit: cover;
    }
  
    .modal-overlay {
    display: block; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }
  
  </style>