<template>
<div :class="$store.state.settings.currentTheme + '-theme'">

    <v-card class="card-style">
      
    <v-dialog v-model="menuschool" :close-on-content-click="false" :openOnHover="false"  location="end">
                  
        <template v-slot:activator="{ props }">
          <div 
            v-bind="props" 
            @click="openMenuSchool"
            class="row1"
          >
            <div v-if="showLeftIcon" class="left-icon-back">
                <PhConfetti class="left-icon"/>
            </div>
            <div class="center-column">
              {{calendar.specialday + " " + calendar.country + " - " + calendar.subdivision}}
            </div>
            <div v-if="showRightIcon" class="right-icon-back">
                <div class="right-icon-container">
                <PhGearSix class="right-icon" />
                </div>
            </div>
        </div>
        </template>

        <annucalHolidays
          @close-menu="closemenu"
          :headline = headline
          :calenderType="props.calenderType"
          prop_mode="edit"
          :prop_calendarId = "props.calendarId"
          :prop_docId = "props.docId"
          :calendarInfo = "props.calendarInfo"
          :allowDelete = true
        />

      </v-dialog>

  </v-card>

</div>

</template>

<script setup>

import { ref, defineProps, onMounted, computed } from 'vue';
import annucalHolidays from './AnnucalHolidays.vue'
import {useStore} from "vuex";
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const schoolholidays = ref(t('schoolholidays'))
const publicholidays = ref(t('publicholidays'))
const headline = ref('')

const store = useStore();

const props = defineProps({
    docId: {
    type: String,
    required: true
  },
  calendarId:{
    type: String,
    required: true
  },
  name: {
    type: String,
    required: true
  },
  calendarColor: {
    type: String,
    default: '#DAFDD8' // Default value if not provided
  },
  calendarInfo:{
    type: Object,
    required: true
  },
  calenderType:{
    type: String,
    required: true
  }

});

onMounted(() => {
  store.state.settings.Log_Status = ""
  store.state.settings.AnnucalSaveText = "Speichern"
  store.state.settings.AnnucalVcardHeadline = "Kalender bearbeiten"
  store.state.settings.normlaErrorMessage = ""
  
  headline.value = props.calenderType === "2" ? publicholidays.value : schoolholidays.value
});

const calendar = computed(() => store.state.mycalendars.items.find(calendar => calendar.calendarId === props.calendarId))

const menuPulic = ref(false);

function closemenu(){
    menuPulic.value = false
    menuschool.value = false
}

//const menuPulic = ref(false);
const menuschool = ref(false);

//function openMenuPublic(){
//    menuPulic.value = true
////}

function openMenuSchool(){
    menuschool.value = true
}

const showLeftIcon = ref(true);
const showRightIcon = ref(true);


</script>

<style scoped>


.annicual-button-grid {
  display: grid;
  grid-template-rows: auto; /* Automatische Höhe für jede Row */
  grid-auto-columns: auto; /* Automatische Breite für Spalten */
  
}

.row1 {
  display: grid; /* Jede Row bleibt ein Grid */
  grid-template-columns: 30px auto 30px; /* Spaltenaufteilung */
  align-items: center; /* Zentrierung vertikal */
  margin-bottom: 2px;
  padding:8px;
  background-color: white; /* Standardhintergrundfarbe */
  border-radius: 4px; /* Optional: Abgerundete Ecken */
}

.row2 {
  display: grid; /* Jede Row bleibt ein Grid */
  grid-template-columns: 30px auto 30px; /* Spaltenaufteilung */
  align-items: center; /* Zentrierung vertikal */
  background-color: white; /* Standardhintergrundfarbe */
  padding:8px;
  border-radius: 4px; /* Optional: Abgerundete Ecken */
}

.row1:hover,
.row2:hover {
  cursor: pointer; /* Zeigt den Zeiger an */
  background-color: #e6e5e55a; /* Hover-Hintergrundfarbe */
}
  
  .left-icon {
    width: 16px;
    height: 16px;
  }
  
  .left-icon-back {
    
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .right-icon-back {
    display: flex;
    justify-self: end; /* Horizontal nach rechts ausgerichtet */
    align-items: center;
  }
  
  .right-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #E7E5DF;
    border-radius: 4px; /* Runde Form */
    width: 16px; /* Größe des grauen Quadrats */
    height: 16px;
    margin-right: 4px;
  }
  
  .right-icon {
    width: 12.8px;
    height: 12.8px;
  }
  
  .center-column {
    text-align: left;
    font-size: 12px;
    font-weight: 400;
    color: var(--standard-text-color);
    letter-spacing: -0.6px;
    
  }

  .card-style{
    border-radius: 8px;
        margin-top: -15px;
        box-shadow: 0 0 32px rgba(0, 0, 0, 0.2);
        padding: 8px;
        margin-left: 3px;
  }

</style>