<template>

  <div class="main-div">

  <!-------------------------------------------------------------------------------------------->
  <!----------------------------------  ADD NEW PUBLIC HOLIDAY --------------------------------->
  <!-------------------------------------------------------------------------------------------->

      <div v-if="showDeleteButton == false">
        <AnnucalVCard 
          :calendarName=headline
          saveText = "savecalendar"
          :showCalendarName = true
          :showvCardActions = true
          :VcardWitdh = '440'
          :prop_mode="props.prop_mode"
          @unsubscribeMain="unsubscribeMain"
          @deleteElement="deleteElement"
          @saveEdit="saveEdit"
          @savenew="savenew"
          @close-menu="closeMenu"
          style="
              margin-left:12px;
              margin-right:8px;
              margin-top:82px;
          "
    >
      <template v-slot:middle>
  
        <div class="first-row"></div>

      
  

        <!-------------------------------------------------------------------------------------------->
        <!----------------------------------  Country Selection -------------------------------------->
        <!-------------------------------------------------------------------------------------------->
        <div class="second-row"></div>
  
        <!----------------------------------  Country Name Input ------------------------------------->
        <AnnucalTextfieldVue
                          labeltext="Suche ein Land"
                          v-model="selectedCountryName" 
                          :label = entercountry
                          @input="filterCountries"
                          showCounter="false"
                      />
     
        <!---------------------------------  Found Country Names List -------------------------------->
        <ul v-if="filteredCountries.length" class="custom-list">
          <li
            v-for="country in filteredCountries.slice(0, 5)"
            :key="country.CountryCode"
            @click="selectCountry(country)"
            class="custom-list-item"
          >
            {{ country.Country }}
          </li>
        </ul>
    

        <!-------------------------------------  Subdivision Name ------------------------------------>
        <!-- Suche nach Unterteilungen, immer aktiv -->
        <div class="third-row"></div>
  
        <AnnucalTextfieldVue
          v-if="selectedCountry"
            labeltext="Suche eine Unterteilung"
            v-model="selectedSubdivisionName" 
            label = "Bundesland eingeben"
            @input="filterSubdivisions"
            showCounter="false"
        />
  
       <!-- Liste der gefilterten Unterteilungen (ohne Vuetify) -->
       <ul v-if="filteredSubdivisions.length && selectedCountry" class="custom-list">
        <li
          v-for="subdivision in filteredSubdivisions.slice(0,5)"
          :key="subdivision.SC"
          @click="selectSubdivision(subdivision)"
          class="custom-list-item"
        >
          {{ subdivision.SN }}
        </li>
      </ul>
    
        <!-- Anzeige des ausgewählten Landes und der Unterteilung -->
       <!-- <div v-if="selectedCountry || selectedSubdivision">
          <p>
            Ausgewähltes Land: <strong>{{ selectedCountryName }}</strong>
            <span v-if="selectedSubdivision"> - Unterteilung: {{ selectedSubdivision.SN }} ({{ selectedSubdivision.SC }})</span>
          </p>
        </div>-->
  
      
      </template>
  
    </AnnucalVCard> 
      </div>
      
  <!-------------------------------------------------------------------------------------------->
  <!---------------------------------EDIT/DELETE PUBLIC HOLIDAY -------------------------------->
  <!-------------------------------------------------------------------------------------------->

    <div v-if="showDeleteButton == true">

      <AnnucalVCard 
        showDeleteButton = true
        saveText = "savecalendar"
        :showCalendarName = true
        :showvCardActions = true
        :VcardWitdh = '440'
        :prop_mode="props.prop_mode"
        @unsubscribeMain="unsubscribeMain"
        @deleteElement="deleteElement"
        @saveEdit="saveEdit"
        @savenew="savenew"
        @close-menu="closeMenu"
        :calendarName=props.headline
        style="
            margin-left:12px;
            margin-right:8px;
            margin-top:82px;
        "
    >
      <template v-slot:middle>
  
          <div class="first-row"></div>
  <!---------------------------------    Radio Buttons ----------------------------------->
          <div>
            <label class="radio country-name">
              <input
                type="radio"
                name="answer"
                value="Feiertage"
                v-model="selectedSpecialday"
                @change="changeselectedSpecialday('Feiertage')"
              />
              &nbsp; {{publicHolidays}}
        </label>
      </div>
    <!---------------------------------    Country Name ----------------------------------->
    <div>
      <label class="radio country-name">
        <input
          type="radio"
          name="answer"
          value="Schulferien"
          v-model="selectedSpecialday"
          @change="changeselectedSpecialday('Schulferien')"
        />
        &nbsp; {{shoolHolidays}}
      </label>
    </div>
  
    <div class="second-row"></div>

      <AnnucalTextfieldVue
          labeltext="Suche ein Land"
          v-model="selectedCountryName" 
          :label = entercountry
          @input="filterCountries"
          showCounter="false"
      />

      <!-- Liste der gefilterten Länder (ohne Vuetify) -->
      <ul v-if="filteredCountries.length" class="custom-list">
        <li
          v-for="country in filteredCountries.slice(0, 5)"
          :key="country.CountryCode"
          @click="selectCountry(country)"
          class="custom-list-item"
        >
          {{ country.Country }}
        </li>
      </ul>
    
        <!-- Suche nach Unterteilungen, immer aktiv -->
        <div class="third-row"></div>

        <!---------------------------------    Subdivision Name ----------------------------------->
  
        <AnnucalTextfieldVue v-if="selectedCountry"
            labeltext="Suche eine Unterteilung"
            v-model="selectedSubdivisionName" 
            label = "Ort eingeben"
            @input="filterSubdivisions"
            showCounter="false"
        />
  
       <ul v-if="filteredSubdivisions.length && selectedCountry" class="custom-list">
        <li
          v-for="subdivision in filteredSubdivisions.slice(0,5)"
          :key="subdivision.SC"
          @click="selectSubdivision(subdivision)"
          class="custom-list-item"
        >
          {{ subdivision.SN }}
        </li>
      </ul>
    
        <!-- Anzeige des ausgewählten Landes und der Unterteilung -->
       <!-- <div v-if="selectedCountry || selectedSubdivision">
          <p>
            Ausgewähltes Land: <strong>{{ selectedCountryName }}</strong>
            <span v-if="selectedSubdivision"> - Unterteilung: {{ selectedSubdivision.SN }} ({{ selectedSubdivision.SC }})</span>
          </p>
        </div>-->
  
      
      </template>
  
    </AnnucalVCard> 
      </div>
    
  
  </div>
    
  </template>
    
  <script setup>
  
    import { ref, defineEmits, onMounted, defineProps, computed } from 'vue';
    import AnnucalVCard from "./AnnucalVcard.vue"
    import AnnucalTextfieldVue from "./AnnucalTextfield.vue"
    import {useStore} from "vuex";
    import calenderCore from "../composition/calenderCore";
    import { v4 as uuidv4 } from 'uuid';
    import { useI18n } from 'vue-i18n'

    const { t } = useI18n()

    const headline = ref('')

    //const topheadline = ref(t('shoolandpublicholidays') )
    const publicHolidays = ref(t('publicholidays'))
    const shoolHolidays = ref(t('schoolholidays'))
    const entercountry = ref(t('entercountry'))
    
    const calenderCore1 = calenderCore();
    const showDeleteButton = ref(false)
  
    const props = defineProps({
      prop_mode: {
        type: String,
        required: true
      },
      prop_calendarId: String,
      prop_docId: String,
      allowDelete: {
        type: Boolean,
        required: true 
      },
      calendarInfo:{
        type: Object,
        required: true
      },
      headline: {
        type: String,
        required: true
      },
  });
  
    const store = useStore();
  const { state } = store;
  
  const emit = defineEmits(['close-menu'])

  const isAuthenticated = computed(() => store.getters["user/isAuthenticated"]);
  const userId = computed(() => store.getters["user/userID"]);
  
  const selectedSpecialdayId = ref('2') //2 Feiertage, 3 Schulferien
  const selectedSpecialday = ref('Feiertage'); // Feiertage ist standardmäßig ausgewählt
  
  const changeselectedSpecialday = (dayId) => {
    if(dayId === "Feiertage" || dayId === 2){
      selectedSpecialdayId.value = '2'
    }else if (dayId === "Schulferien" || dayId === 3){
      selectedSpecialdayId.value = '3'
    }
  };
  
  function closeMenu(){
      emit('close-menu')
  }
  
  onMounted(() => initialize());
  
  const initialize = () => {
      headline.value = t(props.headline)
      store.state.settings.AnnucalSaveText = "Speichern"
      calendarId.value= props.prop_calendarId
      docId.value = props.prop_docId
      store.state.settings.savingModus = false
      showDeleteButton.value = props.allowDelete
      if(props.calendarInfo != undefined){
        selectedSpecialdayId.value = props.calendarInfo.type
        selectedSpecialday.value = props.calendarInfo.specialday


        //selectedCountry.value = props.calendarInfo.country
        selectedCountry.value  = countries.value.find(country => country.Country === props.calendarInfo.country);



        selectedCountryName.value = props.calendarInfo.country
        selectedSubdivisionName.value = props.calendarInfo.subdivision
        selectedSubdivisionCode.value = props.calendarInfo.subdivisionCode
      }
  } 
  
  const countries = computed({
          get() {
            return state.countries.items;
          },
        })
    
    
    
    const selectedCountry = ref(null);
    const selectedSubdivision = ref(null);
    const selectedCountryName = ref('');
    const selectedSubdivisionName = ref('');
    const filteredCountries = ref([]);
    const filteredSubdivisions = ref([]);
    const selectedSubdivisionCode = ref(null);
    
    // Methode zur Filterung der Länder
    const filterCountries = () => {
        filteredCountries.value = countries.value.filter(country =>
          country.Country.toLowerCase().includes(selectedCountryName.value.toLowerCase())
        );
      if (!selectedCountryName.value) {
        selectedCountry.value = null;
        selectedSubdivisionName.value = '';
        filteredSubdivisions.value = [];
      }
    };
    
    // Methode zur Filterung der Unterteilungen
    const filterSubdivisions = () => {
      try{
        if (selectedCountry.value) {
          filteredSubdivisions.value = selectedCountry.value.Subdivisions.filter(subdivision =>
            subdivision.SN.toLowerCase().includes(selectedSubdivisionName.value.toLowerCase())
          );
        }
      }catch{
        console.log("Error - filterSubdivisions")
      }
    };
    
    // Auswahl eines Landes
    const selectCountry = (country) => {
      selectedCountry.value = country;
      selectedCountryName.value = country.Country;
      filteredCountries.value = [];
      selectedSubdivision.value = null; // Reset der Unterteilungswahl
      selectedSubdivisionName.value = ''; // Reset des Suchfeldes für Unterteilungen
    };
    
    // Auswahl einer Unterteilung
    const selectSubdivision = (subdivision) => {
      selectedSubdivision.value = subdivision;
      selectedSubdivisionName.value = subdivision.SN;
      filteredSubdivisions.value = [];
      selectedSubdivisionCode.value = subdivision.SC;
    };

    const docId = ref('')
    const calendarId = ref('')
    const deleting = ref(false);

    const mycalendars = computed({
        get() {
          return state.mycalendars.items;
        },
      })

    const events = computed({
        get() {
            return state.events.items;
        },
        })

async function deleteElement(){

  let Calendar = {    
      calendarId: calendarId.value,
      docId: docId.value,
      prop_docId: String,
      creatorUID: store.state.user.userID,
  }
  if(isAuthenticated.value === true){
    await store.dispatch("deleteCalendarFromMyCalendars", Calendar);
    await store.dispatch("deleteCalendarFromCalendars", Calendar);
  }
  
  unsubcribeToCalendarAfterDeleting(Calendar) 
  SetNewSelectedCalender()
  store.dispatch("removePublicCalendarFromList", calendarId.value);
  emit('close-menu')

}

function SetNewSelectedCalender(){
        for (let i = 0; i < mycalendars.value.length; i++){
            if(mycalendars.value[i].externalCalendarLink == null || mycalendars.value[i].externalCalendarLink == "" || mycalendars.value[i].externalCalendarLink == undefined){
                store.state.settings.currentSelectedCalendar = mycalendars.value[i];
                store.state.settings.currentCalendarId = mycalendars.value[i].calendarId
                store.state.settings.currentCalendarColor = mycalendars.value[i].color 
                store.state.settings.currentCalendarIcon = mycalendars.value[i].icon
            }
        }
  }

async function unsubcribeToCalendarAfterDeleting(result){

  deleting.value = true;

  for (let index = 0; index < mycalendars.value.length; ++index){
      if(mycalendars.value[index].docId == result.docId){
          mycalendars.value.splice(index, 1);
      }
  }

  for(let index2 = 0; index2 < events.value.length; ++index2){
      if(events.value[index2].calendarId == result.docId){
          events.value.splice(index2,1);
      }
  }

  calenderCore1.updateCalender();
  deleting.value = false;

}
  

async function savenew(){

  try{
    store.state.settings.savingModus = true

    /*
      prop_type="1"
      1 = Internal Kalender
      2 = Public Holiday
      3 = School Holidays
      4 = Subscribed ICal from Google, Apple, and so on ...
    */
    
    let newCalendar = {
        color: '#A39F96', //calendarColor.value, 
        //color: '#34ccff', - Farbe für Feiertage
        icon: 'ph-CalendarCheck', //icon.value,
        name: selectedSpecialday.value,
        externalCalendarLink: '',//externalCalendarLink.value,
        description: '',
        visible: true,
        private: true,
        creatorUID: userId.value != undefined ?  userId.value : "notSignedIn",
        creatorName: "Annucal",
        //calendarImage:  calendarImage,
        type: selectedSpecialdayId.value,
        specialday:selectedSpecialday.value,
        country: selectedCountryName.value,
        subdivision: selectedSubdivisionName.value,
        subdivisionCode: selectedSubdivisionCode.value,
        loadedPublicHolidays: false,
        events:[]
    }
    
    if(isAuthenticated.value == true){
      const newCalendarData = await store.dispatch("createCalendar", newCalendar);
      newCalendar.icon = newCalendarData.imageURL
      newCalendar.calendarId = newCalendarData.calendarId
      newCalendar.docId = newCalendarData.MyCalendarsdocId
      store.state.mycalendars.items.push(newCalendar) 
    }else{
      const cId = uuidv4()
      newCalendar.calendarId = cId
      newCalendar.docId = cId
      store.state.mycalendars.items.push(newCalendar)
    }

      await store.dispatch('getPublicHolidaysFromApi', { userId: store.state.user.userID, localCalendars: mycalendars.value });
      //await store.dispatch("getPublicHolidaysFromApi", store.state.user.userID, mycalendars.value)
      //store.state.mycalendars.items = store.state.storedmycalendars.items
      store.state.availablecalendars.items = store.state.storedavailablecalendars.items
      store.state.events.items = store.state.storedevents.items
      await store.dispatch("getmydaysWithEventsFromDB", {
      events: store.state.events.items, 
      myCalendars: store.state.mycalendars.items, 
      calender: store.state.calender.items})
   

    
    calenderCore1.updateCalender()
    
    
    emit('close-menu')

  }catch(error){
    console.log("Error - saveNew - AnnucalHolidays: " + error)
  }
}

function removePublicHolidayEvents(calendarId){

   // delete event from eventlist
   for(let index = 0; index < store.state.storedevents.items.length; ++index){
    for (let index = store.state.storedevents.items.length - 1; index >= 0; --index) {
    if (store.state.storedevents.items[index].calendarId === calendarId) {
      store.state.storedevents.items.splice(index, 1);
    }
  }
  }


  // Refresh Events Indexlist
  for(let index = 0; index < store.state.storedevents.items.length; ++index){
      store.state.storedevents.items[index].index = index;
  }

  // Write each event index in the events of each mycalendars
  for (let calenderIndex = 0; calenderIndex < store.state.mycalendars.items.length; calenderIndex++) {
    store.state.mycalendars.items[calenderIndex].events = []
      for (let eventIndex = 0; eventIndex < store.state.storedevents.items.length; eventIndex++){
          if(store.state.mycalendars.items[calenderIndex].calendarId == store.state.storedevents.items[eventIndex].calendarId){
            store.state.mycalendars.items[calenderIndex].events.push(eventIndex)
          }
      } 
  } 
}

async function saveEdit(){

  store.state.settings.savingModus = true
  removePublicHolidayEvents(calendarId.value)
  store.dispatch("removePublicCalendarFromList", calendarId.value);

  let newCalendar = {
      
      docId: docId.value,
      userId: store.state.user.userID,
      calendarId: calendarId.value,
      type: selectedSpecialdayId.value,
      specialday: selectedSpecialday.value,
      country: selectedCountryName.value,
      subdivision: selectedSubdivisionName.value,
      subdivisionCode: selectedSubdivisionCode.value,
      name: props.calendarInfo.name,
      description: props.calendarInfo.description,
      icon: props.calendarInfo.icon,
      private: props.calendarInfo.private,
      color: props.calendarInfo.color,
      externalCalendarLink:"",
      loadedPublicHolidays: false,
    
  }

  await store.dispatch("updateCalendar", newCalendar);


  //--------------- CHANGE LOCAL MYCALENDERS START ---------------------------

    for (let i = 0; i < mycalendars.value.length; i++) {
      if(mycalendars.value[i].calendarId == calendarId.value){
        mycalendars.value[i].type= selectedSpecialdayId.value
        mycalendars.value[i].specialday= selectedSpecialday.value
        mycalendars.value[i].country= selectedCountryName.value
        mycalendars.value[i].subdivision= selectedSubdivisionName.value
        mycalendars.value[i].subdivisionCode= selectedSubdivisionCode.value
        mycalendars.value[i].loadedPublicHolidays = false
        mycalendars.value[i].events = []
      }
    }

  //--------------- CHANGE LOCAL MYCALENDERS END ---------------------------

  selectedCountry.value = selectedCountryName.value;
  
  filteredCountries.value = [];
      
      
    //selectedSubdivision.value = subdivision;
    selectedSubdivisionName.value = 'Wien';
    filteredSubdivisions.value = [];
    selectedSubdivisionCode.value = '01';

    await store.dispatch('getPublicHolidaysFromApi', { userId: store.state.user.userID, localCalendars: mycalendars.value });
    //await store.dispatch("getPublicHolidaysFromApi", store.state.user.userID, store.state.mycalendars.items)
    store.state.mycalendars.items = store.state.storedmycalendars.items
    store.state.availablecalendars.items = store.state.storedavailablecalendars.items
    store.state.events.items = store.state.storedevents.items
    await store.dispatch("getmydaysWithEventsFromDB", {
          events: store.state.events.items, 
          myCalendars: store.state.mycalendars.items, 
          calender: store.state.calender.items
    })

    calenderCore1.updateCalender()
    

  emit('close-menu')
}
  
  
</script>
    
  <style scoped>

.text-style{
    font-size: 13px;
    font-weight: 400;
    color:var(--standard-text-color);
    letter-spacing: -0.6px;
}
  
  .main-div{
      margin-left:37px;
      margin-top: -116px;
  }
  .first-row{
      padding-top:16px;
  }
  
  .second-row{
      padding-top: 30px;
  }
  
  .third-row{
      padding-top: 4px;
  }
  
  .list-text{
    font-size: 14px;
  }
  
  .custom-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .custom-list-item {
    padding: 0 5px;
    height: 24px;
    line-height: 24px;
    font-size: 12px;
    cursor: pointer;
    border-bottom: 1px solid #ccc;
  }
  
  .custom-list-item:hover {
    background-color: #f0f0f0;
  }
  
  
  </style>
    
  